import _ from 'lodash';
import axios from "axios";
import { getTokens, setTokens } from "./../context/auth";

export const BaseApi = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_SERVER_URL,
    responseType: "json",
    withCredentials: true,
});

BaseApi.interceptors.response.use(
    function (response) {
        // Any status code that lie within the range of 2xx cause this function to trigger
        // Do something with response data
        return response;
    }, function (error) {
        // Any status codes that falls outside the range of 2xx cause this function to trigger
        // Do something with response error.
        // Generically handle authorization error from our backend, and redirect to login page
        if (error.response != undefined && error.response.status == 401) {
            setTokens(null);
            // CG: THIS MAY NEED TO BE SMARTER TO REMEMBER THE REFERRER
            window.location = "/login"
        }
        return Promise.reject(error);
    }
);

export const Api = {
    get(url, options) {
        return BaseApi.get(url, _.merge(options, {
            headers: {
                "Authorization" : 'JWT ' + getTokens().tokens
            }
        }));
    },
    post(url, data, options) {
        //console.log(">> process.env.REACT_APP_BACKEND_SERVER_URL:", process.env.REACT_APP_BACKEND_SERVER_URL, url);
        return BaseApi.post(url, data, _.merge(options, {
            headers: {
                "Authorization" : 'JWT ' + getTokens().tokens
            }
        }));
    }
};
