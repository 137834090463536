import React, { Component, useMemo } from 'react'
import ReactDOM from "react-dom";
import { Link, useLocation, BrowserRouter as Router } from "react-router-dom";

import Header from './../Layout/Header';
import Footer from './../Layout/Footer';
import CarForm from './../../components/CarForm';
import BidTab from './../../components/BidTab';

import queryString from 'query-string';
import 'react-image-gallery/styles/css/image-gallery.css'
import ImageGallery from 'react-image-gallery';

import { useAuth } from "../../context/auth";
import { AuthContext } from "../../context/auth";
import { Api } from "../../utils/Api";
import { getTokens } from "../../context/auth";

import Resizer from 'react-image-file-resizer';
import EXIF from "exif-js";

import Dropzone from 'react-dropzone'

import CurrencyFormat from 'react-currency-format';
import {ToastContainer, toast } from 'react-toastify';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css

import { Image, Video } from 'cloudinary-react';
import { fetchPhotos, openUploadWidget } from "../../utils/CloudinaryService";

const { REACT_APP_ENV } = process.env;

const baseStyle = {
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '20px',
  borderWidth: 2,
  borderRadius: 2,
  borderColor: '#eeeeee',
  borderStyle: 'dashed',
  backgroundColor: '#fafafa',
  color: '#bdbdbd',
  outline: 'none',
  transition: 'border .24s ease-in-out'
};

const activeStyle = {
  borderColor: '#2196f3'
};

const acceptStyle = {
  borderColor: '#00e676'
};

const rejectStyle = {
  borderColor: '#ff1744'
};

function SampleNextArrow(props) {
  const {  onClick } = props;
  return (
    <div className="la la-angle-right nav-right"  onClick={onClick}/>
  );
}

function SamplePrevArrow(props) {
  const {  onClick } = props;
  return (
    <div className="la la-angle-left nav-left" onClick={onClick}/>
  );
}

var bg3 = require('./../../images/main-slider/slide2_car.jpg');
class HotelBooking extends Component {
    constructor(){
        super();
        this.galleryRef = React.createRef();

        this.handler = this.updateTimelineMessage.bind(this)
        this.state = {
            imgName : '',
            img_list : [],
            listing_status : '',
            mileage: '',
            // price: '',
            vin: '',
            odoometer: '',
            lien_holder_id: '',
            lien_holder_other_id: 5,
            lien_holder_other: '',
            lien_holder_list: [],
            make: '',
            make_id: -1,
            model_id: -1,
            trim_id: -1,
            body_style: '',
            door: '',
            drive_type: '',
            ext_color: '',
            interior: '',
            model: '',
            transmission: '',
            year: '',
            isToggleOn: true,
            total_bid_active: 0,
            dealers: [],
            img_exterior_category_list: [],
            img_interior_category_list: [],
            preferred_dealer_id: '',
            preferred_dealer_list: [],
            current_index: 0,
            entity_id: null,
            notes: '',
            video_id: null,
            content_type: null,
        }
    }

    componentDidMount() {
        let url = this.props.location.search;
        let params = queryString.parse(url);

        if (Object.getOwnPropertyNames(params).length > 0){
            var entity_id = params['entity_id'];
            var pd = params['pd'];
            console.log("/// entity_id", entity_id);
            if (entity_id !== undefined) {
                Api.get('/ham/seller_car_detail?entity_id='+entity_id)
                .then(res => {
                    console.log(this.state.entity_id);
                    console.log("Dealer==========>", res.data.dealers);
                    console.log("Car==============>", res.data.car);

                    if (Object.getOwnPropertyNames(res.data.car).length > 0){
                        const imgs = res.data.car.img_list;
                        this.setState({
                            entity_id: entity_id,
                            img_list: imgs,
                            imgName: res.data.car.image_first,
                            id: res.data.car.id,
                            listing_status: res.data.car.listing_status,
                            seller_id: res.data.car.seller_id,
                            year_id: res.data.car.year_id,
                            
                            make: res.data.car.make,
                            model: res.data.car.model,
                            trim: res.data.car.trim,

                            year: res.data.car.year,
                            year_list: res.data.year_list,
                            make_id: res.data.car.make_id,
                            make_list: res.data.make_list,
                            model_id: res.data.car.model_id,
                            model_list: res.data.model_list,
                            trim_id: res.data.car.trim_id,
                            trim_list: res.data.trim_list,

                            body_style_id: res.data.car.body_style_id,
                            drive_type_id: res.data.car.drive_type_id,
                            transmission_id: res.data.car.transmission_id,
                            ext_color_id: res.data.car.ext_color_id,
                            interior_color_id: res.data.car.interior_color_id,
                            door_id: res.data.car.doors_id,
                            dealers: res.data.dealers,
                            img_exterior_category_list: res.data.car.img_exterior_category_list,
                            img_interior_category_list: res.data.car.img_interior_category_list,
                            body_style: res.data.car.body_style,
                            drive_type: res.data.car.drive_type,
                            transmission: res.data.car.transmission,
                            doors: res.data.car.doors,
                            vin: res.data.car.vin,
                            odometer: res.data.car.odometer,
                            lien_holder_id: res.data.car.lien_holder_id,
                            lien_holder_other_id: res.data.car.lien_holder_other_id,
                            lien_holder_list: res.data.car.lien_holder_list,
                            lien_holder_other: res.data.car.lien_holder_other,
                            total_bid_active: res.data.car.total_bid_active,
                            preferred_dealer_id: res.data.car.preferred_dealer_id,
                            preferred_dealer_list: res.data.preferred_dealer_list,
                            notes: res.data.car.notes,
                            other_color: res.data.car.other_color,
                            content_type: res.data.car.content_type,
                            video_id: res.data.car.video_id
                        });
                    }
                })                
            } 

            if (pd !== undefined){
                Api.get('/ham/get_preferred_dealer?pd='+pd)
                .then(res => {
                    if (Object.getOwnPropertyNames(res.data).length > 0){
                        this.setState({
                            preferred_dealer_id: res.data.preferred_dealer_id,
                            preferred_dealer_list: res.data.preferred_dealer_list,
                        });
                    }
                })
            }

           
        }else{            
            // Api.get('/ham/init_car_detail')
            // .then(res => {  
            //     if (Object.getOwnPropertyNames(res.data).length > 0) {
            //         console.log(">> BLARP:", res);
            //         // this.setState({                            
            //         //     img_exterior_category_list: res.data.img_exterior_category_list,
            //         //     img_interior_category_list: res.data.img_interior_category_list,                            
            //         // });                        
            //     }
            // })   
            
            Api.get('/ham/get_preferred_dealer_register')
            .then(res => {
                if (Object.getOwnPropertyNames(res.data).length > 0){
                    if(res.data.preferred_dealer_id != null){
                        this.setState({
                            preferred_dealer_id: res.data.preferred_dealer_id,
                            preferred_dealer_list: res.data.preferred_dealer_list,
                            lien_holder_other_id: res.data.lien_holder_other_id,
                        });
                    }
                }
            })
        }
        
    }

    toggleImage = (e) => {
         this.setState({
              imgName: e.target.src
            });
    }

    uploadToServer = (uri, orig_file, categ_id) => {
       var parentThis = this;
       const formData = new FormData();
       formData.append("file", uri, orig_file.name);
       formData.append("entity_id", this.state.id);
       formData.append("categ_id", categ_id);
       var headers = {
            'Content-Type': 'multipart/form-data'
        }
       Api.post("/ham/car_upload_file", formData, {headers: headers})
            .then(
            res => {
                parentThis.componentDidMount();
                console.log('Testing: ', res);
                this.setState(res.data.result);
                    toast("Upload Success", {autoClose: 3000});
                }
            )
            .catch(err => console.warn(err));
    }

    onDrop = categ_id => (file) => {
        console.log("test", categ_id)

        let categ = {
            'categ_id': categ_id,
            'car_id': this.state.entity_id
        }
        Api.post('/ham/check_total_image_category', {data: categ})
        .then(res => {
            if(res.data.error == null){
                const headers = {
                'Access-Control-Allow-Origin': '*'
                }

                var parentThis = this;

                EXIF.getData(file[0], function() {
                    var orientation = 0;
                    var rotate = 0;
                    var exifData = EXIF.pretty(this);
                    if (exifData) {
                    console.log(exifData);
                    orientation = EXIF.getTag(this, "Orientation");
                    console.log(orientation);
                    if (orientation == 6) rotate = 90;
                    if (orientation == 8) rotate = 270;
                    if (orientation == 3) rotate = 180;

                    } else {
                        console.log("No EXIF data found in image '" + file.name + "'.");
                    }

                    console.log(rotate);

                    Resizer.imageFileResizer(
                            file[0],
                            800,
                            800,
                            'JPEG',
                            100,
                            rotate,
                            uri => {
                                console.log(uri);
                                parentThis.uploadToServer(uri, file[0], categ_id);
                            },
                            'blob'
                        );
                 });
            }else{
                toast(res.data.error, {autoClose: 3000});
            }
        })
    }

    updateTimelineMessage = (msg_list) => {
        this.setState({
            message_list: msg_list,
            is_answered: false,
        })
    }
    
    onThumbnailClick = (event, index) => {

        this.setState({
            current_index: index,
        })

    }

    setPrimaryPhoto = (event) => {
        let data = {
            'index': this.state.current_index,
            'car_id': this.state.entity_id
        }
        Api.post('/ham/set_main_photo', {data: data})
        .then(res => {
            console.log("Result>",res.data);
            toast(res.data.msg, {autoClose: 2000});
        })
    }

    addPhotos = (event) => {
        // You can only either upload videos or photos, but not both. Are you sure you want to continue with <video|photo>?"
        // let data = {
        //     'index': this.state.current_index,
        //     'car_id': this.state.entity_id
        // }
        // Api.post('/ham/set_main_photo', {data: data})
        // .then(res => {
        //     console.log("Result>",res.data);
        //     toast(res.data.msg, {autoClose: 2000});
        // })

        event.preventDefault();
        
        confirmAlert({
            title: 'Info',
            message: 'You can only either upload videos or photos, but not both. Are you sure you want to continue with Photo?',
            buttons: [
              {
                label: 'Yes',
                onClick: () => {
                   
                    this.setState({
                        content_type: 'photos'
                    })

                    let data = {
                        'car_id': this.state.entity_id,
                        'content_type': 'photos'
                    }

                    Api.post('/ham/set_car_content_type', {data: data})
                    .then(res => {
                        console.log("Result>",res.data);
                        // toast(res.data.msg);
                    })

                }
              },
              {
                label: 'No',
                onClick: () => {
                    console.log("None");
                }
              }
            ]
          });
    }

    addVideos = (event) => {
        event.preventDefault();
        
        confirmAlert({
            title: 'Info',
            message: 'You can only either upload videos or photos, but not both. Are you sure you want to continue with Video?',
            buttons: [
              {
                label: 'Yes',
                onClick: () => {
                    this.setState({
                        content_type: 'videos'
                    })

                    let data = {
                        'car_id': this.state.entity_id,
                        'content_type': 'videos'
                    }

                    Api.post('/ham/set_car_content_type', {data: data})
                    .then(res => {
                        console.log("Result>",res.data);
                        // toast(res.data.msg);
                    })
                }
              },
              {
                label: 'No',
                onClick: () => {
                    console.log("None");
                }
              }
            ]
          });
    }


    deletePhoto = (event) => {
        event.preventDefault();
        
        confirmAlert({
            title: 'Confirm to Delete Photo',
            message: 'Are you sure to do this.',
            buttons: [
              {
                label: 'Yes',
                onClick: () => {
                    // e.preventDefault();
                    let data = {
                        'index': this.state.current_index,
                        'car_id': this.state.entity_id
                    }
                    Api.post('/ham/delete_car_image', {data: data})
                    .then(res => {
                        console.log("Result>",res.data);
                        this.setState({
                            img_list: res.data.img_list,
                            img_exterior_category_list: res.data.img_exterior_category_list,
                            img_interior_category_list: res.data.img_interior_category_list,
                            current_index: 0,
                        })
                        toast(res.data.msg);
                    })
                }
              },
              {
                label: 'No',
                onClick: () => {
                    console.log("None");
                }
              }
            ]
          });
    }

    save_video_data = data => {                

        Api.post('/ham/save_video_data', {data:data})
        .then(res => {
            const public_id = res.data.external_id;
            
            this.setState({video_id: public_id})

            toast("Video uploaded successfully", {autoClose: 3000});
        })
    }

    beginUpload = tag => {
        const uploadOptions = {
          cloudName: process.env.REACT_APP_CLOUDINARY_CLOUD_NAME,
          tags: [tag],
          uploadPreset: process.env.REACT_APP_CLOUDINARY_PRESET_NAME
        };
      
        openUploadWidget(uploadOptions, (error, photos) => {
          if (!error) {
            console.log(photos);
            if(photos.event === 'success'){
              //setImages([...images, photos.info.public_id])
              console.log(">>photos.info:", photos.info);
            //   this.setState({video_id: photos.info.public_id})
                this.save_video_data({
                    car_id: this.state.entity_id,
                    url: photos.info.secure_url,
                    public_id: photos.info.public_id
                });
            }
          } else {
            console.log(error);
          }
        })
      }




    render() {
        var settings = {
            dots: false,
            slidesToShow: 1,
            infinite: true,
        };

        return (
        <div>
            <Header originrole="SELLER"/>
            <div className="dlab-bnr-inr overlay-black-middle" style={{ backgroundImage: "url(" + bg3 + ")", backgroundSize: 'cover' }}>
                <div className="container">
                    <div className="dlab-bnr-inr-entry">
                        <h1 className="text-white">Car Detail</h1>
                    </div>
                </div>
            </div>
            <div className="content-block">
                <div className="section-full content-inner bg-white">
                    <div className="container">
                        <div className="row m-b30">
                            {this.state.entity_id === null ?
                                <div className="col-lg-8">
                                    <p>Please enter the car information, after that you can upload photos in the next step.</p>
                                </div>
                                :
                                <div className="col-lg-8">
                                    <div className="d-flex info-bx m-b30">
                                        <div className="tour-title">
                                            <h2>{this.state.year} {this.state.make} {this.state.model} {this.state.trim}</h2>
                                        </div>
                                        <div className="tour-price ml-auto">
                                            {/* <CurrencyFormat value={this.state.price} displayType={'text'}
                                                thousandSeparator={true} prefix={'$'}
                                                renderText={value => <h2 className="price">{value}</h2>} /> */}
                                        </div>
                                    </div>
                                    {this.state.content_type == null?
                                        <>
                                        <div className="row mt-10">
                                            <br />
                                            <div className="col-md-6">
                                                <button className="btn btn-primary float-right" onClick={this.addPhotos} type="button">Add Photos</button>
                                            </div>
                                            <div className="col-md-6">
                                                <button className="btn btn-danger float-left" onClick={this.addVideos} type="button">Add Videos</button>
                                            </div>
                                            <ToastContainer position="top-center" autoClose={2000}/>
                                        </div>
                                        </>
                                        :<span></span>
                                    }
                                    
                                    {this.state.content_type == 'videos'?
                                        <>
                                        {this.state.video_id != null ?
                                            <>
                                            <Video width="540px"
                                                key={this.state.video_id}
                                                publicId={this.state.video_id}
                                                controls={true}
                                            />
                                            </>
                                            :<p>There's no video yet.</p>
                                        }
                                        <div className="row mt-10">
                                            <div className="col-md-6">
                                                <button className="btn btn-primary"  onClick={() => this.beginUpload()}>Upload Video</button>
                                            </div>
                                        </div>
                                        </>
                                        :<p></p>
                                    }
                                    {this.state.content_type == 'photos' ?
                                    <>
                                        {this.state.img_list.length > 0? 
                                            <>
                                            <ImageGallery items={this.state.img_list} onThumbnailClick={this.onThumbnailClick} ref={this.galleryRef} />
                                                
                                            <div className="row mt-10">
                                                <br />
                                                <div className="col-md-6">
                                                    <button className="btn btn-primary float-right" onClick={this.setPrimaryPhoto} type="button">Set as Main photo</button>
                                                </div>
                                                <div className="col-md-6">
                                                    <button className="btn btn-danger float-left" onClick={this.deletePhoto} type="button">Delete Photo</button>
                                                </div>
                                                <ToastContainer position="top-center" autoClose={2000}/>
                                            </div>
                                            </>
                                            : <p>You have not uploaded any photos yet.</p>
                                        }
                                    
                                        <br />
                                        <br />
                                        <div className="row mt-10">
                                            <div className="comments-review-area">
                                                <div className="comments-review-box">
                                                    <div className="review-body">
                                                        <div className="review-btn">Exterior</div>
                                                        <div className="review-comments-list">
                                                            <ul>
                                                                {this.state.img_exterior_category_list.map(r =>  {
                                                                    return (
                                                                        <li>
                                                                            <div className="review-comment-author">
                                                                                <div className="exterior-interior-button">
                                                                                <Dropzone onDrop={this.onDrop(r.id)}>
                                                                                    {({getRootProps, getInputProps}) => (
                                                                                        <div {...getRootProps({baseStyle})} className="btn btn-secondary">
                                                                                            <input {...getInputProps()} />
                                                                                                Upload
                                                                                        </div>
                                                                                    )}
                                                                                    </Dropzone>
                                                                                </div>
                                                                                <div className="comment-info">
                                                                                    <div className="info-group">
                                                                                        <h3 className="title">{r.name}</h3>
                                                                                    <span className="comment-date">{r.total} Uploaded (Minimum: {r.minimum}, Maximum: {r.maximum})</span>
                                                                                    </div>
                                                                                    <p className="comment-text">{r.description}</p>
                                                                                </div>
                                                                            </div>
                                                                        </li>
                                                                    )
                                                                })}
                                                            </ul>
                                                        </div>
                                                        <div className="review-btn">Interior</div>
                                                        <div className="review-comments-list">
                                                            <ul>
                                                                {this.state.img_interior_category_list.map(r =>  {
                                                                    return (
                                                                        <li>
                                                                            <div className="review-comment-author">
                                                                                <div className="exterior-interior-button">
                                                                                <Dropzone onDrop={this.onDrop(r.id)}>
                                                                                    {({getRootProps, getInputProps}) => (
                                                                                        <div {...getRootProps({baseStyle})} className="btn btn-secondary">
                                                                                            <input {...getInputProps()} />
                                                                                                Upload
                                                                                        </div>
                                                                                    )}
                                                                                    </Dropzone>
                                                                                </div>
                                                                                <div className="comment-info">
                                                                                    <div className="info-group">
                                                                                        <h3 className="title">{r.name}</h3>
                                                                                        <span className="comment-date">{r.total} Uploaded (Minimum: {r.minimum}, Maximum: {r.maximum})</span>
                                                                                    </div>
                                                                                    <p className="comment-text">{r.description}</p>
                                                                                </div>
                                                                            </div>
                                                                        </li>
                                                                    )
                                                                })}
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        </>
                                        : <p></p>
                                    }
                                        
                                    <br />
                                    <h3>Bids/Notifications</h3>
                                    <br />
                                    <br />
                                    <div className="row">
                                        {console.log(">>>>> this.state.dealers: ", this.state.dealers)}
                                        {this.state.dealers.length > 0 ?
                                            <BidTab val={this.state} handler={this.updateTimelineMessage}/>
                                            : <p>There are no bids/notifications yet.</p>
                                        }
                                    </div>
                                </div>
                            }
                            <div className="col-lg-4">
                                <div className="">
                                    <CarForm val={this.state} />
                                    <div className="m-t30">
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row m-b30">
                            <div className="col-md-12 col-lg-12">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
        )
    }
}
export default HotelBooking;