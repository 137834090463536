import React, {Component} from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useAuth } from "./auth";
import queryString from 'query-string';

function AuthRoute({ component: Component, ...rest }) {

  const {getAuthTokensOverloaded, setAuthTokensOverloaded} = useAuth();
  
  
  // let url = location.search;
  // let params = queryString.parse(url);

  let search = window.location.search;
  let params = new URLSearchParams(search);
  let pd = params.get('pd');
  console.log(pd);
  let url = 'login'
  
  if (pd !== undefined){
    url = '/login?pd='+pd
  }

  return(
    <Route {...rest} render={(props) => 
      getAuthTokensOverloaded().tokens != null && getAuthTokensOverloaded().role == rest['role'] ? (
        <Component {...props} />
      ) : (
        getAuthTokensOverloaded().tokens != null ?(
          <Redirect to={{ pathname: '/'+getAuthTokensOverloaded().role.toLowerCase()+'/dashboard', props}} />
        ) : (
          <Redirect to={{ pathname: '/login', state: { referer: props.location } }} />
        )
        
    )}
    />
  );
}

export default AuthRoute;