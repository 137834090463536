import React, { Component, useState } from 'react'
import { Link } from 'react-router-dom';
import { Button, Form, FormGroup, Label, Input, FormText } from 'reactstrap';
import { Api } from "../../utils/Api";
import $ from 'jquery';
import ReactSlider from 'react-slider'
import '../../css/slider.css';
import Dropzone from 'react-dropzone'
import Resizer from 'react-image-file-resizer';
import EXIF from "exif-js";
import queryString from 'query-string';

// Stripe
import {useStripe, CardElement, ElementsConsumer, useElements, Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardInput from './../../components/CardInput';
import TextField from '@material-ui/core/TextField';
import {makeStyles} from '@material-ui/core/styles';
import { element } from 'prop-types';
import {ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import InputMask from 'react-input-mask';
import SimpleReactValidator from 'simple-react-validator';


// import {CardElement, Elements, ElementsConsumer} from '@stripe/react-stripe-js';
// Components
// import CardInput from './../../components/CardInput';

const baseStyle = {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '20px',
    borderWidth: 2,
    borderRadius: 2,
    borderColor: '#eeeeee',
    borderStyle: 'dashed',
    backgroundColor: '#fafafa',
    color: '#bdbdbd',
    outline: 'none',
    transition: 'border .24s ease-in-out'
};

const useStyles = makeStyles({
    root: {
      maxWidth: 500,
      margin: '35vh auto',
    },
    content: {
      display: 'flex',
      flexDirection: 'column',
      alignContent: 'flex-start',
    },
    div: {
      display: 'flex',
      flexDirection: 'row',
      alignContent: 'flex-start',
      justifyContent: 'space-between',
    },
    button: {
      margin: '2em auto 1em',
    },
  });


//   const stripe = useStripe();
//   const elements = useElements();
  
class DealerRegister extends Component {
    constructor(props) {
        super(props);
        this.validator = new SimpleReactValidator();
        this.state = {
            entity_id: '',
            ups_user_id: '',
            company_name: '',
            tier: null,
            tier_list: [],
            address: '',
            city: '',
            state_v: '',
            state_list: ["AL", "AK", "AZ", "AR", "CA", "CO", "CT", "DE", "FL", "GA", "HI",
                        "ID", "IL", "IN", "IA", "KS", "KY", "LA", "ME", "MD", "MA", "MI",
                        "MN", "MS", "MO", "MT", "NE", "NV", "NH", "NJ", "NM", "NY", "NC",
                        "ND", "OH", "OK", "OR", "PA", "RI", "SC", "SD", "TN", "TX", "UT",
                        "VT", "VA", "WA", "WV", "WI", "WY"],
            zip: '',
            phone: '',
            email: '',
            password: '',
            verify_password: '',
            license_enabled: true,
            payment_method_disable: true,
            style_button_dropzone: 'btn btn-secondary',
            setEmail: '',
            status: '',
            setStatus: '',
            clientSecret: '',
            setClientSecret: '',
            stripe: '',
            elements: '',
            card_error: '',
            email_error: null,
            color_payment: 'secondary',
        }
    }

    handleSubmitSub = async (event) => {
        event.preventDefault();

        const {stripe, elements} = this.props;
        console.log("Props", this.props);
        console.log(stripe);
        console.log(elements);
        if (!stripe || !elements) {
            console.log("Fail")
            // Stripe.js has not loaded yet. Make sure to disable
            // form submission until Stripe.js has loaded.
            return;
        }

        if (this.state.status !== '') {
            // console.log("test1");
            const result = await stripe.confirmCardPayment(this.state.clientSecret, {
                payment_method: {
                card: elements.getElement(CardElement),
                billing_details: {
                    email: this.state.email,
                },
                },
            });
            if (result.error) {
                console.log(result.error.message);
                // Show error in payment form
            } else {
                
            }
        } else {
            // console.log("test2");
            const result = await stripe.createPaymentMethod({
            type: 'card',
            card: elements.getElement(CardElement),
            billing_details: {
                email: this.state.email,
            },
            });

            if (result.error) {
                console.log(result.error.message);
                console.log("Test1");
                // Show error in payment form
            } else {
                const payload = {
                    email: this.state.email,
                    payment_method: result.paymentMethod,
                    result_from_stripe: result,
                    ups_user_id: this.state.ups_user_id,
                    tier: this.state.tier,
                    //mode: 'register'
                };

                Api.post('/ham/save_cc', {data: payload})
                    .then(res => {

                        const {status, error, client_secret} = res.data;   

                        if (status === 'success'){
                            console.log("Status", status)
                            // const location = "/dealer/profile";
                            // window.location.assign(location);
                            // console.log("Test");
                            // if (res.status === 200) {
                            this.props.history.replace('/gotohomepage', {title: 'Registration Submitted.', msg: 'You will receive an email with instructions on how to complete the registration process.', role: 'DEALER'});
                            // }
                        }else{;
                            this.setState({
                                card_error: error,
                            });
                        }
                    })
                
            }
        }   
    }

    componentDidMount(e) {
        Api.get('/ham/tier_list')
            .then(res => {
                console.log("Tier List", res.data.result);

                this.setState({
                    tier_list: res.data.result.tier_list,
                });
        });
    }

    handleChange = (e) => {
        console.log(e.target.name);
        console.log(e.target.value);
        this.setState({[e.target.name]: e.target.value})
    }

    handleStep1 = (e) => {
        'use strict';
        e.preventDefault();

        // check_email
        if (this.validator.allValid()) {
            const data  = {
                company_name: this.state.company_name,
                tier: this.state.tier,
                address: this.state.address,
                city: this.state.city,
                state: this.state.state_v,
                zip: this.state.zip,
                phone: this.state.phone,
                email: this.state.email,
                password: this.state.password,
                verify_password: this.state.verify_password,
            }
            const url_params = {
                c: '4m0jsDyIk5FxeasJEixQFA==',
                t: 'JAi2p-J9h1lELVfw3HaYYL9X3rsp0xniPs2nnsSsIlMFnfHKbnx7cLHKIGUDvWGNcLVJUTasOhL27mYJP9e7kQ'
            }
            console.log("test=>", data);
            Api.post('/ham/register_dealer', {data: data}, {params: url_params})
                .then(res => {
                    if (res.data.errors == null){
                        const ups_user_id = res.data.entity_id;
                        const style_button_dropzone = 'dropzone_box site-button button-block';
                        console.log("===>>>>>>>>>>> UPS USER ID:",ups_user_id);
                        this.setState({ 'ups_user_id': res.data.entity_id, style_button_dropzone});
                        if(this.state.ups_user_id != ''){
                            this.setState({license_enabled: false})
                        }
                        toast("Info has been saved, please continue to Step 2 (Upload Dealer License)")
                    }else{
                        this.setState({email_error: res.data.errors});
                    }
                }); 
        } else {
            this.validator.showMessages();
            // rerender to show messages for the first time
            // you can use the autoForceUpdate option to do this automatically`
            this.forceUpdate();
        }
    }

    handleStep2 = (e) => {
        e.preventDefault();
    }

    
    uploadToServer = (uri, orig_file) => {
       var parentThis = this;
       const formData = new FormData();
       console.log(orig_file);
       formData.append("file", uri, orig_file.name);
       formData.append("entity_id", this.state.ups_user_id);
       Api.post("/ham/upload_dealer_license", formData)
            .then(
            res => {
                parentThis.componentDidMount();
                    console.log('Testing: ', res);
                    this.setState({ payment_method_disable:false, color_payment: 'primary'});
                    toast("Dealer License has been saved, [Done] please continue to Step 3 (Enter Payment Method)");
                }
            )
            .catch(err => console.warn(err));
    }

    onDrop = (file) => {
        const headers = {
          'Access-Control-Allow-Origin': '*'
        }

        var parentThis = this;

        EXIF.getData(file[0], function() {
            var orientation = 0;
            var rotate = 0;
            var exifData = EXIF.pretty(this);
            if (exifData) {
              console.log(exifData);
              orientation = EXIF.getTag(this, "Orientation");
              console.log(orientation);
              if (orientation == 6) rotate = 90;
              if (orientation == 8) rotate = 270;
              if (orientation == 3) rotate = 180;

            } else {
              console.log("No EXIF data found in image '" + file.name + "'.");
            }

            console.log(rotate);

            Resizer.imageFileResizer(
                    file[0],
                    800,
                    800,
                    'JPEG',
                    100,
                    rotate,
                    uri => {
                        console.log(uri);
                        parentThis.uploadToServer(uri, file[0]);
                    },
                    'blob'
                );
          });
    }

    setEmail = (e) => {
        this.setState({email: e.target.value})
    }

    render() {
        const {stripe, elements} = this.props;
        
        return (
          
            <div className="section-full content-inner-2 shop-account bg-white">                
                <div className="container">
                    <div className="row">
                        <Link to={'/dealer/'} class="site-button m-r5 button-lg radius-no">Back to Homepage</Link>
                        <div className="col-md-12 text-center">
                            <h3 className="font-weight-700 m-t0 m-b20">DEALER REGISTRATION</h3>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="p-a30 border-1  max-w500 m-auto">
                                <div className="tab-content">
                                    <form id="login" className="tab-pane active needs-validation">
                                        <h4 className="font-weight-700">Step 1</h4>
                                        <div className="form-group">
                                            <label className="font-weight-700">Company Name *</label>
                                            <input name="company_name" id="company_name" value={this.state.company_name} required className="form-control" placeholder="Company Name" type="text" onChange={this.handleChange} />
                                            <small class="text-danger">
                                                {this.validator.message('company name', this.state.company_name, 'required')}
                                            </small>
                                        </div>
                                        <div className="form-group">
                                            <label className="font-weight-700">Membership Level *</label>
                                            <select className="form-control" value={this.state.tier} id="tier" required name="tier" className="form-control" onChange={this.handleChange}>
                                              <option selected value=''> -- select an option -- </option>
                                              {this.state.tier_list.map(r =><option value={r.id} key={r.price_key}>{r.name}</option>)}
                                            </select>
                                            <small class="text-danger">
                                                {this.validator.message('membership level', this.state.tier, 'required|alpha_num')}
                                            </small>                                            
                                        </div>

                                        <div className="form-group">
                                            <label className="font-weight-700">Address *</label>
                                            <input value={this.state.address} name="address" id="address" className="form-control" placeholder="Address" type="text" onChange={this.handleChange} />
                                        </div>
                                        <div className="form-group">
											<label className="font-weight-700">City *</label>
											<input value={this.state.city} name="city" id="city"  className="form-control" placeholder="City" type="text" onChange={this.handleChange} />
										</div>
										
                                        <div className="form-group">
											<label className="font-weight-700">State *</label>
											{/* <input value={this.state.state_v} name="state_v" id="state_v"  className="form-control" placeholder="State" type="text" onChange={this.handleChange} /> */}
                                            <select className="form-control" value={this.state.state_v} id="state_v" required name="state_v" className="form-control" onChange={this.handleChange}>
                                              <option selected value={-1}> -- select an option -- </option>
                                              {this.state.state_list.map(r =><option value={r} key={r}>{r}</option>)}
                                            </select>

                                            <small class="text-danger">
                                                {this.validator.message('state', this.state.state_v, 'required')}
                                            </small>
                                        </div>

										<div className="form-group">
											<label className="font-weight-700">Zip *</label>
											<InputMask value={this.state.zip} name="zip" id="zip"  mask="99999" className="form-control" placeholder="Zip" type="text" onChange={this.handleChange} />
										</div>
                                        <div className="form-group">
                                            <label className="font-weight-700">Phone *</label>
                                            <InputMask value={this.state.phone} name="phone" id="phone"  className="form-control" mask="(999) 999-9999" maskChar=" " placeholder="Phone" onChange={this.handleChange} />
                                        </div>
                                        <div className="form-group">
                                            <label className="font-weight-700">Email *</label>
                                            <input value={this.state.email} name="email" id="email"  className="form-control " placeholder="Email" type="email" onChange={this.handleChange} />
                                            <small class="text-danger">
                                                {this.validator.message('email', this.state.email, 'required|email')}
                                                {this.state.email_error != null &&
                                                    <span>{this.state.email_error}</span>
                                                }
                                            </small>
                                        </div>
                                        <div className="form-group">
                                            <label className="font-weight-700">Password *</label>
                                            <input value={this.state.password} name="password" id="password"  className="form-control " placeholder="Password" type="password" onChange={this.handleChange} />
                                            <small class="text-danger">
                                                {this.validator.message('password', this.state.password, 'required|min:6|max:120')}
                                            </small>
                                        </div>
                                        <div className="form-group">
                                            <label className="font-weight-700">Verify Password *</label>
                                            <input value={this.state.verify_password} name="verify_password" id="verify_password"  className="form-control " placeholder="Verify Password" type="password" onChange={this.handleChange} />
                                            <small class="text-danger">
                                                {this.validator.message('verify_password', this.state.verify_password, `required|in:${this.state.password}`, {messages: {in: 'Passwords need to match!'}})}
                                            </small>
                                        </div>
                                        <br/>
                                        <p>* UPShiftauto.net is not responsible for the condition of vehicles purchased.  UPShiftauto.net will provide affiliated links at the dealers expense for private inspections.  If purchaser opts out of independent inspection, dealer takes full responsibility for condition of purchased vehicle from on UPShiftauto.net</p>

                                        <div className="text-left">
                                            <button className="site-button button-lg radius-no outline outline-2" name="SaveInfo" onClick={this.handleStep1}>Save info</button>
                                        </div>
                                    </form>
                                </div>
                            </div>

                            <br />
                            <div className="p-a30 border-1  max-w500 m-auto">
                                <div className="tab-content">
                                    <form id="login" className="tab-pane active">
                                        <h4 className="font-weight-700">Step 2</h4>
                                        <div className="tour-days" id="dropzone">
                                            <Dropzone onDrop={this.onDrop}>
                                              {({getRootProps, getInputProps}) => (
                                                <div {...getRootProps({baseStyle})} className={this.state.style_button_dropzone}>
                                                  <input {...getInputProps()} disabled={this.state.license_enabled}/>
                                                  Upload Dealer License
                                                </div>
                                              )}
                                            </Dropzone>
                                            <br />
                                        </div>
                                    </form>
                                </div>
                            </div>

                            <br />
                            <div className="p-a30 border-1  max-w500 m-auto">
                                <div className="tab-content">
                                    <h4 className="font-weight-700">Step 3</h4>
                                        <div className="text-left">
                                            <Card className={useStyles.root}>
                                                <CardContent className={useStyles.content}>
                                                    <CardInput />
                                                    <small class="text-danger">
                                                        {this.state.card_error &&
                                                            <span>{this.state.card_error}</span>
                                                        }
                                                    </small>
                                                    <br />
                                                    <div className={useStyles.div}>
                                                        <Button variant="contained" color={this.state.color_payment} className={useStyles.button} onClick={this.handleSubmitSub} disabled={this.state.payment_method_disable}>
                                                            Submit Payment Method
                                                        </Button>
                                                        <ToastContainer position="top-center"/>
                                                    </div>
                                                </CardContent>
                                            </Card>
                                        </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Link to={'./'} class="site-button m-r5 button-lg radius-no">Back to Homepage</Link>
                </div>
            </div>
        )
    }
}

export default DealerRegister;