import React, { Component } from 'react'
import { Api } from "../../utils/Api";
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import {ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import InputMask from 'react-input-mask';
import SimpleReactValidator from 'simple-react-validator';

const baseStyle = {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '20px',
    borderWidth: 2,
    borderRadius: 2,
    borderColor: '#eeeeee',
    borderStyle: 'dashed',
    backgroundColor: '#fafafa',
    color: '#bdbdbd',
    outline: 'none',
    transition: 'border .24s ease-in-out'
};

class DealerChangeTier extends Component {
    constructor(props) {
        super(props);
        this.validator = new SimpleReactValidator();
        this.state = {
            entity_id: '',
            tier: '',
            tier_list: [],
            license_enabled: true,
            style_button_dropzone: 'btn btn-secondary',
            password_style: false,
            tier_style: false,
        }
    }

    componentDidMount() {
        console.log("==========<<<<<<Didmount");
        Api.get('/ham/change_tier')
            .then(res => {
                console.log("Dealer Profile=========>",res.data.result);

                this.setState({
                    entity_id: res.data.result.entity_id,
                    tier: res.data.result.tier,
                    tier_list: res.data.result.tier_list,
                    license_enabled: true,
                    style_button_dropzone: 'btn btn-secondary',
                });
        });
    }

    handleChange = (e) => {
        this.setState({[e.target.name]: e.target.value})
    }

    handleStep1 = (e) => {
        e.preventDefault();
        
        if (this.validator.allValid()) {
            confirmAlert({
                title: 'Confirm to submit',
                message: 'Are you sure to do this.',
                buttons: [
                {
                    label: 'Yes',
                    onClick: () => {
                        // e.preventDefault();
                        const data  = {
                            tier: this.state.tier,
                        }
                        console.log("test=>", this.state)
                        Api.post('/ham/change_sub', {data: data})
                            .then(res => {
                                const location = "/dealer/profile";
                                window.location.assign(location);
                            });
                    }
                },
                {
                    label: 'No',
                    onClick: () => {
                        console.log("None");
                    }
                }
                ]
            });
        } else {
            this.validator.showMessages();
            // rerender to show messages for the first time
            // you can use the autoForceUpdate option to do this automatically`
            this.forceUpdate();
        }
    }


    render() {
        const {stripe} = this.props;
        const notify = () => toast("Wow so easy !");
        return (
            <div className="section-full content-inner-2 shop-account bg-white">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 text-center">
                            <h3 className="font-weight-700 m-t0 m-b20">Change Tier</h3>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="p-a30 border-1  max-w500 m-auto">
                                <div className="tab-content">
                                    <form id="login" className="tab-pane active">
                                        <h4 className="font-weight-700">Change Tier</h4>
                                        <div className="form-group">
                                            <label className="font-weight-700">Membership Level *</label>
                                            <select className="form-control" id="tier" value={this.state.tier} name="tier" required className="form-control" onChange={this.handleChange}>
                                            <option selected value=''> -- select an option -- </option>
                                            {this.state.tier_list.map(r =><option value={r.id} key={r.id}>{r.name}</option>)}
                                            </select>
                                            <small class="text-danger">
                                                {this.validator.message('membership level', this.state.tier, 'required|alpha_num')}
                                            </small>     
                                        </div>
                                        <div className="text-left">
                                            <button className="site-button button-lg radius-no outline outline-2" name="SaveInfo" onClick={this.handleStep1}>Save info</button>
                                            <ToastContainer position="top-center"/>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default DealerChangeTier;