import React, { Component } from 'react'
import Slider from 'react-animated-slider';
import 'react-animated-slider/build/horizontal.css';
import { Link } from 'react-router-dom';
import Slick from './Slick';
import TopPlaces from './../Element/TopPlaces';
import SellerHowTo from './../../components/SellerHowTo';
import Header from './../Layout/Header';
import Footer from './../Layout/Footer';




const content = [
    // {
    //     title: 'Vulputate Mollis Ultricies Fermentum Parturient',
    //     button: 'Read More',
    //     image: require('./../../images/main-slider/slide1.jpg'),
    // },
    {
        title: 'The Best Car Trading Experience',
        button: 'Discover',
        image: require('./../../images/main-slider/slide2_car.jpg'),
    },
    // {
    //     title: 'Tortor Dapibus Commodo Aenean Quam',
    //     button: 'Discover',
    //     image: require('./../../images/main-slider/slide3.jpg'),
    // }
];

var bg1 = require('./../../images/background/bg1.jpg');
var bg3 = require('./../../images/background/bg3.jpg');

class SellerHomepage extends Component {
    render() {

        return (
            <div>
                <Header originrole="SELLER"/>
                <div className="page-content">

                    <Slider className="slider-wrapper" disabled={true}>
                        {content.map((item, index) => (
                            <div
                                key={index}
                                className="slider-content"
                                style={{ background: `url('${item.image}') no-repeat center center` }}
                            >
                                <div className="inner">
                                    <h1>{item.title}</h1>
                                    <p>{item.description}</p>
                                </div>
                            </div>
                        ))}
                    </Slider>

                    <SellerHowTo />

                </div>
                <Footer />
            </div>
        )
    }
}
export default SellerHomepage;