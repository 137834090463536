import React, { Component } from 'react';
import Header2 from '../Layout/Header2';
import LandingHeader from '../Layout/LandingHeader';
import { Link, Redirect, useHistory } from 'react-router-dom';
import { useAuth } from "../../context/auth";
import queryString from 'query-string';

import { Api } from "../../utils/Api";

class VerifiedEmail extends Component {
    constructor(props) {
        super(props);
        this.state = {   
            c: '',  
            u: '',
            t: '',     
            h: ''
        }
    }

    componentDidMount() {
        let url = this.props.location.search;
        console.log(">> url:", url);
        let params = queryString.parse(url);
        console.log(">> params:", params, Object.getOwnPropertyNames(params));

        if (Object.getOwnPropertyNames(params).length > 0){
            var c = params['c'];
            var u = params['u'];
            var t = params['t'];
            var h = params['h'];
            console.log(u, t, c, h);
            this.setState({c:c, u:u, t:t, h:h});
        }
        console.log(">> verifyemailprecheck: calling api");
        const data  = {
            c: params['c'],
            u: params['u'],
            t: params['t'],
            h: params['h']
        }
        Api.post('/ham/verifyemailprecheck', data,{})
            .then(res => {
                console.log(">> verifyemailprecheck: res: ", res);
                this.setState({status: res.data.status});
            })
    }
    

    render() {
        return (
            <div>
                <LandingHeader />
                <div className="page-content section-full content-inner-2 shop-account bg-white">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12 text-center">
                                <h3 className="font-weight-700 m-t0 m-b20">Verified Email</h3>
                            </div>
                        </div>
                        <div>
                            <div className="max-w500 m-auto">
                                <div className="p-a30 border-1 seth">
                                    <div className="tab-content nav">
                                        <form id="login" className="tab-pane active col-12 p-a0 " onSubmit={this.submitEmail}>                                                                                       
                                            <p className="font-weight-600">Your Email Has Been Verified. Please login to continue.</p>                                            
                                            <div className="text-left">
                                                <Link to={'/login'} class="site-button m-r5 button-lg radius-no">Go to Login Page</Link>
                                            </div>
                                        </form>
                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        )
    }
}
export default VerifiedEmail;