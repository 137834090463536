import React, { Component } from 'react';
import { Link } from 'react-router-dom';

// import {} from 'react-router-dom'


import {AuthContext, getTokens, setTokens} from './../../context/auth';

class LandingHeader extends Component {

    state = {
        isLoggedIn: false
    }

    componentDidMount() {
        var authToken = getTokens().tokens;
        console.log(">> Header: componentDidMount: getting tokens from storage: authToken:", authToken);
        if (authToken != null && authToken != '') {
            console.log(">>>> AAA: authToken: ", authToken);
            this.setState({isLoggedIn:true});
        } else {
            console.log(">>>> BBB: authToken: ", authToken);
            this.setState({isLoggedIn:false});
        }
        console.log(">>>> CCC: authToken: ", authToken);

    }
    render() {
        return (
            <AuthContext.Consumer>
            {context => (
            <header className="site-header mo-left header">
                <div className="top-bar bg-dark">
                    <div className="container">
                        <div className="row d-flex justify-content-between">
                            <div className="dlab-topbar-left">
                                {/* <ul>
                                    <li>
                                        <Link to={'/hotel'} className="site-button-link"> Hotels </Link>
                                    </li>
                                    <li>
                                        <Link className="site-button-link" to={'/place'}>Places</Link>
                                    </li>
                                    <li><Link className="site-button-link" to={'/packages'}>Packages</Link></li>
                                </ul> */}
                            </div>
                            <div className="dlab-topbar-right top-login">
                                <ul>                                    
                                    {/*<li><Link to={'/login'} className="site-button-link" style={{ display: this.state.isLoggedIn === true? "none" : "" }}>Login</Link></li>
                                    <li><Link to={'/logout'} className="site-button-link" style={{ display: this.state.isLoggedIn === true? "" : "none" }}>Logout</Link></li>
                                    <li><Link to={'/register'} className="site-button-link" style={{ display: this.state.isLoggedIn === true? "none" : "" }}>Register</Link></li>*/}
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="sticky-header navbar-expand-lg">
                    <div className="main-bar clearfix onepage">
                        <div className="container clearfix">
                            <div className="logo-header mostion">
                                <Link to={'./'} ><img src={require('./../../images/logo.png')} alt="" /></Link>
                            </div>
                            <div className="header-nav navbar-collapse collapse navbar myNavbar justify-content-end" id="navbarNavDropdown">
                                <ul className="nav navbar-nav">
                                    { window.location.pathname.includes('dealer') ? 
                                        <li><Link to={'/dealer/dashboard'} className="dez-page">Back to Homepage</Link></li>
                                        : ''
                                    }
                                    { window.location.pathname.includes('seller') ? 
                                        <li><Link to={'/seller/dashboard'} className="dez-page">Back to Homepage</Link></li>
                                        : ''
                                    }
                                    { window.location.pathname != '/' &&
                                        !window.location.pathname.includes('dealer') &&
                                        !window.location.pathname.includes('seller') ? 
                                        <li><Link to={'/'} className="dez-page">Back to Homepage</Link></li>
                                        : ''
                                    }
                                    { !window.location.pathname.includes('about') ? 
                                        <li><Link to={'/about'} className="dez-page">About</Link></li>
                                        : ''
                                    }
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
            )}
            </AuthContext.Consumer>
        )
    }
}
export default LandingHeader;