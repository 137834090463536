import React, {useEffect} from 'react'
import { useAuth } from "../../context/auth";
import { Redirect, useHistory } from 'react-router-dom';
import { BaseApi, Api } from "../../utils/Api";

function Logout(props) {
    const {getAuthTokensOverloaded, setAuthTokensOverloaded} = useAuth();
    
    let history = useHistory();

    useEffect(() => {
        postLogout();
    }, []);

    const postLogout = () => {

        const response = Api.post("/authj/logout", {}, {}

            ).then(result => {
                console.log(">> logout: back from server");
                //var session = Cookies.get('CG_cookie2');
                console.log(">> logout: result:", result);
                if (result.status === 200 && result.data.res === true) {
                    var prevTokens = getAuthTokensOverloaded();
                    console.log(">> logout: prevTokens:", prevTokens.tokens );
                    setAuthTokensOverloaded(null);
                    if (prevTokens === null || prevTokens.tokens === null) {
                        history.replace('/');
                    } else if (prevTokens.role === 'SELLER') {
                        history.replace('/seller/');
                    } else if (prevTokens.role === 'DEALER') {
                        history.replace('/dealer/');
                    } else if (prevTokens.role === 'ADMIN') {
                        history.replace('/login');
                    }
                } else {
                    console.log(">> failed logging out on server");
                }

             }).catch(e => {
                console.log(">> logout error catch");
                 //setIsError(true);
        });
    }

    return (
        <div></div>
    )
}

export default Logout;