import React, { Component } from 'react'
import { Button, Form, FormGroup, Label, Input, FormText } from 'reactstrap';
import { Api } from "../../utils/Api";
import $ from 'jquery';
import ReactSlider from 'react-slider'
import '../../css/slider.css';
import { Link } from 'react-router-dom';
import InputMask from 'react-input-mask';
import SimpleReactValidator from 'simple-react-validator';
import queryString from 'query-string';

const baseStyle = {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '20px',
    borderWidth: 2,
    borderRadius: 2,
    borderColor: '#eeeeee',
    borderStyle: 'dashed',
    backgroundColor: '#fafafa',
    color: '#bdbdbd',
    outline: 'none',
    transition: 'border .24s ease-in-out'
};

class SellerRegister extends Component {
    constructor(props) {
        super(props);
        this.validator = new SimpleReactValidator();
        this.state = {
            entity_id: '',
            company_name: '',
            address: '',
            city: '',
            state: '',
            state_list: ["AL", "AK", "AZ", "AR", "CA", "CO", "CT", "DE", "FL", "GA", "HI",
                        "ID", "IL", "IN", "IA", "KS", "KY", "LA", "ME", "MD", "MA", "MI",
                        "MN", "MS", "MO", "MT", "NE", "NV", "NH", "NJ", "NM", "NY", "NC",
                        "ND", "OH", "OK", "OR", "PA", "RI", "SC", "SD", "TN", "TX", "UT",
                        "VT", "VA", "WA", "WV", "WI", "WY"],
            zip: '',
            phone: '',
            email: '',
            password: '',
            verify_password: '',
            best_time_to_contact: '',
            preferred_dealer_id: null,
            email_error: null,
            // entity_id: '',
            // company_name: 'dev',
            // address: 'bandung',
            // city: 'bandung',
            // state: 'jawabarat',
            // zip: '4029292',
            // phone: '0292282828',
            // email: 'dev.nextcoder@gmail.com',
            // password: 'ham123',
            // verify_password: 'ham123',
            // best_time_to_contact: '12',
        }
    }

    componentDidMount() {        
        let url = this.props.location.search;
        let params = queryString.parse(url);
        if (Object.getOwnPropertyNames(params).length > 0){
            var pd = params['pd'];
            if(pd != null){
                this.setState({
                    preferred_dealer_id: pd,
                });
            }
        }

    }

    handleChange = (e) => {
        this.setState({[e.target.name]: e.target.value})
    }

    handleStep1 = (e) => {
        'use strict';
        e.preventDefault();
        if (this.validator.allValid()) {

            const data  = {
                make: this.state.make,
                company_name: this.state.company_name,
                address: this.state.address,
                city: this.state.city,
                state: this.state.state,
                zip: this.state.zip,
                phone: this.state.phone,
                email: this.state.email,
                password: this.state.password,
                verify_password: this.state.verify_password,
                best_time_to_contact: this.state.best_time_to_contact,
                preferred_dealer_id: this.state.preferred_dealer_id                
            }
            const url_params = {
                c: '4m0jsDyIk5FxeasJEixQFA==',
                t: 'JAi2p-J9h1lELVfw3HaYYL9X3rsp0xniPs2nnsSsIlMFnfHKbnx7cLHKIGUDvWGNcLVJUTasOhL27mYJP9e7kQ'
            }
            console.log("test=>", this.state)
            Api.post('/ham/register_seller', {data: data}, {params: url_params})
                .then(res => {
                    console.log("error", this.state.errors)
                    if(res.data.errors == null){
                        const user = res.data.user;
                        console.log(user);
                        this.setState({ user });
                        if (res.status === 200) {
                            this.props.history.replace('/gotohomepage', {title: 'Registration Submitted.', msg: 'You will receive an email with instructions on how to complete the registration process.', role: 'SELLER'});
                        }
                    }else{
                        this.setState({email_error: res.data.errors});
                    }

                    this.setState({email_error: res.data.errors});
                        
                })
        } else {
            this.validator.showMessages();
            // rerender to show messages for the first time
            // you can use the autoForceUpdate option to do this automatically`
            this.forceUpdate();
        }
    }

    handleStep2 = (e) => {
        e.preventDefault();

    }

    render() {
        return (
            <div className="section-full content-inner-2 shop-account bg-white">
                <div className="container">
                    <div className="row">
                        <Link to={'./'} class="site-button m-r5 button-lg radius-no">Back to Homepage</Link>
                        <div className="col-md-12 text-center">
                            <h3 className="font-weight-700 m-t0 m-b20">SELLER REGISTRATION</h3>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="p-a30 border-1  max-w500 m-auto">
                                <div className="tab-content">
                                    <form id="login" className="tab-pane active needs-validation">
                                        <div className="form-group">
                                            <label className="font-weight-700">Name *</label>
                                            <input name="company_name" value={this.state.company_name} id="company_name" required="" className="form-control" placeholder="Enter your name" type="text" onChange={this.handleChange} />
                                            <small class="text-danger">{this.validator.message('company_name', this.state.company_name, 'required')}</small>
                                        </div>
                                        <div className="form-group">
                                            <label className="font-weight-700">Address *</label>
                                            <input name="address" value={this.state.address} id="address" required="" className="form-control" placeholder="Address" type="text" onChange={this.handleChange} />
                                        </div>
                                        <div className="form-group">
											<label className="font-weight-700">City *</label>
											<input name="city" value={this.state.city} id="city" required="" className="form-control" placeholder="City" type="text" onChange={this.handleChange} />
										</div>
                                        <div className="form-group">
											<label className="font-weight-700">State *</label>
											<select className="form-control" value={this.state.state_v} id="state_v" required name="state_v" className="form-control" onChange={this.handleChange}>
                                              <option selected value={-1}> -- select an option -- </option>
                                              {this.state.state_list.map(r =><option value={r} key={r}>{r}</option>)}
                                            </select>

                                            <small class="text-danger">
                                                {this.validator.message('state', this.state.state_v, 'required')}
                                            </small>
                                        </div>


										<div className="form-group">
											<label className="font-weight-700">Zip *</label>
											<InputMask mask="99999" value={this.state.zip} name="zip" id="zip" required="" className="form-control" placeholder="Zip" type="text" onChange={this.handleChange} />
										</div>
                                        <div className="form-group">
                                            <label className="font-weight-700">Phone *</label>
                                            <InputMask mask="(999) 999-9999" value={this.state.phone} id="phone" name="phone" required="" className="form-control" placeholder="Phone" type="text" onChange={this.handleChange} />
                                            <small class="text-danger">
                                                {this.validator.message('phone', this.state.phone, 'required')}
                                            </small>
                                        </div>
                                        <div className="form-group">
                                            <label className="font-weight-700">Email *</label>
                                            <input name="email" id="email" value={this.state.email} required="" className="form-control " placeholder="Email" type="email" onChange={this.handleChange} />
                                            <small class="text-danger">
                                                {this.validator.message('email', this.state.email, 'required|email')}
                                                {this.state.email_error != null &&
                                                    <span>{this.state.email_error}</span>
                                                }
                                            </small>
                                        </div>
                                        <div className="form-group">
                                            <label className="font-weight-700">Password *</label>
                                            <input name="password" value={this.state.password} id="password" required="" className="form-control " placeholder="Password" type="password" onChange={this.handleChange} />
                                            <small class="text-danger">
                                                {this.validator.message('password', this.state.password, 'required|min:6|max:120')}
                                            </small>
                                        </div>
                                        <div className="form-group">
                                            <label className="font-weight-700">Verify Password *</label>
                                            <input name="verify_password" value={this.state.verify_password} id="verify_password" required="" className="form-control " placeholder="Verify Password" type="password" onChange={this.handleChange} />
                                            <small class="text-danger">
                                                {this.validator.message('verify_password', this.state.verify_password, `required|in:${this.state.password}`, {messages: {in: 'Passwords need to match!'}})}
                                            </small>
                                        </div>
                                        <div className="form-group">
                                            <label className="font-weight-700">Best time to contact *</label>
                                            <input name="best_time_to_contact" value={this.state.best_time_to_contact} id="" required="best_time_to_contact" className="form-control " placeholder="Best time to contact" type="text" onChange={this.handleChange} />
                                        </div>
                                        <div className="text-left">
                                            <button className="site-button button-lg radius-no outline outline-2" name="SaveInfo" onClick={this.handleStep1}>Save info</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Link to={'./'} class="site-button m-r5 button-lg radius-no">Back to Homepage</Link>
                </div>
            </div>
        )
    }
}
export default SellerRegister;