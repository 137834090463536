import React, { Component, useState, useEffect } from 'react'
import { Link, Route , withRouter } from 'react-router-dom';
import { createHashHistory } from 'history'
import Header from './../Layout/Header';
import Footer from './../Layout/Footer';
import { Button, Form, FormGroup, Label, Input, FormText } from 'reactstrap';
import { useAuth } from "../../context/auth";
import { AuthContext } from "../../context/auth";
import { Api } from "../../utils/Api";
import { getTokens } from "../../context/auth";
import $ from 'jquery';
import ReactSlider from 'react-slider'
import '../../css/slider.css';
import queryString from 'query-string';

import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import {ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const state_models = {
    '1': [
        {'value':1, 'name':'Accord'},
        {'value':2, 'name':'Pilot'},
        {'value':3, 'name':'Odessy'},
        {'value':4, 'name':'Odyssey'},
    ],
    '2': [
        {'value':1, 'name':'Taurus'},
        {'value':2, 'name':'Explorer'},
        {'value':3, 'name':'Escape'},
        {'value':4, 'name':'Focus'},
    ]
};

var bg3 = require('./../../images/banner/bnr1.jpg');
class CarSearch extends Component {
    constructor(props) {
        super(props);
        this.state = {
            wishlist_name: '',
            make: -1,
            model: -1,
            trim: [],
            pricemin: 0,
            pricemax: 200,
            yearmin: 1994,
            yearmax: 2020,
            make_list: [],
            model_list:[],
            body_style_list: [],
            door_list: [],
            drive_type_list: [],
            ext_color_list: [],
            interior_color_list: [],
            model_list: [],
            transmission_list: [],
            trim_list: [],
            year_list: [],
            cars: [],
            options: [],
            saved_wishlist: [],
            conditions: [],
            door: [],
            drive_type: [],
            ext_color: [],
            int_color: [],
            body_style: [],
            transmission: [],
            tier: false,
            condition: [],
        };
    }

    loadDataToState(data) {

        const make_list = data.make_list;
        const body_style_list = data.body_style_list;
        const door_list = data.door;
        const drive_type_list = data.drive_type;
        const ext_color_list = data.ext_color;
        const interior_color_list = data.interior;
        const model_list = data.model;
        const transmission_list = data.transmission;
        const trim_list = data.trim;
        const year_list = data.year;
        const saved_wishlist = data.saved_wishlist;
        const tier = data.tier;        
        
        this.setState({ make_list, 
            body_style_list,
            door_list, 
            drive_type_list,
            ext_color_list,
            interior_color_list,
            model_list,
            transmission_list,
            trim_list,
            year_list,
            saved_wishlist,
            tier
        
        });
    }

    componentDidMount() {
        const headers = {
          'Access-Control-Allow-Origin': '*'
        }
        let url = this.props.location.search;
        let params = queryString.parse(url);

       
        Api.get('/ham/new_car')
            .then(res => {
                this.loadDataToState(res.data);
                if (Object.getOwnPropertyNames(params).length > 0){
                    const entity_id = params['entity_id'];
                    const tier = params['tier'];
                    this.setState({
                        saved_search:entity_id,
                    });
                    Api.get('/ham/get_saved_wishlist', {params: {sw_id: entity_id}})
                        .then(res => {
                        const model_list = res.data.result;
                        this.setState(model_list);
                        this.handleMainList();
                    });                        
                }
         });

         
    }

    handleMainList = (f) => {
        const data  = {
            make: this.state.make,
            model: this.state.model,
            trim: this.state.trim,
            conditions: this.state.conditions,
            door: this.state.door,
            drive_type: this.state.drive_type,
            ext_color: this.state.ext_color,
            int_color: this.state.int_color,
            body_style: this.state.body_style,
            transmission: this.state.transmission,
            yearmin: this.state.yearmin,
            yearmax: this.state.yearmax,
            pricemin: this.state.pricemin,
            pricemax: this.state.pricemax,
        }

        Api.post('/ham/dealer_car_search', {data: data})
            .then(res => {
            const cars = res.data.cars;
                this.setState({ cars });
            })
    }

    handleChange = (e) => {
        this.setState({[e.target.name]: e.target.value, saved_search: "-1"})
    }

    append_to_options = (e, state) => {
        const options = state
        console.log(state);
        console.log(e.target);
        let index
        // check if the check box is checked or unchecked
        if (e.target.checked) {
            options.push(+e.target.value)
        } else {
            // or remove the value from the unchecked checkbox from the array
            index = options.indexOf(+e.target.value)
            options.splice(index, 1)
        }
        return options
    }

    handleCheckBoxTrim = (e) => {
        // update the state with the new array of options
        this.setState({ trim: this.append_to_options(e, this.state.trim), saved_search: "-1" })
    }

    handleCheckBoxConditions = (e) => {
        // update the state with the new array of options
        this.setState({ conditions: this.append_to_options(e, this.state.conditions), saved_search: "-1" })
    }

    handleCheckBoxBodyStyle = (e) => {
        this.setState({ body_style: this.append_to_options(e, this.state.body_style), saved_search: "-1" })
    }

    handleCheckBoxDoor = (e) => {
        this.setState({ door: this.append_to_options(e, this.state.door), saved_search: "-1" })
    }

    handleCheckBoxDriveType = (e) => {
        this.setState({ drive_type: this.append_to_options(e, this.state.drive_type), saved_search: "-1" })
    }

    handleCheckBoxExtColor = (e) => {
        this.setState({ ext_color: this.append_to_options(e, this.state.ext_color), saved_search: "-1" })
    }
   
    handleCheckBoxIntColor = (e) => {
        this.setState({ int_color: this.append_to_options(e, this.state.int_color), saved_search: "-1" })
    }

    handleCheckBoxTransmission = (e) => {
        this.setState({ transmission: this.append_to_options(e, this.state.transmission), saved_search: "-1" })
    }

    handleChangeSaveWishlist = (e) => {
        this.setState({[e.target.name]: e.target.value});
        Api.get('/ham/get_saved_wishlist', {params: {sw_id: e.target.value}})
            .then(res => {
         
            this.state.make_list.push(res.data.make_list);
            const model_list = res.data.result;
            this.setState(model_list);
        });
    }

    handleDeleteWishlist = (e) => {
        e.preventDefault();
        confirmAlert({
            title: 'Confirm to Delete',
            message: 'Are you sure to do this.',
            buttons: [
                {
                    label: 'Yes',
                    onClick: async () => {
                         Api.get('/ham/delete_saved_wishlist', {params: {sw_id: this.state.saved_search}})
                             .then(res => {
                             const model_list = res.data;
                             this.setState(model_list);

                         })
                        toast("Delete was successful", {autoClose: 3000});
                    }
                },
                {
                    label: 'No',
                    onClick: () => {
                        console.log("None");
                    }
                }
            ]
        });
    }

    handleClearWishlist = (e) => {
        e.preventDefault();
        this.setState({
            saved_search:-1,
            make: -1,
            model: -1,
            pricemin: 0,
            pricemax: 200,
            yearmin: 2015,
            yearmax: 2020,
            conditions: '',
            condition: [],
            door: '',
            drive_type: '',
            ext_color: '',
            int_color: '',
            body_style: '',
            transmission: '',
            cars: []
            });
    }

    handleChangeMake = (e) => {
        Api.get('/ham/car_model', {params: {make: e.target.value}})
            .then(res => {
            const model_list = res.data.model;
            this.setState({ model_list});
        })
        this.setState({[e.target.name]: e.target.value, saved_search: "-1", model: -1, trim: [], conditions: '', saved_wishlist: []})
    }

    handleChangeTrim = (e) => {
        Api.get('/ham/car_trim', {params: {model: e.target.value}})
            .then(res => {
            const trim_list = res.data.trim_list;
            const trim = [];
            this.setState({ trim_list, trim });
        })
        this.setState({[e.target.name]: e.target.value, saved_search: "-1", conditions: ''})
    }

    handleChecked = (state, id) => {
        if (state.includes(id) == true){
            return true
        }else{
            return false
        }
    }

    handleSearch = event => {
        event.preventDefault();

        this.handleMainList();
    }

    handleSaveWishlist = event => {
        event.preventDefault();

        const data  = {
            wishlist_name: this.state.wishlist_name,
            filter: {
                make: this.state.make,
                model: this.state.model,
                trim: this.state.trim,
                conditions: this.state.conditions,
                door: this.state.door,
                drive_type: this.state.drive_type,
                ext_color: this.state.ext_color,
                int_color: this.state.int_color,
                body_style: this.state.body_style,
                transmission: this.state.transmission,
                yearmin: this.state.yearmin,
                yearmax: this.state.yearmax,
                pricemin: this.state.pricemin,
                pricemax: this.state.pricemax,
            }
        }

        Api.post('/ham/save_wishlist', {data:data})
        .then(res => {
            const saved_wishlist = res.data.saved_wishlist;
            const saved_search = res.data.saved_search;
            const wishlist_name = '';
            this.setState({ saved_wishlist, saved_search, wishlist_name});

            toast("Save successful", {autoClose: 3000});
        })
    }

    updateYearMinMax(range) {
        this.setState({yearmin: range[0],
        yearmax: range[1], saved_search: "-1"});
    }
    updatePriceMinMax(range) {
        this.setState({
            pricemin: range[0],
            pricemax: range[1],
            saved_search: "-1",
        });
    }

    createSelectModel(brand) {
        const models = [
            {'brand': 'Honda',
            'models': [
                {'value':1, 'name':'Accord'},
                {'value':2, 'name':'Pilot'},
                {'value':3, 'name':'Odessy'},
                {'value':4, 'name':'Odyssey'},
            ]},
            {'brand': 'Ford',
            'models': [
                {'value':1, 'name':'Taurus'},
                {'value':2, 'name':'Explorer'},
                {'value':3, 'name':'Escape'},
                {'value':4, 'name':'Focus'},
            ]}
        ];
        let s_model = models.find(o => o.brand == brand);
        let items = [];
        let option_models = s_model.models.map(v => (
            <option value={v.value}>{v.name}</option>
        ));

        // for (let i = 0; i <= this.props.maxValue; i++) {             
        //      items.push(<option key={i} value={i}>{i}</option>);   
        // }
        return option_models;
    }

    onToggleOpen = field => (e) => {
        Api.get('/ham/get_list', {params: {'field': field, 'make_id': this.state.make, 'model_id': this.state.model}})
        .then(res => {
            this.setState(res.data.val);
        })
    }


   
    render() {
        const { brand } = this.state;
        const model_options = state_models[brand];

        return (
            <div>
                <Header originrole="DEALER"/>
                {/* <div className="dlab-bnr-inr overlay-black-middle" style={{ backgroundImage: "url(" + bg3 + ")", backgroundSize: 'cover' }}>
                    <div className="container">
                        <div className="dlab-bnr-inr-entry">
                            <h1 className="text-white">Search Car</h1>
                            <div className="breadcrumb-row">
                                <ul className="list-inline">
                                    <li><Link>Home</Link></li>
                                    <li>Search Car</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div> */}
                <div className="section-full bg-white content-inner dlab-about-1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-4 col-md-5 col-sm-5">
                                <div className="widget">
                                    
                                    <h5 className="font-weight-400 widget-title">Define Search</h5>                                                                        
                                    <br />
                                    <div>
                                        <button className="site-button" name="search-up" id="search-up" type="button" onClick={this.handleSearch}>Search</button>
                                        &nbsp;
                                        <button className="site-button" id="clear_wishlist" name="clear_wishlist" type="button" onClick={this.handleClearWishlist}>Clear</button>
                                    </div>
                                    <br /><br />
                                    <Form>
                                        <FormGroup>
                                            <Label for="year">Year</Label>
                                            <ReactSlider
                                                name="year_range"
                                                id="year_range"
                                                className="horizontal-slider"
                                                thumbClassName="example-thumb"
                                                trackClassName="example-track"
                                                defaultValue={[1994, 2020]}
                                                min={1994}
                                                max={2020}
                                                value={[this.state.yearmin, this.state.yearmax]}
                                                step={1}
                                                ariaLabel={['Lower thumb', 'Upper thumb']}
                                                ariaValuetext={state => `Thumb value ${state.valueNow}`}
                                                renderThumb={(props, state) => <div {...props}>{state.valueNow}</div>}
                                                minDistance={1}
                                                onChange={val => this.updateYearMinMax(val)}
                                            />
                                            <br />
                                            <br />
                                            <div class="row">
                                                <div class="col-md-6">
                                                    Between &nbsp;
                                                    <Input type="text" name="yearmin" id="yearmin" class=""
                                                    value={(this.state.yearmin)} onChange={this.onChange}></Input>
                                                </div>
                                                <div class="col-md-6">
                                                    and&nbsp;
                                                    <Input type="text" name="yearmax" id="yearmax" class=""
                                                    value={(this.state.yearmax)} onChange={this.onChange}></Input>
                                                </div>
                                            </div>
                                            <br/>
                                            <Label for="make">Make</Label>
                                            <select className="form-control" id="make" name="make" onMouseOver={this.onToggleOpen('preferred_dealer_list')} value={this.state.make} onChange={this.handleChangeMake}>
                                              <option disabled value={-1}> -- select an option -- </option>
                                              {this.state.make_list.map(r => <option value={r.id} key={r.id}>{r.name}</option> )}
                                            </select>
                                            <br/>
                                            <Label for="model">Model</Label>
                                            {this.state.make != -1 ?
                                                <select className="form-control" id="model" name="model" value={this.state.model} onChange={this.handleChangeTrim}>
                                                    <option disabled value={-1}> -- select an option -- </option>
                                                    {this.state.model_list.map(r => <option value={r.id} key={r.id}>{r.name}</option>)}
                                                </select>
                                                : <p>Please select Make to see the Models.</p>
                                            }
                                            <br/>
                                            <Label for="trim">Trim</Label>
                                            {this.state.model != -1 ?
                                            
                                                this.state.trim_list.map(r => <div key={r.id} ><input name={'trim_'+r.id} type="checkbox" value={r.id} onChange={this.handleCheckBoxTrim} id={'trim_'+r.id} checked={this.handleChecked(this.state.trim, r.id)}/><label htmlFor={'trim_'+r.id}> {r.name} </label></div>)
                                                : <p>Please select Make and Model to see the Trims.</p>
                                            }
                                            <br />
                                            {/* <Label for="trim">Trim</Label>
                                            <select className="form-control" id="trim" name="trim" value={this.state.trim}  onChange={this.handleChange}>
                                              <option disabled selected value={-1}> -- select an option -- </option>
                                              {this.state.trim_list.map(r => <option value={r.id} key={r.id}>{r.name}</option>)}
                                            </select> */}
                                            <Label for="price">Price</Label>
                                            <ReactSlider
                                                name="price_minmax"
                                                id="price_minmax"
                                                className="horizontal-slider"
                                                thumbClassName="example-thumb"
                                                trackClassName="example-track"
                                                defaultValue={[0, 200]}
                                                value={[this.state.pricemin, this.state.pricemax]}
                                                min="0"
                                                max="200"
                                                step="10"
                                                ariaLabel={['Lower thumb', 'Upper thumb']}
                                                ariaValuetext={state => `Thumb value ${state.valueNow}`}
                                                renderThumb={(props, state) => <div {...props}>{state.valueNow}</div>}
                                                pearling
                                                minDistance={10}
                                                onChange={val => this.updatePriceMinMax(val)}
                                            />
                                            Between&nbsp;
                                            <Input type="text" name="pricemin" id="pricemin"
                                                value={'$' + (this.state.pricemin * 1000)}></Input>
                                            and&nbsp;
                                            <Input type="text" name="pricemax" id="pricemax"
                                                value={'$' + (this.state.pricemax * 1000)}></Input>
                                            <br />
                                            <br />
                                            <Label for="body_style">Body Style</Label>
                                            {
                                                this.state.body_style_list.map(r => <div key={r.id} ><input name={'body_style_'+r.id} type="checkbox" value={r.id} onChange={this.handleCheckBoxBodyStyle} id={'body_style_'+r.id} checked={this.handleChecked(this.state.body_style, r.id)}/><label htmlFor={'body_style_'+r.id}> {r.name} </label></div>
                                            )}
                                            <br />
                                            <Label for="door">Door</Label>
                                            {
                                                this.state.door_list.map(r => <div key={r.id} ><input name={'door_'+r.id} type="checkbox" value={r.id} onChange={this.handleCheckBoxDoor} id={'door_'+r.id} checked={this.handleChecked(this.state.door, r.id)}/><label htmlFor={'door_'+r.id}> {r.name} </label></div>
                                            )}
                                            <br />
                                            <Label for="drive_type">Drive Type</Label>
                                            {
                                                this.state.drive_type_list.map(r => <div key={r.id} ><input name={'drive_type_'+r.id} type="checkbox" value={r.id} onChange={this.handleCheckBoxDriveType} id={'drive_type_'+r.id} checked={this.handleChecked(this.state.drive_type, r.id)}/><label htmlFor={'drive_type_'+r.id}> {r.name} </label></div>
                                            )}
                                            <br />
                                            <Label for="ext_color">Exterior Color</Label>
                                            {
                                                this.state.ext_color_list.map(r => <div key={r.id} ><input name={'ext_color_'+r.id} type="checkbox" value={r.id} onChange={this.handleCheckBoxExtColor} id={'ext_color_'+r.id} checked={this.handleChecked(this.state.ext_color, r.id)}/><label htmlFor={'ext_color_'+r.id}> {r.name} </label></div>
                                            )}
                                            <br />
                                            <Label for="interior_color">Interior Color</Label>
                                            {
                                                this.state.interior_color_list.map(r => <div key={r.id} ><input name={'interior_color_'+r.id} type="checkbox" value={r.id} onChange={this.handleCheckBoxIntColor} id={'interior_color_'+r.id} checked={this.handleChecked(this.state.int_color, r.id)}/><label htmlFor={'interior_color_'+r.id}> {r.name} </label></div>
                                            )}
                                            <br />
                                            <Label for="transmission">Transmission</Label>
                                            {
                                                this.state.transmission_list.map(r => <div key={r.id} ><input name={'transmission_'+r.id} type="checkbox" value={r.id} onChange={this.handleCheckBoxTransmission} id={'transmission_'+r.id} checked={this.handleChecked(this.state.transmission, r.id)}/><label htmlFor={'transmission_'+r.id}> {r.name} </label></div>
                                            )}
                                            <br />

                                            <button className="site-button" type="button" onClick={this.handleSearch}>Search</button>
                                        </FormGroup>
                                    </Form>
                                </div>
                            </div>
                            <div className="col-lg-8 col-md-7 col-sm-7">
                                
                                {this.state.tier == false &&
                                    <div className="m-b10">
                                        {/* <ToastContainer position="top-right" autoClose={2000}/> */}
                                        <h5 className="font-weight-400 widget-title">Wishlist (Saved Search)</h5>

                                        {/* <Label for="make">Wishlist (Saved Search)</Label> */}
                                        <form>
                                            <div className="form-group">
                                                <div className="input-group">  
                                                    <select className="form-control" id="saved_search" name="saved_search" value={this.state.saved_search} onChange={this.handleChangeSaveWishlist}>
                                                    <option disabled selected value={-1}> -- select an option -- </option>
                                                    {this.state.saved_wishlist.map(r =><option value={r.id} key={r.id}>{r.name}</option>)}
                                                    </select>
                                                    &nbsp;
                                                    <span className="input-group-btn p-l15">
                                                    <button className="site-button" id="delete_wishlist" name="delete_wishlist" type="button" onClick={this.handleDeleteWishlist}>Delete This Wishlist</button>                                        
                                                    </span>
                                                </div>
                                            </div>
                                        </form>                                    
                                        <form>
                                            <div className="form-group">
                                                <div className="input-group">                                            
                                                    <input type="text" className="form-control" id="wishlist_name" name="wishlist_name" value={this.state.wishlist_name} onChange={this.handleChange}/>
                                                    <span className="input-group-btn p-l15">
                                                        <button className="site-button" type="button" onClick={this.handleSaveWishlist}>Save This Wishlist</button>                                                    
                                                    </span>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                }
                                
                                <div className="row">
                                    
                                    <div className="col-md-12 col-sm-12 m-b15">
                                        <h3 className="m-b5"></h3>
                                        <div className="dlab-separator bg-primary"></div>
                                    </div>
                                    {this.state.cars.map(car => (
//                                    <li key={car.id}>{car.make}</li>)}
//                                    {placeList.map((item, index) => (
                                       <div className="col-md-12 col-lg-12 col-sm-12 m-b30" key={car.id}>
                                             <div className="dlab-box packages-bx">
                                                <div className="dlab-media">
                                                    <Link to={'/dealer/cardetails?entity_id='+car.id}><img src={car.img_path} alt="" /></Link>
                                                    {/* <span className="tag yellow">Best Seller</span> */}
                                                </div>
                                                <div className="dlab-info">
                                                    <div className="head-title">
                                                        <h4 className="dlab-title"><Link to={'/dealer/cardetails?entity_id='+car.id}>{car.year} {car.make} {car.model} {car.trim}</Link></h4>

                                    <span className="location">{car.odometer} Miles {car.exterior_color} {car.transmission}</span>
                                                    </div>
                                                    <div className="package-content">
                                                        <div className="review-bx">
                                                        </div>
                                                        {/* <div className="price-bx">
                                                            <span className="price-main">${car.price}</span>
                                                        </div> */}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ))}

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        )
    }
}
export default CarSearch;