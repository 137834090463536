import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import Header from './../Layout/Header';
import Footer from './../Layout/Footer';
import axios from 'axios';
import { useAuth } from "../../context/auth";
import { AuthContext } from "../../context/auth";
import { Api } from "../../utils/Api";
import { getTokens } from "../../context/auth";
import Slider from "react-slick";

import { Button, Form, FormGroup, Label, Input, FormText } from 'reactstrap';

var bg3 = require('./../../images/banner/bnr1.jpg');
//var bg3 = require('');

function WishHitNextArrow(props) {
    const { onClick } = props;
    return (
      <div className="la la-angle-right nav-right"  onClick={onClick}/>
    );
  }
  
function WishHitPrevArrow(props) {
    const { onClick } = props;
    return (
      <div className="la la-angle-left nav-left" onClick={onClick}/>
    );
  }

class DealerDashboard extends Component {

    state = {
        cars: [],
        wishlist_list: [],
        car_wishlist: [],
        wishlist: -1,
        tier_name: '',
        transactions: []
    }

    componentDidMount() {
        Api.get('/ham/dealer_dashboard')
            .then(res => {
            const cars = res.data.cars;
            const wishlist_list = res.data.wishlist;
            const tier_name = res.data.tier_name;
            const transactions = res.data.transactions;
                this.setState({ cars, wishlist_list, tier_name, transactions});
            })
    }

    onChangeWishlist = (e) => {
        e.preventDefault();

        this.setState({[e.target.name]: e.target.value});
        Api.get('/ham/get_saved_wishlist_dropdown', {params: {sw_id: e.target.value}})
            .then(res => {
            const car_wishlist = res.data.car_wishlist;
            this.setState({car_wishlist});
        });
    }

    handleGenerate = (e) => {
        console.log("test");
        Api.get('/ham/generate_links')
        .then(res => {
        // const cars = res.data.cars;
        // const wishlist = res.data.wishlist;
            // console.log("Dealer Dashboard=>>>",cars);
            // console.log("Dealer Wishlist=>>>",wishlist);
            // this.setState({ cars, wishlist});
        })
    }

    render() {
        var settings = {
            dots: false,
            slidesToShow: 3,
            infinite: false,
			nextArrow: <WishHitNextArrow />,
			prevArrow: <WishHitPrevArrow />,
			responsive: [
				{
				  breakpoint: 1200,
				  settings: {
					slidesToShow: 3,
				  }
				},
				{
				  breakpoint: 991,
				  settings: {
					slidesToShow: 2,
				  }
				},
				{
				  breakpoint: 576,
				  settings: {
					slidesToShow: 1,
				  }
				}
			]
        };

        return (
            <div>
                <Header originrole="DEALER"/>
                {/* <div className="dlab-bnr-inr overlay-black-middle" style={{ backgroundImage: "url(" + bg3 + ")", backgroundSize: 'cover' }}>
                    <div className="container">
                        <div className="dlab-bnr-inr-entry">
                            <h1 className="text-white">Place</h1>
                            <div className="breadcrumb-row">
                                <ul className="list-inline">
                                    <li><Link>Home</Link></li>
                                    <li>Place</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div> */}
                <div className="section-full bg-white content-inner dlab-about-1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12 col-md-12 col-sm-12">
                                <div className="row">

                                    <div className="col-md-12 col-sm-12 m-b15">
                   
                                            <div className="content-inner-2 overlay-white-middle">
                                                <div className="container">
                                                    <div className="section-head text-black">
                                                        <h2 className="text-uppercase m-b0">Wishlist hits:</h2>
                                                    </div>
                                                    {this.state.wishlist_list.length > 0 ?
                                                        <div>
                                                            <Label for="wishlist">Wishlist</Label>
                                                            <select className="form-control" id="wishlist" name="wishlist" value={this.state.wishlist} onChange={this.onChangeWishlist}>
                                                            <option disabled value={-1}> -- select an option -- </option>
                                                            {this.state.wishlist_list.map(r => <option value={r.id} key={r.id}>{r.name}</option> )}
                                                            </select>
                                                            <br/>
                                                            {this.state.wishlist != -1 ?
                                                                <Slider className="blog-carousel nav-btn-center-lr btn-1" {...settings}>
                                                                    {this.state.car_wishlist.map(w => (
                                                                        <div className="item p-3" key={w.car_id}>
                                                                            <div className="blog-post blog-grid blog-style-1">
                                                                                <div className="dlab-post-media dlab-img-effect radius-sm">
                                                                                    <Link to={'./cardetails?entity_id='+w.car_id}><img src={w.img_path} alt="" /></Link>
                                                                                </div>
                                                                                <div className="dlab-info">
                                                                                    <div className="dlab-post-meta">
                                                                                        <ul className="d-flex align-items-center">
                                                                                            <li className="post-date"></li>
                                                                                            <li className="post-comment"><Link>{w.make}</Link> </li>
                                                                                        </ul>
                                                                                    </div>
                                                                                    <div className="dlab-post-title ">
                                                                                        <h5 className="post-title font-20"><Link to={'./carsearch'}></Link></h5>
                                                                                    </div>
                                                                                    <div className="dlab-post-readmore blog-share">
                                                                                        <Link to={'./carsearch?entity_id='+w.wishlist_id} title="READ MORE" rel="bookmark" className="site-button-link border-link black">Goto wishlist</Link>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    ))}
                                                                </Slider>
                                                                :<p>Please select wishlist to see hits.</p>
                                                            }
                                                        </div>
                                                        :<p>You don't have any Wishlist or Hits yet.</p>
                                                    }
                                                </div>
                                            </div>
                                    </div>
                                   
                                </div>
                                <div className="row">
                                    <div className="col-md-6 col-sm-6">
                                        <div className="col-md-12 col-sm-12 m-b15">
                                            <div className="row">
                                                <div className="col-md-6 col-sm-6 m-6">
                                                    <h3 className="m-b5">My Bids :</h3>
                                                </div>                                           
                                            </div>
                                        </div>
                                        {this.state.cars.length > 0 ?
                                            <div>
                                                {this.state.cars.map(car => (
            //                                    <li key={car.id}>{car.make}</li>)}
            //                                    {placeList.map((item, index) => (
                                                <div className="col-md-12 col-lg-12 col-sm-12 m-b30" key={car.id}>
                                                        <div className="dlab-box packages-bx">
                                                            <div className="dlab-media">
                                                                <Link to={'/dealer/cardetails?entity_id='+car.id}><img src={car.img_path} alt="" /></Link>
                                                            </div>
                                                            <div className="dlab-info">
                                                                <div className="head-title">
                                                                    <h4 className="dlab-title"><Link to={'/dealer/cardetails?entity_id='+car.id}>{car.year} {car.make} {car.model} {car.trim}</Link></h4>

                                                                    <span className="location">{car.odometer} Miles {car.exterior_color} {car.transmission} {car.cylinder}</span>

                                                                </div>
                                                                <div className="package-content">
                                                                    <div className="review-bx">
                                                                    </div>
                                                                    {/* <div className="price-bx">
                                                                        <span className="price-main">${car.price}</span>
                                                                    </div> */}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                            :<p>You don't currently have any Bids.</p>
                                        }
                                    </div>
                                    <div className="col-md-6 col-sm-6">
                                        <div className="col-md-12 col-sm-12 m-b15">
                                            <div className="row">
                                                <div className="col-md-6 col-sm-6 m-6">
                                                    <h3 className="m-b5">My Transactions :</h3>
                                                </div>                                           
                                            </div>
                                        </div>
                                        {this.state.transactions.length > 0 ?
                                            <div>
                                                {this.state.transactions.map(car => (
            //                                    <li key={car.id}>{car.make}</li>)}
            //                                    {placeList.map((item, index) => (
                                                <div className="col-md-12 col-lg-12 col-sm-12 m-b30" key={car.id}>
                                                        <div className="dlab-box packages-bx">
                                                            <div className="dlab-media">
                                                                <Link to={'/dealer/cardetails?entity_id='+car.id}><img src={car.img_path} alt="" /></Link>
                                                            </div>
                                                            <div className="dlab-info">
                                                                <div className="head-title">
                                                                    <h4 className="dlab-title"><Link to={'/dealer/cardetails?entity_id='+car.id}>{car.year} {car.make} {car.model} {car.trim}</Link></h4>

                                                                    <span className="location">{car.odometer} Miles {car.exterior_color} {car.transmission} {car.cylinder}</span>

                                                                </div>
                                                                <div className="package-content">
                                                                    <div className="review-bx">
                                                                    </div>
                                                                    {/* <div className="price-bx">
                                                                        <span className="price-main">${car.price}</span>
                                                                    </div> */}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                            :<p>You don't currently have any Transaction yet.</p>
                                        }
                                    </div>
                                </div>
                                <div className="row">                                           
                                    {this.state.tier_name == 'Tier1' || this.state.tier_name == 'Tier3' &&
                                        <div className="col-md-6 col-sm-6 m-6">
                                            {/* <button type="submit" className="site-button btn-block" onClick={this.handleGenerate}>Generate Link</button> */}
                                            <Link to={'../dealer/generateemail'} title="Generate Email" rel="bookmark" className="site-button button-lg radius-no outline outline-2">Generate Seller Invitation Email</Link>
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        )
    }
}
export default DealerDashboard;