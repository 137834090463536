import React, { Component } from 'react';
import axios from 'axios';
import { useAuth } from "../context/auth";
import { AuthContext } from "../context/auth";
import { Api } from "../utils/Api";
import { getTokens } from "../context/auth";


class BidForm extends Component {

    state = {
        bid_price: '',
        listing_id: '',
        bid: {}
    }

    componentWillReceiveProps(nextProps){
        console.log("---------------------------", nextProps);
        this.setState({
            listing_id: nextProps.val.listing_id,
            bid: nextProps.val.bid
            })
        }


    handleChange = (e) => {
        this.setState({[e.target.name]: e.target.value})
    }

    componentDidMount() {

    }

    handleSubmit = event => {
        event.preventDefault();
        console.log(this.state)

        const headers = {
          'Access-Control-Allow-Origin': '*'
        }
        Api.post('/ham/place_bid', {data:this.state})
            .then(res => {
                console.log(res);
                // this.setState({bid: res.data.bid});
                this.props.handler(res.data.bid);
            })
    }
 render() {
  return (
   <div>
	<form className="hotel-booking" onSubmit={this.handleSubmit}>
    <div className="row">
        <div className="col-md-12 col-lg-12 col-xl-12 col-sm-12 col-12">
            {Object.keys(this.state.bid).length === 0 ? 
            <div className="form-group">
                <div className="input-group">
                    <input className="form-control" id="bid_price" type="text"  name="bid_price" onChange={this.handleChange}/>
                </div>
                <span className="font-12">Bid Amount</span>
            </div>
            : <p>You offered ${this.state.bid.bid_price}</p> 
            }
        </div>
        <div className="col-md-12 col-lg-12 col-xl-12 col-sm-12 col-12">
            {Object.keys(this.state.bid).length === 0 ?
            <button type="submit" className="site-button btn-block">Place Bid</button>
            : <span></span>
            }
        </div>
    </div>
    </form>
   </div>
  );
 }
}

export default BidForm;