import React, { Component } from 'react'
import Slider from 'react-animated-slider';
import 'react-animated-slider/build/horizontal.css';
import Slick2 from './Slick2';
import HomeBlog from './../Element/HomeBlog';
import { Link } from 'react-router-dom';

import LandingHeader from './../Layout/LandingHeader';
import Footer from './../Layout/Footer';
import Tab2 from './../Pages/Tab2';

const content = [
    {

        title: 'THE BEST CAR TRADING EXPERIENCE',
		description: '',
        image: require('./../../images/main-slider/slide2_car.jpg'),
    },
    // {
    //     title: 'THE BEST CAR TRADING EXPERIENCE',
	// 	description: '',
    //     image: require('./../../images/main-slider/slide2.jpg'),
    // },
    // {
    //     title: 'THE BEST CAR TRADING EXPERIENCE',
	// 	description: '',
    //     image: require('./../../images/main-slider/slide3.jpg'),
    // }
];




var bg1 = require('./../../images/main-slider/slide2_car.jpg');



class LandingHomepage extends Component {

    render() {

		const settings = {
		  dots: false,
		  infinite: true,
		  slidesToShow: 3,
		  slidesToScroll: 1,

		};

        return (
            <div>

                <LandingHeader/>
                <Slider className="slider-wrapper" {...settings}>
                    {content.map((item, index) => (
                        <div
                            key={index}
                            className="slider-content"
                            style={{ background: `url('${item.image}') no-repeat center center` }}
                        >
                            <div className="inner">
                                <h1 className="react-slide-title">{item.title}</h1>
                                <p className="react-slide-desc">{item.description}</p>
                            </div>
                        </div>
                    ))}
                </Slider>
                <div className="content-block" id="page_content">


                    <div className="section-full bg-white content-inner-2">
                        <div className="container">

                            <div className="row">

                                <div className="col-lg-6 col-md-6 col-sm-12">
                                    <div className="featured-bx style2">
                                        <div className="featured-media">
                                            <img src={require('./../../images/hamilton/car_agent_dealership_photo_2_small.jpg')} alt="" />
                                        </div>
                                        <div className="featured-content text-white">
                                            <div>
                                                <h2 className="title"><Link to={'/dealer'}>For Dealers</Link></h2>
                                                <p>Search our inventory of ready-to-buy cars</p>
                                                <Link to={'/dealer'} className="site-button outline white">Go</Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6 col-sm-12">
                                    <div className="featured-bx style2">
                                        <div className="featured-media">
                                            <img src={require('./../../images/hamilton/car_owner_small.jpg')} alt="" />
                                        </div>
                                        <div className="featured-content text-white">
                                            <div>
                                                <h2 className="title"><Link to={'/seller'}>For Sellers</Link></h2>
                                                <p>Sell to interested Dealerships</p>
                                                <Link to={'/seller'} className="site-button outline white">Go</Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>

                        </div>
                    </div>

                    <div className="section-full content-inner bg-img-fix overlay-black-middle" style={{ backgroundImage: "url(" + bg1 + ")" }}>
                        <div className="container">
                            <div className="video-banner-box">
                                <div>
                                    <Link to={'https://www.youtube.com/watch?v=Dj6CKxQue7U'} className="popup-youtube video play-btn"><i className="fa fa-play"></i></Link>
                                    <h2 className="title">Hassle-free</h2>
                                    <h4 className="sub-title">Car Trading</h4>

                                </div>
                            </div>
                        </div>
                    </div>



                    {/* <Slick2 /> */}





                </div>

                <Footer />


            </div>
        )
    }
}
export default LandingHomepage;