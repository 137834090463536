import React, { Component, useState } from 'react'
import { Link } from 'react-router-dom';
import Header from './../Layout/Header';
import Footer from './../Layout/Footer';

import { Button, Form, FormGroup, Label, Input, FormText } from 'reactstrap';
import { Api } from "../../utils/Api";
import $ from 'jquery';
import ReactSlider from 'react-slider'
import '../../css/slider.css';
import Dropzone from 'react-dropzone'
import Resizer from 'react-image-file-resizer';
import EXIF from "exif-js";

// Stripe
import {useStripe, CardElement, ElementsConsumer, useElements, Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardInput from './../../components/CardInput';
import TextField from '@material-ui/core/TextField';
import {makeStyles} from '@material-ui/core/styles';
import { element } from 'prop-types';

import InputMask from 'react-input-mask';


const baseStyle = {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '20px',
    borderWidth: 2,
    borderRadius: 2,
    borderColor: '#eeeeee',
    borderStyle: 'dashed',
    backgroundColor: '#fafafa',
    color: '#bdbdbd',
    outline: 'none',
    transition: 'border .24s ease-in-out'
};

const useStyles = makeStyles({
    root: {
      maxWidth: 500,
      margin: '35vh auto',
    },
    content: {
      display: 'flex',
      flexDirection: 'column',
      alignContent: 'flex-start',
    },
    div: {
      display: 'flex',
      flexDirection: 'row',
      alignContent: 'flex-start',
      justifyContent: 'space-between',
    },
    button: {
      margin: '2em auto 1em',
    },
  });

class DealerProfile extends Component {
    constructor(props) {
        super(props);
        this.state = {
            entity_id: '',
            company_name: '',
            tier: '',
            tier_list: [],
            address: '',
            city: '',
            state: '',
            states: ["AL", "AK", "AZ", "AR", "CA", "CO", "CT", "DE", "FL", "GA", "HI",
                    "ID", "IL", "IN", "IA", "KS", "KY", "LA", "ME", "MD", "MA", "MI",
                    "MN", "MS", "MO", "MT", "NE", "NV", "NH", "NJ", "NM", "NY", "NC",
                    "ND", "OH", "OK", "OR", "PA", "RI", "SC", "SD", "TN", "TX", "UT",
                    "VT", "VA", "WA", "WV", "WI", "WY"],
            zip: '',
            phone: '',
            email: '',
            password: '',
            verify_password: '',
            tier_list: ['tier1', 'tier2'],
            license_enabled: true,
            style_button_dropzone: 'btn btn-secondary',
            password_style: false,
            tier_style: false,
        }
    }

    componentDidMount() {
        Api.get('/ham/my_profile')
            .then(res => {
                console.log("Dealer Profile=========>",res.data);
                this.setState({
                    entity_id: res.data.user.entity_id,
                    company_name: res.data.user.company_name,
                    tier: res.data.user.tier,
                    tier_list: [],
                    address: res.data.user.address,
                    city: res.data.user.city,
                    state: res.data.user.state,
                    zip: res.data.user.zip,
                    phone: res.data.user.phone,
                    email: res.data.user.email,
                    password: res.data.user.password,
                    verify_password: res.data.user.verify_password,
                    license_enabled: true,
                    style_button_dropzone: 'btn btn-secondary',
                });
                console.log("Company name:", res.data.user.company_name);
        });
    }

    handleChange = (e) => {
        this.setState({[e.target.name]: e.target.value})
    }

    ShowInputPassword = (e) => {
        e.preventDefault();
        console.log('True');
        this.setState({password_style: true})
    }


    ShowInputTier = (e) => {
        e.preventDefault();
        console.log('True');
        this.setState({tier_style: true})
    }

    handleStep1 = (e) => {
        e.preventDefault();
        const data  = {
            entity_id: this.state.entity_id,
            make: this.state.make,
            company_name: this.state.company_name,
            tier: this.state.tier,
            address: this.state.address,
            city: this.state.city,
            state: this.state.state,
            zip: this.state.zip,
            phone: this.state.phone,
            email: this.state.email,
            password: this.state.password,
            verify_password: this.state.verify_password
        }
        const url_params = {
            c: '4m0jsDyIk5FxeasJEixQFA==',
            t: 'JAi2p-J9h1lELVfw3HaYYL9X3rsp0xniPs2nnsSsIlMFnfHKbnx7cLHKIGUDvWGNcLVJUTasOhL27mYJP9e7kQ'
        }
        Api.post('/ham/update_dealer', {data: data}, {params: url_params})
            .then(res => {
                const entity_id = res.data.entity_id;
                const style_button_dropzone = 'dropzone_box site-button button-block';
                console.log(entity_id);
                this.setState({ entity_id, style_button_dropzone});

                if(this.state.entity_id != ''){
                    this.setState({license_enabled: false})
                }
            })
    }

    handleSubmitSub = async (event) => {
        event.preventDefault();

        const {stripe, elements} = this.props;
        console.log("Props", this.props);
        console.log(stripe);
        console.log(elements);
        if (!stripe || !elements) {
            console.log("Fail")
            // Stripe.js has not loaded yet. Make sure to disable
            // form submission until Stripe.js has loaded.
            return;
        }

        if (this.state.status !== '') {
            console.log("test1");
            const result = await stripe.confirmCardPayment(this.state.clientSecret, {
                payment_method: {
                card: elements.getElement(CardElement),
                billing_details: {
                    email: this.state.email,
                },
                },
            });
            if (result.error) {
                console.log(result.error.message);
                // Show error in payment form
            } else {
                console.log('Hell yea, you got that sub money!');
            }
        } else {
            console.log("test2");
            const result = await stripe.createPaymentMethod({
            type: 'card',
            card: elements.getElement(CardElement),
            billing_details: {
                email: this.state.email,
            },
            });

            if (result.error) {
                console.log(result.error.message);
                console.log("Test1");
                // Show error in payment form
            } else {
                console.log("Test2");
                const payload = {
                    email: this.state.email,
                    payment_method: result.paymentMethod,
                    result_from_stripe: result,
                    ups_user_id: this.state.ups_user_id,
                    //mode: 'profile'
                };
                // Otherwise send paymentMethod.id to your server
                const res = ""                
                Api.post('/ham/save_cc', {data: payload})
                    .then(res => {
                
                        // eslint-disable-next-line camelcase
                        const {client_secret, status} = res.data;

                        if (status === 'requires_action') {
                            this.state.setStatus(status);
                            this.state.setClientSecret(client_secret);
                            stripe.confirmCardPayment(client_secret).then(function(result) {
                            if (result.error) {
                                console.log("test1");
                                // Display error message in your UI.
                                // The card was declined (i.e. insufficient funds, card has expired, etc)
                                console.log(result.error.message);
                            } else {
                                console.log("test2");
                                // Show a success message to your customer
                                console.log('Hell yea, you got that sub money!');
                            }
                            });
                        } else {
                            console.log('Hell yea, you got that sub money!');
                        }
                    })

                
            }
        }   
    }


    render() {
        const {stripe} = this.props;
        return (
            <div>
                <Header originrole="SELLER"/>
                <div className="section-full content-inner-2 shop-account bg-white">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12 text-center">
                                <h3 className="font-weight-700 m-t0 m-b20">MY PROFILE</h3>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <div className="p-a30 border-1  max-w500 m-auto">
                                    <div className="tab-content">
                                        <form id="login" className="tab-pane active">
                                            <h4 className="font-weight-700">Step 1</h4>
                                            <div className="form-group">
                                                <label className="font-weight-700">Company Name *</label>
                                                <input name="company_name" id="company_name" value={this.state.company_name} required="" className="form-control" type="text" onChange={this.handleChange} />
                                            </div>
                                            <div className="form-group">
                                                <label className="font-weight-700">Address *</label>
                                                <input name="address" id="address" value={this.state.address} required="" className="form-control" placeholder="Address" type="text" onChange={this.handleChange} />
                                            </div>
                                            <div className="form-group">
                                                <label className="font-weight-700">City *</label>
                                                <input name="city" id="city" value={this.state.city} required="" className="form-control" placeholder="City" type="text" onChange={this.handleChange} />
                                            </div>
                                            <div className="form-group">
                                                <label className="font-weight-700">State *</label>
                                                <select className="form-control" id="state" value={this.state.state} name="state" className="form-control" onChange={this.handleChange}>
                                                <option disabled selected value> -- select an option -- </option>
                                                {this.state.states.map(r =><option value={r} key={r}>{r}</option>)}
                                                </select>
                                            </div>
                                        
                                            <div className="form-group">
                                                <label className="font-weight-700">Zip *</label>
                                                <InputMask mask="99999" name="zip" id="zip" required="" value={this.state.zip} className="form-control" placeholder="Zip" type="text" onChange={this.handleChange} />
                                            </div>
                                            <div className="form-group">
                                                <label className="font-weight-700">Phone *</label>
                                                <InputMask name="phone" id="phone" required="" value={this.state.phone} className="form-control" mask="(999) 999-9999" maskChar=" " placeholder="Phone" onChange={this.handleChange} />
                                            </div>
                                            <div className="form-group">
                                                <label className="font-weight-700">Email *</label>
                                                <input name="email" id="email" value={this.state.email} required="" className="form-control " placeholder="Email" type="email" onChange={this.handleChange} />
                                            </div>
                                            <div className="form-group">
                                                <Link to={'../forgotpassword'} title="Change Password" rel="bookmark" className="site-button button-lg radius-no outline outline-2">Change Password</Link>
                                            </div>
                                            {/*
                                                {this.state.password_style != true && 
                                                    <div className="form-group">
                                                        <button className="site-button button-lg radius-no outline outline-2" name="ChangePassword" onClick={this.ShowInputPassword}>Change My Password</button>
                                                    </div>
                                                }
                                                {this.state.password_style != false && 
                                                    <div>
                                                        <div className="form-group">
                                                            <label className="font-weight-700">Password *</label>
                                                            <input name="password" id="password" required="" className="form-control " placeholder="Password" type="password" onChange={this.handleChange} />
                                                        </div>
                                                        <div className="form-group">
                                                            <label className="font-weight-700">Verify Password *</label>
                                                            <input name="verify_password" id="verify_password" required="" className="form-control " placeholder="Verify Password" type="password" onChange={this.handleChange} />
                                                        </div>
                                                    </div>
                                                }
                                            */}
                                            <div className="form-group">
                                                <Link to={'../dealer/changetier'} title="Change Tier" rel="bookmark" className="site-button button-lg radius-no outline outline-2">Change Tier</Link>
                                            </div>

                                            <div className="form-group">
                                                <Link to={'../dealer/changelicense'} title="Change License" rel="license" className="site-button button-lg radius-no outline outline-2">Change License</Link>
                                            </div>

                                            <div className="form-group">
                                                <Link to={'../dealer/changecc'} title="Change Credit Card" rel="bookmark" className="site-button button-lg radius-no outline outline-2">Change Credit Card</Link>
                                            </div>
                                            <div className="text-left">
                                                <button className="site-button button-lg radius-no outline outline-2" name="SaveInfo" onClick={this.handleStep1}>Save info</button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        )
    }
}

export default DealerProfile;