import { createContext, useContext } from 'react';

export const AuthContext = createContext();

export const setTokens = (data) => {
    console.log(">> setTokens: data:", data);
    if (data !== undefined && data !== null) {
        localStorage.setItem("h76_tokens", data.access_token);
        localStorage.setItem("h76_name", data.name);
        localStorage.setItem("h76_role", data.role);
    } else {
        localStorage.removeItem("h76_tokens");
        localStorage.removeItem("h76_name");
        localStorage.removeItem("h76_role");
    }
}

export const getTokens = () => {
    var result = {
        tokens : localStorage.getItem("h76_tokens"),
        name : localStorage.getItem("h76_name"),
        role : localStorage.getItem("h76_role")
    }
    console.log(">> getTokens: result:", result);
    return result;
}

export function useAuth() {
//    console.log(AuthContext);
    return useContext(AuthContext);
}
