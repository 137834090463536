import React, { Component } from 'react';
import Header2 from './../Layout/Header2';
import LandingHeader from './../Layout/LandingHeader';

import { useAuth } from "../../context/auth";

import { Api } from "../../utils/Api";

class ResetLink extends Component {

    state = {
        email: null
    }

    submitEmail = (event) => {
        event.preventDefault();
        console.log(">> submitEmail: ", this.state.email);
        Api.post("/ham/forgotpwd",
            {   
                c: '4m0jsDyIk5FxeasJEixQFA==',
                t: 'JAi2p-J9h1lELVfw3HaYYL9X3rsp0xniPs2nnsSsIlMFnfHKbnx7cLHKIGUDvWGNcLVJUTasOhL27mYJP9e7kQ',
                e: this.state.email
            },
            {}
            ).then(result => {
                console.log(">> back from server");                
                // //var session = Cookies.get('CG_cookie2');
                console.log(">> result:", result);
                // if (result.status === 200 && result.data.res === true) {
                //     setAuthTokensOverloaded(result.data);
                //     setLoggedIn(true);
                //     if (result.data.role === 'SELLER') {
                //         history.push('/seller/dashboard');
                //     } else if (result.data.role === 'DEALER') {
                //         history.push('/dealer/dashboard');
                //     }                                        
                // } else {
                //     console.log(">> BB");
                //     //setIsError(true);
                // }

             }).catch(e => {
                // setIsError(true);
        });
    }

    render() {
        return (
            <div>
                <LandingHeader />
                <div className="section-full content-inner-2 shop-account bg-white">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12 text-center">
                                <h3 className="font-weight-700 m-t0 m-b20">Reset Password</h3>
                            </div>
                        </div>
                        <div>
                            <div className="max-w500 m-auto">
                                <div className="p-a30 border-1 seth">
                                    <div className="tab-content nav">                                             
                                            
                                            <p className="font-weight-600">Click the following link to reset your password.</p>
                                            <a href="http://yamatocg.nextcoder.com:30001/">http://yamatocg.nextcoder.com:30001/</a>
                                    
                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        )
    }
}
export default ResetLink;