import React, { Component } from 'react';
import { useAuth } from "../context/auth";
import { AuthContext } from "../context/auth";
import { Api } from "../utils/Api";
import { getTokens } from "../context/auth";

import {ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


class MessageForm extends Component {

    state = {
        car_id: '',
        message: '',
        listing_id: '',
        bid_id: '',
        message: -1,
        messages: [],
        is_answered: false,
    }

    componentWillReceiveProps(nextProps){
        console.log("---------------------------", nextProps);
        this.setState({
            car_id: nextProps.val.car_id,
            listing_id: nextProps.val.listing_id,
            bid_id: nextProps.val.bid_id,
            messages: nextProps.val.messages,
            is_answered: nextProps.val.is_answered,
            })
        console.log("Messages Load==>",nextProps.val.messages);
    }

    handleChange = (e) => {
        this.setState({[e.target.name]: e.target.value})
    }

    handleSubmit = event => {
        event.preventDefault();
        console.log(this.state)

        const headers = {
          'Access-Control-Allow-Origin': '*'
        }
        const data = {
            'car_id': this.state.car_id,
            'listing_id' : this.state.listing_id,
            'bid_id' : this.state.bid_id,
            'message' : this.state.message,
        }
        console.log("Submit Message =>>>>>>>>",data);
        Api.post('/ham/create_dealer_message', {data:data})
            .then(res => {
                console.log("status =>>>>>>", res.status);
                if (res.data.status == 'success'){
                    let message_list = res.data.message_list;
                    // this.setState({is_answered: false});
                    this.props.handler(message_list);
                    toast("Your question has been successfully submitted");
                }
            })
    }
    render() {
        return (
            <div>
                {this.state.is_answered == true &&
                    <form className="dealer-message" onSubmit={this.handleSubmit}>
                        <div className="row">
                            <div className="col-md-12 col-lg-12 col-xl-12 col-sm-12 col-12">
                                <div className="form-group">
                                    <select className="form-control" id="message" name="message" value={this.state.message} onChange={this.handleChange}>
                                        <option disabled value={-1}> -- select an option -- </option>
                                        {this.state.messages.map(r => <option value={r.id} key={r.id}>{r.name}</option> )}
                                    </select>
                                </div>
                            </div>
                            <div className="col-md-12 col-lg-12 col-xl-12 col-sm-12 col-12">
                                <button type="submit" className="site-button btn-block">Submit Question</button>
                            </div>
                        </div>
                    </form>
                }
            </div>
        );
    }
}

export default MessageForm;