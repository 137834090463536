import React, { Component } from 'react';
import Header2 from './../Layout/Header2';
import LandingHeader from './../Layout/LandingHeader';

import { useAuth } from "../../context/auth";

import { Api } from "../../utils/Api";

class ForgotPassword extends Component {

    state = {
        email: null
    }

    submitEmail = (event) => {
        event.preventDefault();
        console.log(">> submitEmail: ", this.state.email);
        Api.post("/ham/forgotpwd",
            {   
                c: '4m0jsDyIk5FxeasJEixQFA==',
                t: 'JAi2p-J9h1lELVfw3HaYYL9X3rsp0xniPs2nnsSsIlMFnfHKbnx7cLHKIGUDvWGNcLVJUTasOhL27mYJP9e7kQ',
                e: this.state.email
            },
            {}
            ).then(result => {
                console.log(">> back from server");                
                // //var session = Cookies.get('CG_cookie2');
                console.log(">> result:", result);
                if (result.status === 200) {
                    this.props.history.replace('/forgotsubmitted');
                }

             }).catch(e => {
                // setIsError(true);
        });
    }

    render() {
        return (
            <div>
                <LandingHeader />
                <div className="page-content section-full content-inner-2 shop-account bg-white">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12 text-center">
                                <h3 className="font-weight-700 m-t0 m-b20">Reset Password</h3>
                            </div>
                        </div>
                        <div>
                            <div className="max-w500 m-auto">
                                <div className="p-a30 border-1 seth">
                                    <div className="tab-content nav">
                                        <form id="login" className="tab-pane active col-12 p-a0 " onSubmit={this.submitEmail}>     
                                            <h4 className="font-weight-700">Enter Your Email Address</h4>                                       
                                            <p className="font-weight-600">If you have an account with us, please specify your email address. We'll send an email for resetting your password.</p>
                                            <div className="form-group">
                                                <label className="font-weight-700">E-MAIL *</label>
                                                <input name="dzName" required className="form-control" placeholder="Your Email Address" type="email" onChange={e => this.setState({email: e.target.value})} />
                                            </div>                                            
                                            <div className="text-left">
                                                <button className="site-button m-r5 button-lg radius-no">Submit</button>                                                
                                            </div>
                                        </form>
                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        )
    }
}
export default ForgotPassword;