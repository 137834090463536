import React, { useState } from 'react'
import { BrowserRouter, Route, Switch } from 'react-router-dom';
// import Header from './Layout/Header';
// import Footer from './Layout/Footer';
import CarSearch from './Pages/CarSearch';
import SellerDashboard from './Pages/SellerDashboard';
import SellerCarDetails from './Pages/SellerCarDetails';
import DealerDashboard from './Pages/DealerDashboard';
import DealerCarDetails from './Pages/DealerCarDetails';
import Login2 from './Pages/Login2';
import Login from './Pages/Login';
import Logout from './Pages/Logout'
import Register from './Pages/Register';
import Register2 from './Pages/Register2';
import ForgotPass from './Pages/ForgotPass';
import Contacts from './Pages/Contacts';

import DealerHomepage from './Pages/DealerHomepage';

import AuthRoute from '../context/AuthRoute';

function DealerMarkup(props) {

    return (

        <BrowserRouter basename="/dealer">
            <div className="page-wraper">
                <Switch>
                    <Route path='/' exact component={DealerHomepage} />
                    <AuthRoute path='/dashboard' exact component={DealerDashboard} />
                    <AuthRoute path='/dealercardetails' exact component={DealerCarDetails} />
                    <AuthRoute path='/carsearch' exact component={CarSearch} />
                    <Route path='/login' exact component={Login2} />
                    <Route path='/logout' exact component={Logout} />
                    <Route path='/register' exact component={Register} />
                    <Route path='/register2' exact component={Register2} />
                    <Route path='/forgot' exact component={ForgotPass} />
                    <Route path='/contact' exact component={Contacts} />
                </Switch>
            </div>
        </BrowserRouter>

    )
}

export default DealerMarkup;