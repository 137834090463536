import React, { Component } from 'react';
import axios from 'axios';
import { useAuth } from "../context/auth";
import { AuthContext } from "../context/auth";
import { Api } from "../utils/Api";
import { getTokens } from "../context/auth";
import $ from 'jquery';
import SimpleReactValidator from 'simple-react-validator';
import {ToastContainer, toast } from 'react-toastify';


class CarForm extends Component {
    constructor(props) {
        super(props);
        this.validator = new SimpleReactValidator();
        this.state = {
            entity_id: '',
            listing_status: 'DRAFT',
            year: '',
            total_bid_active: false,
            vin: '',
            odometer: '',
            lien_holder_list: [],
            lien_holder_id: -1,
            lien_holder_other_id: 5,
            lien_holder_other: '',
            make: -1,
            body_style_list: [],
            body_style: -1,
            door: '',
            drive_type: '',
            ext_color: '',   
            fuel_type: '',
            interior_color: '',
            model: '',
            transmission: '',
            trim: '',
            make_list: [],
            door_list: [],
            drive_type_list: [],
            ext_color_list: [],
            interior_color_list: [],
            model_list: [],
            transmission_list: [],
            trim_list: [],
            year_list: [],
            preferred_dealer_id: -1,
            preferred_dealer_list: [],
            entity_id: '',
            notes: '',
            other_color_id: 18,
            other_color: '',
        }

    }

    componentWillReceiveProps(nextProps){
        console.log("--------------------------- nextProps:", nextProps);
        if (this.state.year != -1 || this.state.year != null){
            this.state.year_list.push(nextProps.val.year_list);
        }

        // console.log("------------------->", this.val.make, nextProps.val.make_id, nextProps.val.make_list);

        if (this.state.make_list != null){
            this.state.make_list.push(nextProps.val.make_list);
        }
        console.log("Make ====>", nextProps.val.make_list)
        console.log("Model ====>", nextProps.val.model_list)

        if (nextProps.val.model_id != null && nextProps.val.model_list != null){
            console.log("Model ====>", nextProps.val.model_list);
            this.state.model_list.push(nextProps.val.model_list);
        }

        if (nextProps.val.trim_id != null && nextProps.val.trim_list != null){
            this.state.trim_list.push(nextProps.val.trim_list);
        }

        if(this.state.entity_id == ''){
            this.setState({
                entity_id: nextProps.val.id,
                listing_status: nextProps.val.listing_status,
                total_bid_active: nextProps.val.total_bid_active,
                vin: nextProps.val.vin,
                odometer: nextProps.val.odometer,
                lien_holder_id: nextProps.val.lien_holder_id,
                lien_holder_other_id: nextProps.val.lien_holder_other_id,
                lien_holder_other: nextProps.val.lien_holder_other,
                make: nextProps.val.make_id,
                model: nextProps.val.model_id,
                trim: nextProps.val.trim_id,
                body_style: nextProps.val.body_style_id,
                door: nextProps.val.door_id,
                drive_type: nextProps.val.drive_type_id,
                ext_color: nextProps.val.ext_color_id,
                interior_color: nextProps.val.interior_color_id,
                transmission: nextProps.val.transmission_id,
                year: nextProps.val.year_id,
                preferred_dealer_id: nextProps.val.preferred_dealer_id,
                notes: nextProps.val.notes,
                other_color: nextProps.val.other_color,
                })

                $("#dropzone :input").attr("disabled", false);
            }
            // console.log("Total bid active", this.state.total_bid_active == 0)

        console.log("Load 2");
    }

    onToggleOpen = field => (e) => {
        console.log('val=>', field);
        Api.get('/ham/get_list', {params: {'field': field, 'make_id': this.state.make, 'model_id': this.state.model}})
            .then(res => {
                this.setState(res.data.val);
                console.log(res.data.val);
            })
    }


    handleChange = (e) => {
        this.setState({[e.target.name]: e.target.value})
    }


    handleChangeMake = (e) => {
        Api.get('/ham/car_model', {params: {make: e.target.value}})
            .then(res => {
            const model_list = res.data.model;
            console.log("test---------------", res)
            this.setState({ model_list});
        })
        this.setState({[e.target.name]: e.target.value})
    }

    handleChangeTrim = (e) => {
        Api.get('/ham/car_trim', {params: {model: e.target.value}})
            .then(res => {
            const trim_list = res.data.trim_list;
            console.log("test---------------", res)
            this.setState({ trim_list });
        })
        this.setState({[e.target.name]: e.target.value})
    }

    componentDidMount() {
        Api.get('/ham/new_car')
            .then(res => {
                const make_list = res.data.make_list;
                const door_list = res.data.door;
                const drive_type_list = res.data.drive_type;
                const body_style_list = res.data.body_style_list;
                const ext_color_list = res.data.ext_color;
                const interior_color_list = res.data.interior;
                // const model_list = res.data.model;
                const transmission_list = res.data.transmission;
                // const trim_list = res.data.trim;
                // if (this.state.year_id == null)
                const year_list = res.data.year;
                
                this.setState({ 
                    make_list, door_list, drive_type_list, ext_color_list, interior_color_list, transmission_list,
                    year_list, 'preferred_dealer_list': res.data.preferred_dealer_list, 'lien_holder_list':  res.data.lien_holder_list,
                    'body_style_list': body_style_list
                 });

            })

        if(this.state.entity_id == ''){
            $("#dropzone :input").attr("disabled", true);
        }

        console.log("Load 1");

    }

    handleSaveCar = params => event => {
        console.log(">> params:", params);
        console.log(">> event:", event);
        
        event.preventDefault();
        Api.post('/ham/save_car', {data:this.state, button: params})
            .then(res => {
                console.log("================================Result+>",res.data);
                const location = "/seller/cardetails?entity_id="+res.data.car.entity_id;
                if (res.data.error == null){
                    if (this.state.entity_id == null || this.state.entity_id == ''){
                        window.location.assign(location);
                    }
                    var success_msg = 'Saved as draft.'
                    if (params === 'list_now') {
                        success_msg = "Your car is now listed."
                        this.setState({
                            'listing_status': res.data.car.listing_status
                        })
                    }

                    toast(success_msg, {autoClose: 3000});
                }else{
                    toast(res.data.error, {autoClose: 3000});
                }
                
            })
    }
 render() {
     
  return (
   <div>
	<form className="hotel-booking">
    <div className="row">
        <ToastContainer position="top-center" autoClose={2000}/>
        <div className="col-md-6 col-lg-6 col-xl-12 col-sm-6 col-6">
            <div className="form-group">
                <div className="input-group">
                    <select className="form-control" value={this.state.preferred_dealer_id} id="preferred_dealer_id" name="preferred_dealer_id" onChange={this.handleChange}>
                      <option disabled selected value={-1}> -- select an option -- </option>
                      {this.state.preferred_dealer_list.map(r =>
                        <option selected value={r.id} key={r.id}>{r.name}</option>
                      )}
                    </select>
                </div>
                <span className="font-12">Preferred Dealer</span>
            </div>
        </div>
        <div className="col-md-6 col-lg-6 col-xl-12 col-sm-6 col-6">
            <div className="form-group">
                <div className="input-group">
                    <input value={this.state.entity_id} id="entity_id" name="entity_id" type="hidden"/>
                    <select className="form-control" id="year" name="year" onMouseOver={this.onToggleOpen('year_list')} onChange={this.handleChange}>
                      <option disabled selected value> -- select an option -- </option>
                      {this.state.year_list.map(r =>
                        this.props.val.year_id == r.id
                            ? <option selected value={r.id} key={r.id}>{r.name}</option>
                            : <option value={r.id} key={r.id}>{r.name}</option>
                      )}
                    </select>
                </div>
                <span className="font-12">Year</span>
            </div>
        </div>
        <div className="col-md-6 col-lg-6 col-xl-12 col-sm-6 col-6">
            <div className="form-group">
                <div className="input-group">
                    <select className="form-control" id="make" value={this.state.make} name="make" onMouseOver={this.onToggleOpen('preferred_dealer_list')} onChange={this.handleChangeMake}>
                        <option disabled selected value={-1}> -- select an option -- </option>
                        {this.state.make_list.map(r =>
                            <option value={r.id} key={r.id}>{r.name}</option>
                        )}
                    </select>
                </div>
                <span className="font-12">Make</span>
            </div>
        </div>

        {this.state.make != -1 &&
            <div className="col-md-6 col-lg-6 col-xl-12 col-sm-6 col-6">
                <div className="form-group">
                    <div className="input-group">
                        <select className="form-control" id="model" name="model" onMouseOver={this.onToggleOpen('model')} onChange={this.handleChangeTrim}>
                        <option disabled selected value> -- select an option -- </option>
                        {this.state.model_list.map(r =>
                            this.state.model == r.id
                            ? <option selected value={r.id} key={r.id}>{r.name}</option>
                            : <option value={r.id} key={r.id}>{r.name}</option>
                            )}
                        </select>
                    </div>
                    <span className="font-12">Model</span>
                </div>
            </div>
        }

        {this.state.model != -1 &&
            <div className="col-md-6 col-lg-6 col-xl-12 col-sm-6 col-6">
                <div className="form-group">
                    <div className="input-group">
                        <select className="form-control" id="trim" name="trim" onMouseOver={this.onToggleOpen('trim')} onChange={this.handleChange}>
                        <option disabled selected value> -- select an option -- </option>
                        {this.state.trim_list.map(r =>
                        this.props.val.trim_id == r.id
                            ? <option selected value={r.id} key={r.id}>{r.name}</option>
                            : <option value={r.id} key={r.id}>{r.name}</option>
                        )}
                        </select>
                    </div>
                    <span className="font-12">Trim</span>
                </div>
            </div>
        }

        <div className="col-md-6 col-lg-6 col-xl-12 col-sm-6 col-6">
            <div className="form-group">
                <div className="quantity btn-quantity">
                    <input className="form-control" id="vin" type="text"  name="vin" value={this.state.vin} onChange={this.handleChange}/>
                </div>
                <span className="font-12">Vin</span>
                {this.validator.message('title', this.state.vin, 'required|alpha')}
            </div>
        </div>
        <div className="col-md-6 col-lg-6 col-xl-12 col-sm-6 col-6">
            <div className="form-group">
                <div className="quantity btn-quantity">
                    <input className="form-control" id="odometer" type="text"  name="odometer" value={this.state.odometer}  onChange={this.handleChange}/>
                </div>
                <span className="font-12">Odometer</span>
            </div>
        </div>
        <div className="col-md-6 col-lg-6 col-xl-12 col-sm-6 col-6">
            <div className="form-group">
                <div className="input-group">
                    <select className="form-control" id="lien_holder_id" value={this.state.lien_holder_id} name="lien_holder_id" className="form-control" onChange={this.handleChange}>
                        <option disabled selected value> -- select an option -- </option>
                        {this.state.lien_holder_list.map(r =><option value={r.id} key={r.id}>{r.name}</option>)}
                    </select>
                </div>
                <span className="font-12">Lien Holder</span>
            </div>
        </div>
        {this.state.lien_holder_id == this.state.lien_holder_other_id &&
            <div className="col-md-12 col-lg-12 col-xl-12 col-sm-12 col-12">
                <div className="form-group">
                    <div className="quantity btn-quantity">
                        <input className="form-control" id="lien_holder_other" type="text"  name="lien_holder_other" value={this.state.lien_holder_other}  onChange={this.handleChange}/>
                    </div>
                    <span className="font-12">Lien holder info</span>
                </div>
            </div>  
        }
        
        <div className="col-md-6 col-lg-6 col-xl-12 col-sm-6 col-6">
            <div className="form-group">
                <div className="input-group">
                    <select className="form-control" id="drive_type" name="drive_type" onChange={this.handleChange}>
                      <option disabled selected value> -- select an option -- </option>
                      {this.state.drive_type_list.map(r =>
                      this.props.val.drive_type_id == r.id
                           ? <option selected value={r.id} key={r.id}>{r.name}</option>
                           : <option value={r.id} key={r.id}>{r.name}</option>
                      )}
                    </select>
                </div>
                <span className="font-12">Drive Type</span>
            </div>
        </div>
        
        
        <div className="col-md-6 col-lg-6 col-xl-12 col-sm-6 col-6">
            <div className="form-group">
                <div className="input-group">
                    <select value={this.props.val.transmission_id} className="form-control" id="transmission" name="transmission" onChange={this.handleChange}>
                      <option disabled selected value> -- select an option -- </option>
                      {this.state.transmission_list.map(r =>
                      this.props.val.transmission_id == r.id
                           ? <option selected value={r.id} key={r.id}>{r.name}</option>
                           : <option value={r.id} key={r.id}>{r.name}</option>
                      )}
                    </select>
                </div>
                <span className="font-12">Transmission</span>
            </div>
        </div>

        <div className="col-md-6 col-lg-6 col-xl-12 col-sm-6 col-6">
            <div className="form-group">
                <div className="input-group">
                    <select className="form-control" id="body_style" name="body_style" onChange={this.handleChange}>
                      <option disabled selected value={-1}> -- select an option -- </option>
                      {this.state.body_style_list.map(r =>
                        <option value={r.id} key={r.id}>{r.name}</option>
                      )}
                    </select>
                </div>
                <span className="font-12">Body Style</span>
            </div>
        </div>
        
        <div className="col-md-6 col-lg-6 col-xl-12 col-sm-6 col-6">
            <div className="form-group">
                <div className="input-group">
                    <select className="form-control" id="ext_color" name="ext_color" onChange={this.handleChange}>
                      <option disabled selected value> -- select an option -- </option>
                      {this.state.ext_color_list.map(r =>
                      this.props.val.ext_color_id == r.id
                           ? <option selected value={r.id} key={r.id}>{r.name}</option>
                           : <option value={r.id} key={r.id}>{r.name}</option>
                      )}
                    </select>
                </div>
                <span className="font-12">Exterior Color</span>
            </div>
        </div>

        {this.state.ext_color == this.state.other_color_id &&
            <div className="col-md-12 col-lg-12 col-xl-12 col-sm-12 col-12">
                <div className="form-group">
                    <div className="quantity btn-quantity">
                        <input className="form-control" id="other_color" type="text"  name="other_color" value={this.state.other_color}  onChange={this.handleChange}/>
                    </div>
                    <span className="font-12">Other Color</span>
                </div>
            </div>
        }

        <div className="col-md-6 col-lg-6 col-xl-12 col-sm-6 col-6">
            <div className="form-group">
                <div className="input-group">
                    <select className="form-control" id="interior_color" name="interior_color" onChange={this.handleChange}>
                      <option disabled selected value> -- select an option -- </option>
                      {this.state.interior_color_list.map(r =>
                      this.props.val.interior_color_id == r.id
                           ? <option selected value={r.id} key={r.id}>{r.name}</option>
                           : <option value={r.id} key={r.id}>{r.name}</option>
                      )}
                    </select>
                </div>
                <span className="font-12">Interior Color</span>
            </div>
        </div>        
        <div className="col-md-6 col-lg-6 col-xl-12 col-sm-6 col-6">
            <div className="form-group">
                <div className="input-group">
                    <select className="form-control" id="door" name="door" onChange={this.handleChange}>
                      <option disabled selected value> -- select an option -- </option>
                      {this.state.door_list.map(r =>
                        this.props.val.door_id == r.id
                           ? <option selected value={r.id} key={r.id}>{r.name}</option>
                           : <option value={r.id} key={r.id}>{r.name}</option>
                      )}
                    </select>
                </div>
                <span className="font-12">Door</span>
            </div>
        </div>
        <div className="col-md-6 col-lg-6 col-xl-12 col-sm-6 col-6">
            <div className="form-group">
                <div className="quantity btn-quantity">
                    <textarea name="dzMessage" id="notes" name="notes" rows="8" className="form-control" value={this.state.notes} onChange={this.handleChange}></textarea>
                </div>
                <span className="font-12">Notes</span>
            </div>
        </div>

        <div className="col-md-12 col-lg-12 col-xl-12 col-sm-12 col-12">
            { this.state.listing_status === '' || this.state.listing_status === 'DRAFT'
               ? <div><button type="submit" className="site-button btn-block" onClick={this.handleSaveCar('draft')}>Save as Draft</button>
               <button type="submit" className="site-button btn-block" onClick={this.handleSaveCar('list_now')}>Save and List Now</button></div>
               : ''
            }            
            <br/>
            {this.state.listing_status == 'STARTED' && this.state.total_bid_active == 0 && this.state.entity_id != '' ?
                <button type="submit" className="site-button btn-block" onClick={this.handleSaveCar('revise')}>Revise</button>
                : ''
            }
        </div>
    </div>
    </form>
   </div>
  );
 }
}

export default CarForm;