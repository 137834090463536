import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import Header from './../Layout/Header';
import Footer from './../Layout/Footer';
import axios from 'axios';
import { useAuth } from "../../context/auth";
import { AuthContext } from "../../context/auth";
import { Api } from "../../utils/Api";
import { getTokens } from "../../context/auth";
import CurrencyFormat from 'react-currency-format';



var bg3 = require('./../../images/main-slider/slide2_car.jpg');
//var bg3 = require('');
class SellerDashboard extends Component {

    state = {
        cars: []
    }


    componentDidMount() {
     Api.get('/ham/seller_car_main_list')
        .then(res => {
        const cars = res.data.cars;
            console.log(cars);
            this.setState({ cars });
        })
    }

    render() {

        return (
            <div>
                <Header originrole="SELLER"/>
                <div className="dlab-bnr-inr overlay-black-middle" style={{ backgroundImage: "url(" + bg3 + ")", backgroundSize: 'cover' }}>
                    <div className="container">
                        <div className="dlab-bnr-inr-entry">
                            <h1 className="text-white">My Cars Dashboard</h1>
                            <div className="breadcrumb-row">
                                {/* <ul className="list-inline">
                                    <li><Link>Home</Link></li>
                                    <li>Place</li>
                                </ul> */}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="section-full bg-white content-inner dlab-about-1">
                    <div className="container">
                        <div className="row">

                            <div className="col-lg-8 col-md-7 col-sm-7">

                                <div className="row">
                                    <div className="col-md-12 col-sm-12 m-b15">
                                        <div className="dlab-separator">
                                            <Link className="input-group-btn" to={'/seller/cardetails'}>
                                                 <button type="button">
                                                      Add Car
                                                 </button>
                                            </Link>
                                            <br />
                                        </div>
                                        <h3 className="m-b5">My Cars:</h3>
                                    </div>
                                    {this.state.cars.length > 0 ?
                                        this.state.cars.map(car => (
                                        <div className="col-md-12 col-lg-12 col-sm-12 m-b30" key={car.id}>
                                                <div className="dlab-box packages-bx">
                                                    <div className="dlab-media">
                                                        <Link to={'/seller/cardetails?entity_id='+car.id}><img src={car.img_path} alt="" /></Link>
                                                        {/*<span className="tag yellow">Best Seller</span>*/}
                                                    </div>
                                                    <div className="dlab-info">
                                                        <div className="head-title">
                                                            <h4 className="dlab-title"><Link to={'/seller/cardetails?entity_id='+car.id}>{car.year} {car.make} {car.model} {car.trim}</Link></h4>

                                        <span className="location">{car.odometer} Miles {car.exterior_color} {car.transmission} {car.cylinder}</span>

                                                        </div>
                                                        <div className="package-content">
                                                            <div className="review-bx">
                                                            </div>
                                                            <div className="price-bx">
                                                                {/* <CurrencyFormat value={car.price} displayType={'text'}
                                                                    thousandSeparator={true} prefix={'$'}
                                                                    renderText={value => <span className="price-main">{value}</span>} /> */}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        ))

                                        : <p>You don't have any cars yet.</p>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        )
    }
}
export default SellerDashboard;