import React, { Component } from 'react'
import styled from 'styled-components';
// import Button from '../shared/Button';

import { Link } from 'react-router-dom';
import Header from './../Layout/Header';
import Footer from './../Layout/Footer';
import axios from 'axios';
import { useAuth } from "../../context/auth";
import { AuthContext } from "../../context/auth";
import { Api } from "../../utils/Api";
import { getTokens } from "../../context/auth";
import CurrencyFormat from 'react-currency-format';


import DataTable, { createTheme } from 'react-data-table-component';

const seller_columns = [
  {
    name: 'Seller',
    selector: 'name',
    sortable: true,
  },
  {
    name: 'Join Date',
    selector: 'join_date',
    sortable: true,
  },
  {
    name: 'Email',
    selector: 'email',
    sortable: true,
  },
  {
    name: 'Phone',
    selector: 'phone',
    sortable: true,
  },
  {
    name: 'Number Of Cars',
    selector: 'number_of_cars',
    sortable: true,
  },
  {
    name: 'Number Of Listing On Transaction',
    selector: 'number_of_listing_on_transaction',
    sortable: true,
  },
  {
    name: 'Status',
    selector: 'status',
    sortable: true,
  },
];

const columns_dealer = [
    {
      name: 'Dealer',
      selector: 'name',
      sortable: true,
    },
    {
      name: 'Join Date',
      selector: 'join_date',
      sortable: true,
    },
    {
        name: 'License Uploaded Date',
        sortable: true,
        cell: row => <div><div>
            {row.license_uploaded != 'No' ?
                <a href={row.license_uploaded_link} target="_blank">{row.license_uploaded}</a>
                :row.license_uploaded
            }
            </div></div>,
      },
    {
      name: 'Credit Card Submitted',
      selector: 'credit_card_submitted',
      sortable: true,
    },
    {
      name: 'Number Of Listing On Transaction',
      selector: 'number_of_purchase_car',
      sortable: true,
    },
    {
      name: 'Status',
      selector: 'status',
      sortable: true,
    },
  ];

class AdminDashboard extends Component {

    state = {
        seller_list: []
    }

    componentDidMount() {
        Api.get('/ham/admin_dashboard')
            .then(res => {
            console.log(res.data);
            const seller_list = res.data.seller_list;
            const dealer_list = res.data.dealer_list;
            this.setState({seller_list, dealer_list});
            // console.log(this.state.list_seller);
        })
    }

    render() {
        return (
            <div>
                <Header originrole="ADMIN"/>
                    <div className="section-full bg-white content-inner dlab-about-1">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12 col-md-12 col-sm-12">
                                    <div className="row">
                                        <DataTable
                                                title="SELLER"
                                                columns={seller_columns}
                                                data={this.state.seller_list}
                                                pagination
                                            />
                                    </div>
                                    <div className="row">
                                        <DataTable
                                                title="DEALER"
                                                columns={columns_dealer}
                                                data={this.state.dealer_list}
                                                pagination
                                            />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                <Footer />
            </div>
        )
    }
}
export default AdminDashboard;