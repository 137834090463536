import React, { Component } from 'react';
import Header2 from '../Layout/Header2';
import LandingHeader from '../Layout/LandingHeader';
import { Link, Redirect, useHistory } from 'react-router-dom';
import { useAuth } from "../../context/auth";

import { Api } from "../../utils/Api";

class LinkExpired extends Component {

    

    render() {
        return (
            <div>
                <LandingHeader />
                <div className="page-content section-full content-inner-2 shop-account bg-white">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12 text-center">
                                <h3 className="font-weight-700 m-t0 m-b20">Link Expired</h3>
                            </div>
                        </div>
                        <div>
                            <div className="max-w500 m-auto">
                                <div className="p-a30 border-1 seth">
                                    <div className="tab-content nav">
                                        <form id="login" className="tab-pane active col-12 p-a0 " onSubmit={this.submitEmail}>                                                                                       
                                            <p className="font-weight-600">Please click the button to go back to our Homepage.</p>                                            
                                            <div className="text-left">
                                                <Link to={'./'} class="site-button m-r5 button-lg radius-no">Back to Homepage</Link>
                                            </div>
                                        </form>
                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        )
    }
}
export default LinkExpired;