import React, { Component } from 'react'
import { Api } from "../../utils/Api";

import Dropzone from 'react-dropzone'
import Resizer from 'react-image-file-resizer';
import EXIF from "exif-js";

import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import {ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const baseStyle = {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '20px',
    borderWidth: 2,
    borderRadius: 2,
    borderColor: '#eeeeee',
    borderStyle: 'dashed',
    backgroundColor: '#fafafa',
    color: '#bdbdbd',
    outline: 'none',
    transition: 'border .24s ease-in-out'
};

class DealerChangeLicense extends Component {
    constructor(props) {
        super(props);
        this.state = {
            entity_id: '',
            license_enabled: true,
            style_button_dropzone: 'btn btn-secondary',
            password_style: false,
            tier_style: false,
        }
    }

    componentDidMount() {
        console.log("==========<<<<<<Didmount");
        Api.get('/ham/change_tier')
            .then(res => {
                console.log("Dealer Profile=========>",res.data.result);

                this.setState({
                    entity_id: res.data.result.entity_id,
                    license_enabled: true,
                    style_button_dropzone: 'btn btn-secondary',
                });
        });
    }

    uploadToServer = (uri, orig_file) => {
        var parentThis = this;
        const formData = new FormData();
        console.log(orig_file);
        formData.append("file", uri, orig_file.name);
        formData.append("entity_id", this.state.id);
        Api.post("/ham/update_dealer_license", formData)
             .then(
             res => {
                    parentThis.componentDidMount();
                    const location = "/dealer/profile";
                    window.location.assign(location);
                 }
             )
             .catch(err => console.warn(err));
     }

    onDrop = (file) => {
        const headers = {
          'Access-Control-Allow-Origin': '*'
        }

        var parentThis = this;

        EXIF.getData(file[0], function() {
            var orientation = 0;
            var rotate = 0;
            var exifData = EXIF.pretty(this);
            if (exifData) {
              console.log(exifData);
              orientation = EXIF.getTag(this, "Orientation");
              console.log(orientation);
              if (orientation == 6) rotate = 90;
              if (orientation == 8) rotate = 270;
              if (orientation == 3) rotate = 180;

            } else {
              console.log("No EXIF data found in image '" + file.name + "'.");
            }

            console.log(rotate);

            Resizer.imageFileResizer(
                    file[0],
                    800,
                    800,
                    'JPEG',
                    100,
                    rotate,
                    uri => {
                        console.log(uri);
                        parentThis.uploadToServer(uri, file[0]);
                    },
                    'blob'
                );
          });
    }

    render() {
        return (
            <div className="section-full content-inner-2 shop-account bg-white">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 text-center">
                            <h3 className="font-weight-700 m-t0 m-b20">Change License</h3>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="p-a30 border-1  max-w500 m-auto">
                                <div className="tab-content">
                                    <form id="login" className="tab-pane active">
                                        <div className="tour-days" id="dropzone">
                                            <Dropzone onDrop={this.onDrop}>
                                              {({getRootProps, getInputProps}) => (
                                                <div {...getRootProps({baseStyle})} className={this.state.style_button_dropzone}>
                                                  <input {...getInputProps()}/>
                                                  Upload Dealer License
                                                </div>
                                              )}
                                            </Dropzone>
                                            <ToastContainer position="top-center"/>
                                            <br />
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default DealerChangeLicense;