import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import { Api } from "../../utils/Api";

import {ToastContainer, toast } from 'react-toastify';

var bg3 = require('./../../images/background/bg6.jpg');

class Footer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            contact_name: '',
            contact_email: '',
            contact_message: '',
        }
    }

    handleChange = (e) => {
        this.setState({[e.target.name]: e.target.value})
    }

    handleSendEmail = (f) => {
        f.preventDefault();

        const data  = {
            contact_name: this.state.contact_name,
            contact_email: this.state.contact_email,
            contact_message: this.state.contact_message,
        }
        console.log("test=>", this.state)
        Api.post('/ham/send_contact_us_email', {data: data})
            .then(res => {
                this.setState({
                    contact_name: '',
                    contact_email: '',
                    contact_message: '',
                });

                toast(res.message, {autoClose: 3000});
            })
    }

    render() {
        return (
            <footer className="site-footer style1">
                <div className="footer-top" style={{ backgroundImage: "url(" + bg3 + ")", backgroundSize: 'cover' }}>
                    <div className="container">
                        <div className="row">
                            <ToastContainer position="top-center" autoClose={2000}/>
                            <div className="col-lg-3 col-md-6 col-sm-5 footer-col-4">
                                <div className="widget widget_getintuch">
                                    <h6 className="m-b15 h6 text-uppercase">INFO</h6>
                                    <div className="dlab-separator bg-white"></div>
                                    <ul className="info-contact">
                                        <li>
                                            <span>
                                                <i className="fa fa-map-marker	"></i> Dallas, TX
									</span>
                                        </li>

                                        <li>
                                            <span>
                                                <i className="fa fa-phone"></i> Mobile: 214-914-7248
									</span>
                                        </li>

                                        <li>
                                            <span>
                                                <i className="fa fa-envelope-o"></i> Mail: support@upshiftauto.net
									</span>
                                        </li>
                                        {/* <li>
                                            <span>
                                                <i className="fa fa-fax"></i> Fax: 000 123 2294 089
                                            </span>
                                        </li> */}
                                    </ul>
                                </div>
                                {/* <ul className="list-inline">
                                    <li><Link to={''} className="site-button facebook sharp"><i className="fa fa-facebook"></i></Link></li>
                                    <li><Link to={''} className="site-button google-plus sharp"><i className="fa fa-google-plus"></i></Link></li>
                                    <li><Link to={''} className="site-button linkedin sharp"><i className="fa fa-linkedin"></i></Link></li>
                                    <li><Link to={''} className="site-button twitter sharp"><i className="fa fa-twitter"></i></Link></li>
                                </ul> */}
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-7 footer-col-4">
                                <div className="widget  widget_tag_cloud">
                                    <h6 className="m-b15 h6 text-uppercase">ABOUT</h6>
                                    <div className="dlab-separator bg-white"></div>
                                    <div><p>Welcome! <br></br>UpShift is the only customer to dealer platform that helps alleviate the need to drive from dealer to dealer for the best trade number.  
                                        UpShifts’ national database of dealers will pay top dollar for cars they know will sell in their market.</p></div>
                                    {/* <div className="tagcloud">
                                        <Link to={''}>Design</Link>
                                        <Link to={''}>User interface</Link>
                                        <Link to={''}>SEO</Link>
                                        <Link to={''}>WordPress</Link>
                                        <Link to={''}>Development</Link>
                                        <Link to={''}>Joomla</Link>
                                        <Link to={''}>Design</Link>
                                        <Link to={''}>User interface</Link>
                                        <Link to={''}>SEO</Link>
                                        <Link to={''}>WordPress</Link>
                                        <Link to={''}>Development</Link>
                                        <Link to={''}>Joomla</Link>
                                        <Link to={''}>Design</Link>
                                        <Link to={''}>User interface</Link>
                                        <Link to={''}>SEO</Link>
                                    </div> */}
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-6 footer-col-4">
                                <div className="widget widget_getintuch">
                                    <h6 className="m-b15 h6 text-uppercase">Contact us</h6>
                                    <div className="dlab-separator bg-white"></div>
                                    <div className="search-bx">
                                        <div className="dzFormMsg"></div>
                                        <form method="post" className="dzForm" action="script/contact.php">
                                            <input type="hidden" value="Contact" name="dzToDo" />
                                            <div className="input-group">
                                                <input name="contact_name" id="contact_name" value={this.state.contact_name} type="text" onChange={this.handleChange} required className="form-control" placeholder="Your Name" />
                                            </div>
                                            <div className="input-group">
                                                <input name="contact_email" id="contact_email" value={this.state.contact_email} type="email" onChange={this.handleChange} className="form-control" required placeholder="Your Email Address" />
                                            </div>
                                            <div className="input-group">
                                                <textarea name="contact_message" id="contact_message" value={this.state.contact_message} rows="4" onChange={this.handleChange} className="form-control" required placeholder="Your Message..."></textarea>
                                            </div>
                                            <div className="input-group">
                                                <button name="submit" type="submit" value="Submit" className="site-button " onClick={this.handleSendEmail}> <span>Submit</span> </button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="footer-bottom">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6 col-md-6 text-left"> <span>Copyright © 2020 UpshiftAuto</span> </div>                            
                        </div>
                    </div>
                </div>
            </footer>
        )
    }
}
export default Footer;