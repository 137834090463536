import React, { Component } from 'react'
import Slider from 'react-animated-slider';
import 'react-animated-slider/build/horizontal.css';
import { Link } from 'react-router-dom';
import Slick from './Slick';
import TopPlaces from './../Element/TopPlaces';
import Header from './../Layout/Header';
import Footer from './../Layout/Footer';
import DealerHowTo from './../../components/DealerHowTo';




const content = [
    // {
    //     title: 'Vulputate Mollis Ultricies Fermentum Parturient',
    //     button: 'Read More',
    //     image: require('./../../images/main-slider/slide1.jpg'),
    // },
    {
        title: 'Build your Inventory',
        button: 'Discover',
        image: require('./../../images/main-slider/slide2_car.jpg'),
    },
    // {
    //     title: 'Tortor Dapibus Commodo Aenean Quam',
    //     button: 'Discover',
    //     image: require('./../../images/main-slider/slide3.jpg'),
    // }
];

var bg1 = require('./../../images/background/bg1.jpg');
var bg3 = require('./../../images/background/bg3.jpg');

class DealerHomepage extends Component {
    render() {

        return (
            <div>
                <Header originrole="DEALER"/>
                <div className="page-content">

                    <Slider className="slider-wrapper" disabled={true}>
                        {content.map((item, index) => (
                            <div
                                key={index}
                                className="slider-content"
                                style={{ background: `url('${item.image}') no-repeat center center` }}
                            >
                                <div className="inner">
                                    <h1>{item.title}</h1>
                                    <p>{item.description}</p>
                                    {/* <button className="site-button">{item.button}</button> */}
                                </div>
                            </div>
                        ))}
                    </Slider>

                    <DealerHowTo />

                    {/* WG: Enable this beyond the Jump Start Phase when the system has 200 or so active listings
                    <div className="section-full book-form overlay-black-dark bg-img-fix p-t30 p-b10 mid" style={{ backgroundImage: "url(" + bg1 + ")" }}>
                        <div className="container">
                            <form className="row">
                                <div className="col-md-4 col-sm-6 col-6 col-lg-2 form-group">
                                    <label>Zip Code</label>
                                    <input className="form-control" placeholder="Enter Zip Code" type="text" />
                                </div>
                                <div className="col-md-4 col-sm-6 col-6 col-lg-2 form-group">
                                    <label>Make</label>
                                    <select className="form-control">
                                        <option>Any</option>
                                        <option>Honda</option>
                                        <option>Audi</option>
                                        <option>Ford</option>
                                        <option>Mercedes</option>
                                        <option>Volvo</option>
                                        <option>Toyota</option>
                                        <option>Subaru</option>
                                        <option>Holden</option>
                                    </select>
                                </div>
                                <div className="col-md-4 col-sm-6 col-6 col-lg-2 form-group">
                                    <label>Model</label>
                                    <select className="form-control">
                                        <option>Any</option>
                                        <option>Accord</option>
                                        <option>Civic</option>
                                        <option>Pilot</option>
                                        <option>Odyssey</option>
                                    </select>
                                </div>
                                <div className="col-md-4 col-sm-6 col-6 col-lg-2 form-group">
                                    <label>Year</label>
                                    <select className="form-control">
                                        <option>Any</option>
                                    </select>
                                </div>
                                <div className="col-md-4 col-sm-6 col-6 col-lg-2 form-group">
                                    <label>Find</label>
                                    <Link to={'/place'} className="site-button btn-block">SEARCH</Link>
                                </div>
                            </form>
                        </div>
                    </div> */}

                    {/* <div className="section-full bg-white content-inner-2 bgeffect overlay-black-middle" style={{ backgroundImage: "url(" + bg1 + ")", backgroundSize: 'cover' }}>
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-6 col-md-12">
                                    <div className="add-area">
                                        <h3>Yearly Subscriptions on Sale</h3>
                                        <h2>Discount <span className="text-primary">10-30%</span> Off</h2>
                                        <p>If your dealership is looking for additional source of vast and reliable inventory of used cars.</p>
                                        <Link to='' className="site-button white">See Promotion</Link>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6"></div>
                            </div>
                        </div>
                    </div> */}
                </div>
                <Footer />
            </div>
        )
    }
}
export default DealerHomepage;