import React, { useState, useEffect, Component } from 'react';

import { Link, Redirect, useHistory } from 'react-router-dom';
import Slick3 from './Slick3';

import { useAuth } from "../../context/auth";
import axios from 'axios';

import { Api } from "../../utils/Api";


function MainLogin(props) {
    const [isLoggedIn, setLoggedIn] = useState(false);
    const [isError, setIsError] = useState(false);
    const [errorMsg, setErrorMsg] = useState("");
    const [userName, setUserName] = useState("");
    const [password, setPassword] = useState("");
    const {getAuthTokensOverloaded, setAuthTokensOverloaded} = useAuth();
    const authContext = useAuth();

    let history = useHistory();

    var referer = null;
    if (props.hasOwnProperty('location') && props.location.state != undefined) {
        referer = props.location.state.referer;
    }
    console.log(props)
    console.log(">> referer:", referer);


    useEffect(() => {
        //console.log("<< login page, checking authtokens:", getAuthTokensOverloaded());
        var token = getAuthTokensOverloaded().tokens;
        if (token != null && token != '') {
            setLoggedIn(true);
        }
    }, []);

    function postLogin(event) {
        event.preventDefault();
        Api.post("/authj/auth",
            {
                username: userName,
                password: password
            },
            {}
            ).then(result => {
                console.log(">> back from server");
                //var session = Cookies.get('CG_cookie2');
                console.log(">> result:", result);
                if (result.status === 200 && result.data.res === true) {
                    console.log(">> AA");
                    setAuthTokensOverloaded(result.data);
                    setLoggedIn(true);
                    if (referer != null){
                        history.replace(referer);
                    } else if (result.data.role === 'SELLER') {
                        history.push('/seller/dashboard');
                    } else if (result.data.role === 'DEALER') {
                        history.push('/dealer/dashboard');
                    }                          
                    else if (result.data.role === 'ADMIN') {
                        history.push('/admin/dashboard');
                    }                                        
                } else {
                    console.log(result);
                    console.log(">> BB");
                    setErrorMsg(result.data.error);
                }

             }).catch(e => {
                 setIsError(true);
        });
    }
    
    if (isLoggedIn) {
        return <Redirect to={referer} />;
    }

    return (
        <div>
            <div class="page-content dlab-login font-roboto">
                <div class="container-fluid p-lr0">
                    <div class="row m-lr0 login-form-box">
                        <div class="col-lg-4 p-lr0">
                            <div class="login-form">
                                <div class="logo logo-header">
                                <Link to={'/'}><img src={require('./../../images/logo.png')} alt="" /></Link>
                                </div>
                                <div id="login" class="tab-pane">
                                    <form class="dlab-form" onSubmit={postLogin}>
                                        <div class="form-head">                                            
                                            <p class="title-text">Please login<br /> to your account</p>
                                        </div>
                                        <div>{errorMsg != "" ? errorMsg : ""}</div>
                                        <div class="form-group-bx">
                                            <div class="form-group input-form">
                                                <label>Email Address</label>
                                                <input name="userName" required="" class="form-control" placeholder="info123@example.com" type="text" onChange={e => setUserName(e.target.value)}/>
                                            </div>
                                            <div class="form-group input-form">
                                                <label>Password</label>
                                                <input name="password" required="" class="form-control " placeholder="Type Password" type="password" onChange={e => setPassword(e.target.value)}/>
                                            </div>
                                        </div>
                                        <div class="form-group field-btn text-left">
                                            {/* <div class="input-block">
                                                <input id="check1" type="checkbox" />
                                                <label for="check1">Remember me</label>
                                            </div> */}
                                            <Link to={'/forgotpassword'} class=""> Forgot Password</Link>
                                        </div>
                                        <div class="form-group">
                                            <button type="button" class="site-button black m-r10" type="submit">Login</button>
                                            {/* <Link to={'./register'} class="site-button outline">Sign Up</Link> */}
                                        </div>
                                    </form>
                                </div>


                            </div>
                        </div>
                        <div class="col-lg-8 p-lr0">
                            <Slick3 />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default MainLogin;