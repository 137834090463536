import React, { Component } from 'react'
import Header from './../Layout/Header';
import Footer from './../Layout/Footer';
import { Link } from 'react-router-dom';
import { Button, Form, FormGroup, Label, Input, FormText } from 'reactstrap';
import { Api } from "../../utils/Api";
import $ from 'jquery';
import ReactSlider from 'react-slider'
import '../../css/slider.css';
import InputMask from 'react-input-mask';

const baseStyle = {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '20px',
    borderWidth: 2,
    borderRadius: 2,
    borderColor: '#eeeeee',
    borderStyle: 'dashed',
    backgroundColor: '#fafafa',
    color: '#bdbdbd',
    outline: 'none',
    transition: 'border .24s ease-in-out'
};

class SellerRegister extends Component {
    constructor(props) {
        super(props);
        this.state = {
            entity_id: '',
            company_name: '',
            address: '',
            city: '',
            state: '',
            state_list: ["AL", "AK", "AZ", "AR", "CA", "CO", "CT", "DE", "FL", "GA", "HI",
                        "ID", "IL", "IN", "IA", "KS", "KY", "LA", "ME", "MD", "MA", "MI",
                        "MN", "MS", "MO", "MT", "NE", "NV", "NH", "NJ", "NM", "NY", "NC",
                        "ND", "OH", "OK", "OR", "PA", "RI", "SC", "SD", "TN", "TX", "UT",
                        "VT", "VA", "WA", "WV", "WI", "WY"],
            zip: '',
            phone: '',
            email: '',
            password: '',
            verify_password: '',
            best_time_to_contact: '',
            password_style: false,
        }
    }

    componentDidMount() {
        Api.get('/ham/my_profile')
            .then(res => {
                console.log("Seller Profile=========>",res.data);
                this.setState({
                    entity_id: res.data.user.entity_id,
                    company_name: res.data.user.company_name,
                    address: res.data.user.address,
                    city: res.data.user.city,
                    state: res.data.user.state,
                    zip: res.data.user.zip,
                    phone: res.data.user.phone,
                    email: res.data.user.email,
                    password: res.data.user.password,
                    verify_password: res.data.user.verify_password,
                    best_time_to_contact: res.data.user.best_time_to_contact,
                });
                console.log("Company name:", res.data.user.company_name);
                console.log("Entity name:", res.data.user.entity_id);
        });
    }

    handleChange = (e) => {
        this.setState({[e.target.name]: e.target.value})
    }

    handleStep1 = (e) => {
        e.preventDefault();
        const data  = {
            entity_id: this.state.entity_id,
            make: this.state.make,
            company_name: this.state.company_name,
            address: this.state.address,
            city: this.state.city,
            state: this.state.state,
            zip: this.state.zip,
            phone: this.state.phone,
            email: this.state.email,
            password: this.state.password,
            verify_password: this.state.verify_password,
            best_time_to_contact: this.state.best_time_to_contact
        }
        const url_params = {
            c: '4m0jsDyIk5FxeasJEixQFA==',
            t: 'JAi2p-J9h1lELVfw3HaYYL9X3rsp0xniPs2nnsSsIlMFnfHKbnx7cLHKIGUDvWGNcLVJUTasOhL27mYJP9e7kQ'
        }
        console.log("test=>", this.state)
        Api.post('/ham/update_seller', {data: data}, {params: url_params})
            .then(res => {
            const user = res.data.user;
                console.log(user);
                this.setState({ user });

            })
    }

    ShowInputPassword = (e) => {
        e.preventDefault();
        console.log('True');
        this.setState({password_style: true})
    }

    render() {
        return (
            <div>
                <Header originrole="SELLER"/>
                <div className="section-full content-inner-2 shop-account bg-white">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12 text-center">
                                <h3 className="font-weight-700 m-t0 m-b20">MY PROFILE</h3>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <div className="p-a30 border-1  max-w500 m-auto">
                                    <div className="tab-content">
                                        <form id="login" className="tab-pane active">
                                            <div className="form-group">
                                                <label className="font-weight-700">Name *</label>
                                                <input name="company_name" id="company_name" value={this.state.company_name} required="" className="form-control" placeholder="Name" type="text" onChange={this.handleChange} />
                                            </div>
                                            <div className="form-group">
                                                <label className="font-weight-700">Address *</label>
                                                <input name="address" id="address" value={this.state.address} required="" className="form-control" placeholder="Address" type="text" onChange={this.handleChange} />
                                            </div>
                                            <div className="form-group">
                                                <label className="font-weight-700">City *</label>
                                                <input name="city" id="city" value={this.state.city} required="" className="form-control" placeholder="City" type="text" onChange={this.handleChange} />
                                            </div>
                                            <div className="form-group">
                                                <label className="font-weight-700">State *</label>
                                                {/* <input name="state" id="state" value={this.state.state} required="" className="form-control" placeholder="State" type="text" onChange={this.handleChange} /> */}
                                                <select className="form-control" value={this.state.state} id="state" required name="state" className="form-control" onChange={this.handleChange}>
                                                    <option selected value={-1}> -- select an option -- </option>
                                                    {this.state.state_list.map(r =><option value={r} key={r}>{r}</option>)}
                                                </select>
                                            </div>

                                            
                                            <div className="form-group">
                                                <label className="font-weight-700">Zip *</label>
                                                <InputMask mask="99999" name="zip" id="zip" value={this.state.zip} required="" className="form-control" placeholder="Zip" type="text" onChange={this.handleChange} />
                                            </div>
                                            <div className="form-group">
                                                <label className="font-weight-700">Phone *</label>
                                                <InputMask mask="(999) 999-9999" name="phone" id="phone" value={this.state.phone} required="" className="form-control" placeholder="Phone" type="text" onChange={this.handleChange} />
                                            </div>
                                            <div className="form-group">
                                                <label className="font-weight-700">Email *</label>
                                                <input name="email" id="email" value={this.state.email} required="" className="form-control " placeholder="Email" type="email" onChange={this.handleChange} />
                                            </div>
                                            <div className="form-group">
                                                <Link to={'../forgotpassword'} title="Change Password" rel="bookmark" className="site-button button-lg radius-no outline outline-2">Change Password</Link>
                                            </div>
                                            <div className="form-group">
                                                <label className="font-weight-700">Best time to contact *</label>
                                                <input name="best_time_to_contact" id="" value={this.state.best_time_to_contact} required="best_time_to_contact" className="form-control " placeholder="Best time to contact" type="text" onChange={this.handleChange} />
                                            </div>
                                            <div className="text-left">
                                                <button className="site-button button-lg radius-no outline outline-2" name="SaveInfo" onClick={this.handleStep1}>Save info</button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        )
    }
}
export default SellerRegister;