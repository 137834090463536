import React, { Component } from 'react';
// stripe
import {CardElement} from '@stripe/react-stripe-js';


const CARD_ELEMENT_OPTIONS = {
    style: {
        base: {
            'color': '#32325d',
            'fontFamily': '"Helvetica Neue", Helvetica, sans-serif',
            'fontSmoothing': 'antialiased',
            'fontSize': '16px',
            '::placeholder': {color: '#aab7c4',
        },
        },
        invalid: {
            color: '#fa755a',
            iconColor: '#fa755a',
        },
    },
};



class CardInput extends Component {
    render() {
        return (
            <CardElement options={CARD_ELEMENT_OPTIONS} />
        );
    }

}

export default CardInput;
