import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import './css/plugins.css';
import './css/style.css';
import './css/templete.css';
import './css/skin/skin-1.css';
import './plugins/slick/slick.min.css';
import './plugins/slick/slick-theme.min.css';
import './css/hamilton.css';



// WG import Markup from './markup/Markup';
import Markup2 from './markup/Markup2';
import MainMarkup from './markup/MainMarkup';
import LandingMarkup from './markup/LandingMarkup';
import SellerMarkup from './markup/SellerMarkup';
import DealerMarkup from './markup/DealerMarkup';

import {AuthContext, getTokens, setTokens} from './context/auth';

import {useStripe, CardElement, ElementsConsumer, useElements, Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js/pure';


import { CloudinaryContext } from "cloudinary-react";

loadStripe.setLoadParameters({advancedFraudSignals: false})
export const stripePromise = loadStripe('pk_test_51H7YdcCyoD85g3Lthhabm85GXSr6KA8PVtBFbcG6HBV0wWJu4zXgaWZly78u1UejJKPdqook0pfYa5wkUOE6QzRv00Wc8aptqG');




function App() {

    return (
        <div className="App">
            
            <AuthContext.Provider value={{ getAuthTokensOverloaded: getTokens,
                                            setAuthTokensOverloaded: setTokens }}>
                <CloudinaryContext cloudName={process.env.REACT_APP_CLOUDINARY_CLOUD_NAME}>                                             
                    <Elements stripe={stripePromise}>
                    <MainMarkup />
                    </Elements>
                    {/* <LandingMarkup />
                    <SellerMarkup />
                    <DealerMarkup /> */}
                    <Markup2 />
                </CloudinaryContext>   
            </AuthContext.Provider>
        </div>
    );
}

export {App, getTokens};
