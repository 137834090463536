import React, { Component } from 'react';
import { Link } from 'react-router-dom';

// import {} from 'react-router-dom'


import {AuthContext, getTokens, setTokens} from './../../context/auth';

class Header extends Component {

    state = {
        isLoggedIn: false,
        name: '',
        role: '',
        originrole: ''
    }

    constructor(props) {
        super(props);
        console.log(">> Header: constructor: props:", props);
        this.state.originrole = props.originrole;
        console.log(">> Header: constructor: state:", this.state);
    }

    componentWillReceiveProps(nextProps) {
        console.log(">> Header: componentWillReceiveProps: nextProps:", nextProps);
        this.setState({originrole:nextProps.originrole});
        console.log(">> Header: componentWillReceiveProps: state:", this.state);
    }

    componentDidMount() {
        console.log(">> Header: componentDidMount: state:", this.state);
        var authTokens = getTokens();
        if (authTokens.tokens != null && authTokens.tokens != '') {
            //console.log(">>>> AAA: authToken: ", authTokens.tokens);
            this.setState({isLoggedIn:true, name:authTokens.name, role:authTokens.role});
        } else {
            //console.log(">>>> BBB: authToken: ", authToken);
            this.setState({isLoggedIn:false, name:'', role:''});
        }

        // sidebar open/close

        var btn = document.querySelector('.navicon');
        var aaa = document.querySelector('.myNavbar ');

        function toggleFunc() {
            return aaa.classList.toggle("show");
        }

        btn.addEventListener('click', toggleFunc);


        // Sidenav li open close
        var navUl = [].slice.call(document.querySelectorAll('.navbar-nav > li'));
        for (var y = 0; y < navUl.length; y++) {
            navUl[y].addEventListener('click', function () { checkLi(this) });
        }

        function checkLi(current) {
            navUl.forEach(el => el.classList.remove('open'));
            current.classList.add('open');
        }

    }
    render() {
        return (
            <AuthContext.Consumer>
            {context => (
            <header className="site-header mo-left header" style={{'z-index':"100 !important"}}>
                <div className="top-bar bg-dark">
                    <div className="container">
                        <div className="row d-flex justify-content-between">
                            <div className="dlab-topbar-left">
                                {/* <ul>
                                    <li>
                                        <Link to={'/hotel'} className="site-button-link"> Hotels </Link>
                                    </li>
                                    <li>
                                        <Link className="site-button-link" to={'/place'}>Places</Link>
                                    </li>
                                    <li><Link className="site-button-link" to={'/packages'}>Packages</Link></li>
                                </ul> */}
                            </div>
                            <div className="dlab-topbar-right top-login">
                                <ul>
                                    {this.state.name !== '' ? 'Hi ' + this.state.name + '!' : ''}
                                    {/*<li><Link to={'/login'} className="site-button-link" style={{ display: this.state.isLoggedIn === true? "none" : "" }}>Login</Link></li>
                                    <li><Link to={'/logout'} className="site-button-link" style={{ display: this.state.isLoggedIn === true? "" : "none" }}>Logout</Link></li>
                                    <li><Link to={'/register'} className="site-button-link" style={{ display: this.state.isLoggedIn === true? "none" : "" }}>Register</Link></li>*/}
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="sticky-header navbar-expand-lg">
                    <div className="main-bar clearfix onepage">
                        <div className="container clearfix">
                            <div className="logo-header mostion">
                                <Link to={'./'} ><img src={require('./../../images/logo.png')} alt="" /></Link>
                            </div>
                            <button className="navbar-toggler collapsed navicon justify-content-end" type="button" data-toggle="collapse" data-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
                                <span></span>
                                <span></span>
                                <span></span>
                            </button>
                            <div className="header-nav navbar-collapse collapse navbar myNavbar justify-content-end" id="navbarNavDropdown">
                                <ul className="nav navbar-nav">
                                    <li><Link to={'/seller/dashboard'} className="dez-page"
                                        style={{ display: this.state.isLoggedIn === true && this.state.originrole === 'SELLER'? "" : "none" }}>Home</Link></li>
                                    <li><Link to={'/dealer/dashboard'} className="dez-page"
                                        style={{ display: this.state.isLoggedIn === true && this.state.originrole === 'DEALER'? "" : "none" }}>Home</Link></li>
                                    <li><Link to={'/about'} className="dez-page"
                                        style={{ display: this.state.isLoggedIn === true ? "none" : "" }}>About</Link></li>
                                    <li><Link to={'/seller/about'} className="dez-page"
                                        style={{ display: this.state.isLoggedIn === true && this.state.originrole === 'SELLER'? "" : "none" }}>About</Link></li>
                                    <li><Link to={'/dealer/about'} className="dez-page"
                                        style={{ display: this.state.isLoggedIn === true && this.state.originrole === 'DEALER'? "" : "none" }}>About</Link></li>
                                    <li><Link to={'/seller/profile'} className="dez-page"
                                        style={{ display: (this.state.isLoggedIn === true && this.state.originrole === 'SELLER')? "" : "none" }}>My Profile</Link></li>
                                    <li><Link to={'/dealer/carsearch'} className="dez-page"
                                        style={{ display: this.state.isLoggedIn === true && this.state.originrole === 'DEALER'? "" : "none" }}>Search Cars</Link></li>
                                    <li><Link to={'/dealer/profile'} className="dez-page"
                                        style={{ display: this.state.isLoggedIn === true && this.state.originrole === 'DEALER'? "" : "none" }}>My Profile</Link></li>
                                    <li><Link to={'/login'} className="dez-page" style={{ display: this.state.isLoggedIn === true? "none" : "" }}>Sign In</Link></li>
                                    <li><Link to={'/logout'} className="dez-page" style={{ display: this.state.isLoggedIn === true? "" : "none" }}>Sign Out</Link></li>
                                    <li><Link to={'/seller/register'} className="dez-page"
                                        style={{ display: this.state.isLoggedIn === true? "none" : (this.state.originrole === 'SELLER')? "" : "none" }}>Register</Link></li>
                                    <li><Link to={'/dealer/register'} className="dez-page"
                                        style={{ display: this.state.isLoggedIn === true? "none" : (this.state.originrole === 'DEALER')? "" : "none" }}>Register</Link></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
            )}
            </AuthContext.Consumer>
        )
    }
}
export default Header;