import React, { useState } from 'react'
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import CarSearch from './Pages/CarSearch';
import SellerHomepage from './Pages/SellerHomepage';
import SellerDashboard from './Pages/SellerDashboard';
import SellerCarDetails from './Pages/SellerCarDetails';
import AdminDashboard from './Pages/AdminDashboard';
import DealerHomepage from './Pages/DealerHomepage';
import DealerDashboard from './Pages/DealerDashboard';
import DealerCarDetails from './Pages/DealerCarDetails';
import DealerProfile from './Pages/DealerProfile';
import DealerChangeTier from './Pages/DealerChangeTier';
import DealerChangeLicense from './Pages/DealerChangeLicense';
import DealerGenerateEmail from './Pages/DealerGenerateEmail';
import LandingHomepage from './Pages/LandingHomepage';
import SellerRegister from './Pages/SellerRegister';
import SellerProfile from './Pages/SellerProfile';
import MainLogin from './Pages/MainLogin';
import Login2 from './Pages/Login2';
import Login from './Pages/Login';
import Logout from './Pages/Logout';
import About from './Pages/About';
import ForgotPassword from './Pages/ForgotPassword';
import ForgotSubmitted from './Pages/ForgotSubmitted';
import VerifiedEmail from './Pages/VerifiedEmail';
import ForgotPass from './Pages/ForgotPass';
import ResetLink from './Pages/ResetLink';
import ResetPassword from './Pages/ResetPassword';
import LinkExpired from './Pages/LinkExpired';
import SomethingWrong from './Pages/SomethingWrong';
import GoToHomepage from './Pages/GoToHomepage';
import AuthRoute from '../context/AuthRoute';

import DealerRegister from './Pages/DealerRegister';
import DealerChangeCc from './Pages/DealerChangeCc';
import AuthRouteStripe from '../context/AuthRouteStripe';
import {useStripe, CardElement, ElementsConsumer, useElements, Elements} from '@stripe/react-stripe-js';
// import {loadStripe} from '@stripe/stripe-js';

// export const stripePromise = loadStripe('pk_test_51H7YdcCyoD85g3Lthhabm85GXSr6KA8PVtBFbcG6HBV0wWJu4zXgaWZly78u1UejJKPdqook0pfYa5wkUOE6QzRv00Wc8aptqG');


function MainMarkup(props) {

    return (

        <BrowserRouter basename="/">
            <div className="page-wraper">
                <Switch>
                    <Route path='/' exact component={LandingHomepage} />
                    <Route path='/dealer/' role="DEALER" exact component={DealerHomepage}/>
                    <AuthRoute path='/admin/dashboard' role="ADMIN" exact component={AdminDashboard}/>    
                    <AuthRoute path='/dealer/dashboard' role="DEALER" exact component={DealerDashboard}/>
                    <AuthRoute path='/dealer/cardetails' role="DEALER" exact component={DealerCarDetails}/>
                    <AuthRoute path='/dealer/carsearch' role="DEALER" exact component={CarSearch}/>
                    <AuthRoute path='/dealer/changetier' role="DEALER" exact component={DealerChangeTier} />
                    <AuthRoute path='/dealer/changelicense' role="DEALER" exact component={DealerChangeLicense}/>
                    <AuthRoute path='/dealer/generateemail' role="DEALER" exact component={DealerGenerateEmail}/>
                    <AuthRoute path='/dealer/profile' role="DEALER" exact component={DealerProfile}/>
                    <Route path='/seller/' exact component={SellerHomepage}/>
                    <Route path='/seller/register' exact component={SellerRegister} />
                    <AuthRoute path='/seller/profile' role="SELLER" exact component={SellerProfile}/>
                    <AuthRoute path='/seller/dashboard' role="SELLER" exact component={SellerDashboard}/>
                    <AuthRoute path='/seller/cardetails' role="SELLER" exact component={SellerCarDetails}/>
                    <Route path='/login' exact component={MainLogin} />                    
                    <Route path='/logout' exact component={Logout} />
                    <Route path='/about' exact component={About} />
                    <Route path='/dealer/about' originrole="DEALER" exact component={About} />
                    <Route path='/seller/about' originrole="SELLER" exact component={About} />
                    <Route path='/forgotpassword' exact component={ForgotPassword} />    
                    <Route path='/forgotsubmitted' exact component={ForgotSubmitted} />
                    <Route path='/verifiedemail' exact component={VerifiedEmail} />
                    <Route path='/resetlink' exact component={ResetLink} />
                    <Route path='/resetpassword' exact component={ResetPassword} />
                    <Route path='/linkexpired' exact component={LinkExpired} />
                    <Route path='/mainerror' exact component={SomethingWrong} />
                    <Route path='/gotohomepage' exact component={GoToHomepage} />
                    {/* <Route path='/dealer/register' exact component={Register} /> */}
                    {/* <Route path='/register2' exact component={Register2} />
                    <Route path='/forgot' exact component={ForgotPass} />
                    <Route path='/contact' exact component={Contacts} /> */}
                    {/* /* <Elements stripe={stripePromise}> */}
                        <AuthRouteStripe path='/dealer/changecc' role="DEALER" exact component={DealerChangeCc}/>
                        <Route path='/dealer/register' exact render={(props) => {
                            return(
                                <ElementsConsumer>
                                    {({elements, stripe}) => (
                                        <DealerRegister elements={elements} stripe={stripe} {...props} />
                                    )}
                                </ElementsConsumer>
                            )
                            }
                        } />

                        {/* <Route path='/dealer/changecc' exact render={(props) => {
                            return(
                                <ElementsConsumer>
                                    {({elements, stripe}) => (
                                        <DealerChangeCc elements={elements} stripe={stripe} {...props} />
                                    )}
                                </ElementsConsumer>
                            )
                            }
                        } /> */}
                        
                    {/* </Elements> */}
                </Switch>
            </div>
        </BrowserRouter>

    )
}

export default MainMarkup;