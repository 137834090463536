import React, { Component } from 'react';
import axios from 'axios';
import { useAuth } from "../context/auth";
import { AuthContext } from "../context/auth";
import { Api } from "../utils/Api";
import { getTokens } from "../context/auth";


class SellerMessageForm extends Component {

    state = {
        car_id: '',
        message: '',
        listing_id: '',
        bid_id: '',
        dealer_id: '',
        message: -1,
        messages: [],
    }

    componentWillReceiveProps(nextProps){
        console.log("ReceiveProps Seller Message Form", nextProps);
        this.setState({
            car_id: nextProps.val.car_id,
            listing_id: nextProps.bid.listing_id,
            bid_id: nextProps.bid.bid_id,
            dealer_id: nextProps.bid.dealer_id,
            messages: nextProps.val.messages, 
            })
        console.log("Messages Load==>",nextProps.val.messages);
    }

    handleChange = (e) => {
        this.setState({[e.target.name]: e.target.value})
    }

    componentDidMount() {

    }

    handleSubmit = event => {
        event.preventDefault();
        console.log(this.state)

        const headers = {
          'Access-Control-Allow-Origin': '*'
        }
        const data = {
            'car_id': this.state.car_id,
            'listing_id' : this.state.listing_id,
            'bid_id' : this.state.bid_id,
            'dealer_id' : this.state.dealer_id,
            'message' : this.state.message,
        }
        console.log("Submit Message =>>>>>>>>",data);
        Api.post('/ham/create_seller_message', {data:data})
            .then(res => {
                console.log(res);
                console.log(res.data);
            })
    }
 render() {
  return (
   <div>
	<form className="dealer-message" onSubmit={this.handleSubmit}>
    <div className="row">
        <div className="col-md-12 col-lg-12 col-xl-12 col-sm-12 col-12">
            <div className="form-group">
                <select className="form-control" id="message" name="message" value={this.state.message} onChange={this.handleChange}>
                    <option disabled value={-1}> -- select an option -- </option>
                    {this.state.messages.map(r => <option value={r.id} key={r.id}>{r.name}</option> )}
                </select>
            </div>
        </div>
        <div className="col-md-12 col-lg-12 col-xl-12 col-sm-12 col-12">
            <button type="submit" className="site-button btn-block">Submit Answer</button>
        </div>
    </div>
    </form>
   </div>
  );
 }
}

export default SellerMessageForm;