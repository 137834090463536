import React, { Component } from 'react'
import { Link } from 'react-router-dom';


const steps = [
    {
        image: require('./../images/hamilton/man-in-black-jacket-and-black-pants-sitting-near-silver-3807386.jpg'),
        step: 1,
        title: 'Register',
        desc: 'Enter your dealership information along with a valid dealer license and credit card for payment.',

    },
    {
        image: require('./../images/hamilton/car_driver_1_small.jpg'),
        step: 2,
        title: 'Email Verification',
        desc: 'Check your email for registration verification. Click on the Verification link, and login into the Upshift using your email and registered password.',

    },
    {
        image: require('./../images/hamilton/close-up-photography-of-silver-car-1236710.jpg'),
        step: 3,
        title: 'Search Cars',
        desc: 'Define a search - search year make and model of specific cars you would like to purchase.',

    },
    {
        image: require('./../images/hamilton/car_key_handoff_small.jpg'),
        step: 4,
        title: 'Add Wish List',
        desc: 'Search and add particular cars that will show up when added by sellers.  This will give you a shortcut for cars that are right for your lot.',

    },
    {
        image: require('./../images/hamilton/car_key_handoff_small.jpg'),
        step: 5,
        title: 'Accept or Counter-offer bids and view Past Transactions',
        desc: 'Sellers will see real time buy-bids, along with notifications about the status of your bid.  After you have accepted a bid, the seller will receive an email with instructions for conclusion of transaction.  Dealers will send inspection and transportation information if needed.',

    },
]

class DealerHowTo extends Component {

    render() {
        return (
            <div className="section-full bg-white content-inner dlab-about-1 promotions" id="about-us">
                <div className="container">
                    <div className="section-head text-center">
                        <h1 className="text-uppercase m-b0">How it Works</h1>
                        <p className="font-18">Simply follow these simple steps</p>
                    </div>
                    <div className="row" id="masonry">
                        <style dangerouslySetInnerHTML={{__html: `
                                .ham-howto-desc {
                                    border:1px solid #e2f3ff;
                                    padding-left: 15px;
                                    padding-top: 10px;
                                    border-radius:4px;
                                    background: #fafdff;
                                }
                        `}} />
                        <table>
                        {steps.map((item, index) => (
                                <tr>
                                <td>
                                    <div>
                                        <div className="day-details bg-primary">Step {item.step}</div>
                                    </div>
                                </td>
                                <td>
                                    <div className="ham-howto-desc">
                                        <h4>{item.title}</h4>
                                        <p>{item.desc}</p>
                                    </div>
                                </td>
                            </tr>
                        ))}
                        </table>
                    </div>
                </div>
            </div>



        )
    }
}
export default DealerHowTo;