import React, { Component, useState } from 'react'
import { Link } from 'react-router-dom';
import { Button, Form, FormGroup, Label, Input, FormText } from 'reactstrap';
import { Api } from "../../utils/Api";
import ReactSlider from 'react-slider'
import '../../css/slider.css';

// Stripe
import {useStripe, CardElement, ElementsConsumer, useElements, Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardInput from './../../components/CardInput';
import TextField from '@material-ui/core/TextField';
import {makeStyles} from '@material-ui/core/styles';
import { element } from 'prop-types';

import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import {ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const useStyles = makeStyles({
    root: {
      maxWidth: 500,
      margin: '35vh auto',
    },
    content: {
      display: 'flex',
      flexDirection: 'column',
      alignContent: 'flex-start',
    },
    div: {
      display: 'flex',
      flexDirection: 'row',
      alignContent: 'flex-start',
      justifyContent: 'space-between',
    },
    button: {
      margin: '2em auto 1em',
    },
  });

class DealerChangeCc extends Component {
    constructor(props) {
        super(props);
        this.state = {
            entity_id: '',
            email: '',
            status: '',
            setStatus: '',
            clientSecret: '',
            setClientSecret: '',
            stripe: '',
            elements: '',
            card_error: ''
        }
    }

    componentDidMount() {
        Api.get('/ham/my_profile')
            .then(res => {
                console.log("Dealer Profile=========>",res.data);
                this.setState({
                    entity_id: res.data.user.entity_id,
                    email: res.data.user.email
                });
                console.log("Company name:", res.data.user.company_name);
        });
    }

    handleChange = (e) => {
        this.setState({[e.target.name]: e.target.value})
    }

    
    handleSubmit = async (event) => {
        event.preventDefault();

        confirmAlert({
            title: 'Confirm to submit',
            message: 'Are you sure to do this.',
            buttons: [
                {
                    label: 'Yes',
                    onClick: async () => {
                        const {stripe, elements} = this.props;
                        console.log("Props", this.props);
                        console.log(stripe);
                        console.log(elements);
                        if (!stripe || !elements) {
                            console.log("Fail")
                            // Stripe.js has not loaded yet. Make sure to disable
                            // form submission until Stripe.js has loaded.
                            return;
                        }

                        if (this.state.status !== '') {
                            const result = await stripe.confirmCardPayment(this.state.clientSecret, {
                                payment_method: {
                                card: elements.getElement(CardElement),
                                billing_details: {
                                    email: this.state.email,
                                },
                                },
                            });
                            if (result.error) {
                                console.log(result.error.message);
                                // Show error in payment form
                            } else {
                                console.log('Hell yea, you got that sub money!');
                            }
                        } else {
                            console.log("test2");
                            const result = await stripe.createPaymentMethod({
                            type: 'card',
                            card: elements.getElement(CardElement),
                            billing_details: {
                                email: this.state.email,
                            },
                            });

                            if (result.error) {
                                console.log(result.error.message);
                                console.log("Test1");
                                // Show error in payment form
                            } else {
                                console.log("Test2");
                                const payload = {
                                    email: this.state.email,
                                    payment_method: result.paymentMethod,
                                    result_from_stripe: result,
                                    ups_user_id: this.state.ups_user_id,
                                };
                                // Otherwise send paymentMethod.id to your server
                                const res = ""
                                Api.post('/ham/update_payment_method', {data: payload})
                                    .then(res => {
                                
                                        // // eslint-disable-next-line camelcase
                                        const {status, error, client_secret} = res.data;

                                        // if (status === 'requires_action') {
                                        //     this.state.setStatus(status);
                                        //     this.state.setClientSecret(client_secret);
                                        //     stripe.confirmCardPayment(client_secret).then(function(result) {
                                        //     if (result.error) {
                                        //         console.log("test1");
                                        //         // Display error message in your UI.
                                        //         // The card was declined (i.e. insufficient funds, card has expired, etc)
                                        //         console.log(result.error.message);
                                        //     } else {
                                        //         console.log("test2");
                                        //         // Show a success message to your customer
                                        //         console.log('Hell yea, you got that sub money!');
                                        //     }
                                        //     });
                                        // } else {
                                        //     console.log('Hell yea, you got that sub money!');
                                        // }

                                        if (status === 'success'){
                                            const location = "/dealer/profile";
                                            window.location.assign(location);
                                            // console.log("Test");
                                        }else{
                                            this.setState({
                                                card_error: error
                                            });
                                        }

                                        
                                    })                               
                            }
                        }
                    }
                },
                {
                    label: 'No',
                    onClick: () => {
                        console.log("None");
                    }
                }
            ]
        });
    }

    render() {
        const {stripe, elements} = this.props;
        return (
            <div className="section-full content-inner-2 shop-account bg-white">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 text-center">
                            <h3 className="font-weight-700 m-t0 m-b20">Change Credit Card</h3>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <br />
                            <div className="p-a30 border-1  max-w500 m-auto">
                                <div className="tab-content">
                                    <h4 className="font-weight-700"></h4>
                                        <div className="text-left">
                                            <Card className={useStyles.root}>
                                                <CardContent className={useStyles.content}>
                                                    <CardInput />
                                                    <small class="text-danger">
                                                        {this.state.card_error &&
                                                            <span>{this.state.card_error}</span>
                                                        }
                                                    </small>
                                                    <br />
                                                    <div className={useStyles.div}>
                                                        <Button variant="contained" color="primary" className={useStyles.button} onClick={this.handleSubmit}>
                                                            Submit Payment Method
                                                        </Button>
                                                        <ToastContainer position="top-center"/>
                                                    </div>
                                                </CardContent>
                                            </Card>
                                        </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default DealerChangeCc;