import React, { Component } from 'react';
import axios from 'axios';
import { useAuth } from "../context/auth";
import { AuthContext } from "../context/auth";
import { Api } from "../utils/Api";
import { getTokens } from "../context/auth";
import $ from 'jquery';
import { Timeline, TimelineEvent } from "react-event-timeline";


class TimelineMessages extends Component {

    constructor(props) {
        super(props);
        this.state = {
            messages: [],
        }

    }

    componentWillReceiveProps(nextProps){
        console.log('<<<<<<<<<<<<', nextProps);
        this.setState({
            messages: nextProps.val
            })
        }      


  handleChange = (e) => {
      this.setState({[e.target.name]: e.target.value})
  }


  handleClassType = (v) => {
    if (v=='QUESTION'){
        return "la la-question md-18"
    } else if(v=="ANSWER"){
        return "la la-check md-18"
    }else{
        return "la la-exclamation md-18"
    }
  }

  handleSubmit = event => {
    event.preventDefault();
    console.log(this.state)

        const headers = {
        'Access-Control-Allow-Origin': '*'
        }
        Api.post('/ham/place_bid', {data:this.state})
            .then(res => {
                console.log(res);
                console.log(res.data);
            })
  }
    render() {
        return (
            <Timeline>
                {this.state.messages.map(r => (
                    <TimelineEvent 
                        index={r.id}
                        title="John Doe sent a SMS"
                        createdAt="2016-09-12 10:06 PM"
                        icon={<i className={this.handleClassType(r.message_type)} style={{'font-size':'200%'}}></i>}
                    >
                        {r.body}
                    </TimelineEvent>
                ))}
            </Timeline>
        );
    }
}

export default TimelineMessages;