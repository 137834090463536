import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useAuth } from "./auth";
import {useStripe, CardElement, ElementsConsumer, useElements, Elements} from '@stripe/react-stripe-js';
// import {stripePromise} from "../markup/MainMarkup";

function AuthRouteStripe({ component: Component, ...rest }) {

  const {getAuthTokensOverloaded, setAuthTokensOverloaded} = useAuth();
  
  return(
    <Route {...rest} render={(props) => 
      getAuthTokensOverloaded().tokens != null && getAuthTokensOverloaded().role == rest['role']? (
        <ElementsConsumer>
            {({elements, stripe}) => (
                <Component elements={elements} stripe={stripe} {...props} />
            )}
        </ElementsConsumer>
      ) : (
        <Redirect to={{ pathname: "/login", state: { referer: props.location } }} />
    )}
    />
  );
}

export default AuthRouteStripe;