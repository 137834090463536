import React, { Component } from 'react'
import Slider from "react-slick";
import { Link } from 'react-router-dom';
import LandingHeader from './../Layout/LandingHeader';
import Header from './../Layout/Header';
import Footer from './../Layout/Footer';
import {AuthContext, getTokens, setTokens} from './../../context/auth';

var bg1 = require('./../../images/background/bg1.jpg');
// var bg3 = require('./../../images/banner/bnr1.jpg');
var bg3 = require('./../../images/main-slider/slide2_car.jpg');

function SampleNextArrow(props) {
  const { onClick } = props;
  return (
    <div className="la la-angle-right nav-right"  onClick={onClick}/>
  );
}

function SamplePrevArrow(props) {
  const { onClick } = props;
  return (
    <div className="la la-angle-left nav-left" onClick={onClick}/>
  );
}

class About extends Component {

    state = {
        isLoggedIn: false,
        name: '',
        role: '',
        originrole: ''
    }

    constructor(props) {
        super(props);
        console.log(">> About: constructor: props:", props);
        this.state.originrole = props.originrole;
        console.log(">> About: constructor: state:", this.state);
    }

    componentDidMount() {
        console.log(">> About: componentDidMount: state:", this.state);
        var authTokens = getTokens();
        if (authTokens.tokens != null && authTokens.tokens != '') {
            //console.log(">>>> AAA: authToken: ", authTokens.tokens);
            this.setState({isLoggedIn:true, name:authTokens.name, role:authTokens.role});
        } else {
            //console.log(">>>> BBB: authToken: ", authToken);
            this.setState({isLoggedIn:false, name:'', role:''});
        }
    }

    render() {
        var settings = {
            dots: false,
            slidesToShow: 3,
            infinite: true,
			nextArrow: <SampleNextArrow />,
			prevArrow: <SamplePrevArrow />,
            responsive: [
                {
                    breakpoint: 1200,
                    settings: {
                        slidesToShow: 3,
                    }
                },
                {
                    breakpoint: 991,
                    settings: {
                        slidesToShow: 2,
                    }
                },
                {
                    breakpoint: 576,
                    settings: {
                        slidesToShow: 1,
                    }
                }
            ]
        };
        return (
            <div>
                { console.log(">> ORIGINROLE:", this.state.originrole) }
                { console.log(">> ROLE FROM LOGIN TOKEN:", this.state.role) }
                <LandingHeader/>
                
                <div className="dlab-bnr-inr overlay-black-middle" style={{ backgroundImage: "url(" + bg3 + ")", backgroundSize: 'cover' }}>
                    <div className="container">
                        <div className="dlab-bnr-inr-entry">
                            <h1 className="text-white">About Us</h1>
                            <div className="breadcrumb-row">
                                <ul className="list-inline">
                                    <li><Link>Home</Link></li>
                                    <li>About Us</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="content-block">
                    <div className="section-full content-inner overlay-white-middle">
                        <div className="container">
                            <div className="row align-items-start m-b20">
                                <div className="col-md-12 col-lg-6">
                                    <p>Hello, my name is Joel Moss, I started my career in auto sales on September, 10th 2001.  
                                        In my 19yrs of auto experience, I have been looking for ways to streamline the car purchasing experience.  
                                        I have seen the digital and information age take over all aspects of car sales and customer trade expectations.   
                                        As a Pre-Owned director I have the task of finding the right car for my pre-owned lot, which is why I created a way to eliminate the headache for both the dealer and customer.
                                        UpShift is the only customer to dealer platform that helps alleviate the need to drive from dealer to dealer for the best trade number.  
                                        UpShifts’ national database of dealers will pay top dollar for cars they know will sell in their market.
                                    </p>
                                </div>
                                <div className="col-md-6 col-lg-3">
                                    <h2>Mission</h2>
                                    <p>My mission is to take the hassle out of trading your vehicle and change the relationship between dealers and customers.  My vision is to shrink the vehicle trade market and put all the top national buyers in one place.</p>
                                </div>
                                <div className="col-md-6 col-lg-3">
                                    <h2>Why UPShift?</h2>
                                    <ul>
                                        <li>Takes the hassle out of selling/trading your car.</li>
                                        <li>No more driving to multiple dealerships and waiting for offers.</li>
                                        <li>Real time dealers with real money for your car.</li>
                                        <li>Get the most money for your car now!</li>
                                        <li>More bang for your buck.</li>
                                        <li>The most convenient experience.</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-3 col-md-6 col-12 col-sm-6 m-b30">
                                    <div className="counter-style-1">
                                        <div className="">
                                            <i className="icon ti-bar-chart text-primary"></i>
                                            <span className="counter">72</span>
                                        </div>
                                        <span className="counter-text">Completed Trades</span>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-6 col-12 col-sm-6 m-b30">
                                    <div className="counter-style-1">
                                        <div className="">
                                            <i className="icon ti-user text-primary"></i>
                                            <span className="counter">72</span>
                                        </div>
                                        <span className="counter-text">Happy Sellers</span>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-6 col-12 col-sm-6 m-b30">
                                    <div className="counter-style-1">
                                        <div className="">
                                            <i className="icon ti-headphone-alt text-primary"></i>
                                            <span className="counter">30</span>
                                        </div>
                                        <span className="counter-text">Dealer Members</span>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-6 col-12 col-sm-6 m-b30">
                                    <div className="counter-style-1">
                                        <div className="">
                                            <i className="icon ti-cup text-primary"></i>
                                            <span className="counter">2</span>
                                        </div>
                                        <span className="counter-text">Affiliated Services</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                <Footer />
            </div>
        )
    }
}
export default About;