import React, { Component } from 'react'
import { Button, Form, FormGroup, Label, Input, FormText } from 'reactstrap';
import { Api } from "../../utils/Api";
import $ from 'jquery';
import ReactSlider from 'react-slider'
import '../../css/slider.css';
import Dropzone from 'react-dropzone'
import Resizer from 'react-image-file-resizer';
import EXIF from "exif-js";

const baseStyle = {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '20px',
    borderWidth: 2,
    borderRadius: 2,
    borderColor: '#eeeeee',
    borderStyle: 'dashed',
    backgroundColor: '#fafafa',
    color: '#bdbdbd',
    outline: 'none',
    transition: 'border .24s ease-in-out'
};

class Register extends Component {
    constructor(props) {
        super(props);
        this.state = {
            entity_id: '',
            company_name: '',
            tier: '',
            tier_list: [],
            address: '',
            city: '',
            state: '',
            zip: '',
            phone: '',
            email: '',
            password: '',
            verify_password: '',
            best_time_to_contact: '',
            tier_list: ['tier1', 'tier2'],
            license_enabled: true,
            style_button_dropzone: 'btn btn-secondary',

        }
    }

    componentDidMount() {
    }

    handleChange = (e) => {
        this.setState({[e.target.name]: e.target.value})
    }

    handleStep1 = (e) => {
        e.preventDefault();
        const data  = {
            make: this.state.make,
            company_name: this.state.company_name,
            tier: this.state.tier,
            address: this.state.address,
            city: this.state.city,
            state: this.state.state,
            zip: this.state.zip,
            phone: this.state.phone,
            email: this.state.email,
            password: this.state.password,
            verify_password: this.state.verify_password,
            best_time_to_contact: this.state.best_time_to_contact,
        }
        console.log("test=>", this.state)
        Api.post('/ham/register_dealer', {data: data})
            .then(res => {
                const entity_id = res.data.entity_id;
                const style_button_dropzone = 'dropzone_box site-button button-block';
                console.log(entity_id);
                this.setState({ entity_id, style_button_dropzone});

                if(this.state.entity_id != ''){
                    this.setState({license_enabled: false})
                }
            })
    }

    handleStep2 = (e) => {
        e.preventDefault();
    }

    uploadToServer = (uri, orig_file) => {
       var parentThis = this;
       const formData = new FormData();
       console.log(orig_file);
       formData.append("file", uri, orig_file.name);
       formData.append("entity_id", this.state.id);
       Api.post("/ham/upload_dealer_license", formData)
            .then(
            res => {
                parentThis.componentDidMount();
                console.log('Testing: ', res);
                }
            )
            .catch(err => console.warn(err));
    }

    onDrop = (file) => {
        const headers = {
          'Access-Control-Allow-Origin': '*'
        }

        var parentThis = this;

        EXIF.getData(file[0], function() {
            var orientation = 0;
            var rotate = 0;
            var exifData = EXIF.pretty(this);
            if (exifData) {
              console.log(exifData);
              orientation = EXIF.getTag(this, "Orientation");
              console.log(orientation);
              if (orientation == 6) rotate = 90;
              if (orientation == 8) rotate = 270;
              if (orientation == 3) rotate = 180;

            } else {
              console.log("No EXIF data found in image '" + file.name + "'.");
            }

            console.log(rotate);

            Resizer.imageFileResizer(
                    file[0],
                    800,
                    800,
                    'JPEG',
                    100,
                    rotate,
                    uri => {
                        console.log(uri);
                        parentThis.uploadToServer(uri, file[0]);
                    },
                    'blob'
                );
          });
    }

    render() {
        return (
            <div className="section-full content-inner-2 shop-account bg-white">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 text-center">
                            <h3 className="font-weight-700 m-t0 m-b20">DEALER REGISTRATION</h3>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="p-a30 border-1  max-w500 m-auto">
                                <div className="tab-content">
                                    <form id="login" className="tab-pane active">
                                        <h4 className="font-weight-700">Step 1</h4>
                                        <div className="form-group">
                                            <label className="font-weight-700">Company Name *</label>
                                            <input name="company_name" id="company_name" required="" className="form-control" placeholder="Company Name" type="text" onChange={this.handleChange} />
                                        </div>
                                        <div className="form-group">
                                            <label className="font-weight-700">Membership Level *</label>
                                            <select className="form-control" id="tier" name="tier" className="form-control" onChange={this.handleChange}>
                                              <option disabled selected value> -- select an option -- </option>
                                              {this.state.tier_list.map(r =><option value={r} key={r}>{r}</option>)}
                                            </select>
                                        </div>

                                        <div className="form-group">
                                            <label className="font-weight-700">Address *</label>
                                            <input name="address" id="address" required="" className="form-control" placeholder="Address" type="text" onChange={this.handleChange} />
                                        </div>
                                        <div className="form-group">
											<label className="font-weight-700">City *</label>
											<input name="city" id="city" required="" className="form-control" placeholder="City" type="text" onChange={this.handleChange} />
										</div>
										<div className="form-group">
											<label className="font-weight-700">State *</label>
											<input name="state" id="state" required="" className="form-control" placeholder="State" type="text" onChange={this.handleChange} />
										</div>
										<div className="form-group">
											<label className="font-weight-700">Zip *</label>
											<input name="zip" id="zip" required="" className="form-control" placeholder="Zip" type="text" onChange={this.handleChange} />
										</div>
                                        <div className="form-group">
                                            <label className="font-weight-700">Phone *</label>
                                            <input name="phone" id="phone" required="" className="form-control" placeholder="Phone" type="number" onChange={this.handleChange} />
                                        </div>
                                        <div className="form-group">
                                            <label className="font-weight-700">Email *</label>
                                            <input name="email" id="email" required="" className="form-control " placeholder="Email" type="email" onChange={this.handleChange} />
                                        </div>
                                        <div className="form-group">
                                            <label className="font-weight-700">Password *</label>
                                            <input name="password" id="password" required="" className="form-control " placeholder="Password" type="password" onChange={this.handleChange} />
                                        </div>
                                        <div className="form-group">
                                            <label className="font-weight-700">Verify Password *</label>
                                            <input name="verify_password" id="verify_password" required="" className="form-control " placeholder="Verify Password" type="password" onChange={this.handleChange} />
                                        </div>
                                        <div className="form-group">
                                            <label className="font-weight-700">Best time to contact *</label>
                                            <input name="best_time_to_contact" id="" required="best_time_to_contact" className="form-control " placeholder="Best time to contact" type="text" onChange={this.handleChange} />
                                        </div>
                                        <div className="text-left">
                                            <button className="site-button button-lg radius-no outline outline-2" name="SaveInfo" onClick={this.handleStep1}>Save info</button>
                                        </div>
                                    </form>
                                </div>
                            </div>

                            <br />
                            <div className="p-a30 border-1  max-w500 m-auto">
                                <div className="tab-content">
                                    <form id="login" className="tab-pane active">
                                        <h4 className="font-weight-700">Step 2</h4>
                                        <div className="tour-days" id="dropzone">
                                            <Dropzone onDrop={this.onDrop}>
                                              {({getRootProps, getInputProps}) => (
                                                <div {...getRootProps({baseStyle})} class={this.state.style_button_dropzone}>
                                                  <input {...getInputProps()} disabled={this.state.license_enabled}/>
                                                  Upload Dealer License
                                                </div>
                                              )}
                                            </Dropzone>
                                            <br />
                                        </div>
                                    </form>
                                </div>
                            </div>

                            <br />
                            <div className="p-a30 border-1  max-w500 m-auto">
                                <div className="tab-content">
                                    <form id="login" className="tab-pane active">
                                        <h4 className="font-weight-700">Step 3</h4>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
export default Register;