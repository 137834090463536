import React, { Component } from 'react';
import { TabContent, TabPane,Nav, NavItem, NavLink} from 'reactstrap';
import classnames from 'classnames';
import TimelineMessages from '../components/TimelineMessages';
import SellerMessageForm from '../components/SellerMessageForm';
import { Api } from "../utils/Api";
import { extend } from 'lodash';
import { Timeline, TimelineEvent } from "react-event-timeline";
import { confirmAlert } from 'react-confirm-alert'; // Import
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser'; //Import

import {ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import moment from 'moment'


class BidTab extends Component {

    state = {
        dealers: [],
        activeTab: 1,
        seller_counter_offer: '',
        answer_message: -1,
        entity_id: '',
        preferred_dealer_id: '',
        listing_status: '',
    }

    constructor(props) {
        // console.log("--------------------------- BidTab.constructor:", props);
        super(props);
        this.state = {
            dealers: props.val.dealers,
            activeTab: 1,
            seller_counter_offer: '',
            answer_message: -1,
            entity_id: props.val.id,
            preferred_dealer_id: '',
            listing_status: props.val.listing_status,
        }
        console.log("===========>Listing status", this.state.listing_status);
    }

    componentWillReceiveProps(nextProps){
        // console.log("--------------------------- BidTab.componentWillReceiveProps:", nextProps);
        console.log("Dealers 1==========>", nextProps.val.dealers);
        this.setState({
            entity_id: nextProps.val.id,
            dealers: nextProps.val.dealers,
            preferred_dealer_id: nextProps.val.preferred_dealer_id,
            listing_status: nextProps.val.listing_status,
            })
        }

    
    changeTab = param => e => {
        this.setState({
            activeTab: param
        })
        // console.log(this.state.activeTab);
    }

    handleChange = (e) => {
        // console.log(e.target.value);
        this.setState({[e.target.name]: e.target.value})
    }

    handleChangeSellerCO = (e, index) => {
        let ds = [...this.state.dealers];
        let d = {...ds[index-1]};
        d.ui_states['SELLER_COUNTER_OFFER'] = e.target.value;
        ds[index-1] = d;
        this.setState({ds});
    }

    handleOffer = (btn_name, index) => e => {
        var data = {}
        var bid_id = this.state.dealers[index - 1].bid.bid_id;
        // console.log(btn_name, bid_id);

        if (btn_name == 'counter_offer'){
            data = {
                'bid_id': bid_id,
                'button_name': btn_name,
                'counter_offer': this.state.dealers[index-1].ui_states['SELLER_COUNTER_OFFER'],
                'car_id': this.state.entity_id,
            };
            // console.log(this.state.entity_id);
        }else if (btn_name == 'accept_offer'|| btn_name == 'accept_counter_offer') {
            data = {
                'bid_id': bid_id,
                'car_id': this.state.entity_id,
            };
        } else if (btn_name == 'accept_original_offer') {
            data = {
                'bid_id': bid_id,
                'response': 'ACCEPT',
                'car_id': this.state.entity_id,
            };
            btn_name = 'original_offer';
        }
        
        Api.post('/ham/'+ btn_name, {data: data})
            .then(res => {
                // console.log(res);
                let ds = [...this.state.dealers];
                let d = {...ds[index-1]};
                d.bid.ui_states = res.data.ui_states
                ds[index-1] = d;
                this.setState(res.data);

        })
    }

    handleClassType = (v) => {
        if (v=='QUESTION'){
            return "la la-question md-18"
        } else if(v=="ANSWER"){
            return "la la-check md-18"
        }else{
            return "la la-exclamation md-18"
        }
      }

      handleSubmitAnswer = m_id => event => {
        event.preventDefault();
        const data = {
            'message_id': m_id,
            'entity_id': this.state.entity_id,
            'listing_id' : this.state.listing_id,
            'bid_id' : this.state.bid_id,
            'dealer_id' : this.state.dealer_id,
            'message' : this.state.message,
            'answer_message': this.state.answer_message,
        }
        // console.log("Submit Message =>>>>>>>>",data);
        Api.post('/ham/create_seller_message', {data:data})
            .then(res => {
                this.setState({'dealers': res.data.dealers});
                toast("Your answer has been successfully submitted");
            })
    }

    getAnswerMesseges = event => {
        
    }

    render() {
        return (
            <div className="col-md-12">
                <div className="section-full search-filter">
                    <div className="container">
                        <Nav pills>
                            {this.state.dealers.map(r => (
                                <NavItem>
                                    <NavLink className={classnames({ active: this.state.activeTab === r.index })}
                                        onClick={this.changeTab(r.index)}>DEALER
                                    </NavLink>
                                </NavItem>
                            ))}
                        </Nav>
                    </div>
                </div>
                <div className="search-form-bx">
                    <div className="container">
                        <TabContent activeTab={this.state.activeTab}>
                            {console.log(">>>>>>> 2: this.state.dealers:", this.state.dealers)}
                            {this.state.dealers.map(r => (
                                <TabPane tabId={r.index}>
                                    <div className="day-details-bx">
                                        <div className="row sp20">
                                            <div className="form-group col-md-8 col-sm-4 col-4">    
                                                {r.bid != null ?
                                                    <p>Offer $ {r.bid.bid_price}</p>
                                                    : <p>There is no bid from this dealer yet.</p>
                                                }
                                                {r.ui_states.SELLER_COUNTER_OFFER ? 
                                                    <p>My counter Offer: ${r.ui_states.SELLER_COUNTER_OFFER}</p>
                                                    : <p>You have not made any counter-offer.</p>
                                                }
                                                {r.ui_states.DEALER_COUNTER_OFFER ? 
                                                    <p>Dealer Counter Offer: ${r.ui_states.DEALER_COUNTER_OFFER}</p>
                                                    : <p>There is no counter offer from this dealer.</p>
                                                }                                        
                                            </div>
                                            <div className="form-group col-md-4 col-sm-4 col-4 text-right">
                                                {/* {this.state.preferred_dealer_id == r.id || this.state.listing_status == 'TRANSACTION' ? */}
                                                <span>{r.dealer_name}</span>
                                                    {/* :<span></span> */}
                                                {/* } */}
                                            </div>
                                        </div>

                                        <div className="row sp20">
                                            <div className="form-group col-md-12 col-sm-12 col-12 text-center">
                                                {r.ui_states.ACCEPT_DEALER_OFFER ? <button type="button" name="btn_accept_offer" id="btn_accept_offer" onClick={this.handleOffer('accept_offer', r.index)} className="site-button sm-1" style={{float:"right"}}>Accept Offer</button>
                                                    : <span></span>}
                                            </div>
                                        </div>
                                        {r.ui_states.MAKE_SELLER_COUNTEROFFER ? 
                                        <div className="row sp20">
                                            <div className="form-group col-md-8 col-sm-8 col-8">
                                                <input type="text" name="seller_counter_offer" id="seller_counter_offer" 
                                                onChange={(e) => this.handleChangeSellerCO(e, r.index)} 
                                                className="form-control" placeholder="Enter Counter Offer" style={{float:"right"}}/>
                                            </div>
                                            <div className="form-group col-md-4 col-sm-4 col-4">
                                                <button type="button" name="2" id="2" onClick={this.handleOffer('counter_offer', r.index)} className="site-button sm-1" style={{float:"right"}}>Counter Offer</button>
                                            </div>
                                        </div>
                                        : <span></span>
                                        }
                                        {r.ui_states.ACCEPT_DEALER_COUNTEROFFER ?                                 
                                            <div className="row sp20">
                                                <div className="form-group col-md-12 col-sm-12 col-12 text-center">
                                                    {r.ui_states.ACCEPT_DEALER_COUNTEROFFER ? <button type="button" name="3" id="3" onClick={this.handleOffer('accept_counter_offer', r.index)} className="site-button sm-1" style={{float:"right"}}>Accept Dealer Counter Offer</button>
                                                        : <span></span>}
                                                </div>
                                            </div>
                                            :<span></span>
                                        }
                                        <div className="row sp20">
                                            <div className="form-group col-md-12 col-sm-12 col-12 text-center">
                                                {r.ui_states.ACCEPT_ORIGINAL_OFFER ? <button type="button" name="4" id="4" onClick={this.handleOffer('accept_original_offer', r.index)} className="site-button sm-1" style={{float:"right"}}>Accept Original Offer</button>
                                                    : <span></span>}
                                            </div>
                                        </div>
                                        <div class="row">
                                            <h4>Messages: </h4>
                                        </div>
                                        <div className="row">
                                            <Timeline>
                                                {r.messages.map(m => (
                                                    <>
                                                        <TimelineEvent 
                                                            index={m.id}
                                                            title={m.from_user}
                                                            createdAt={moment(m.dd_date_created).format('MMMM Do YYYY, h:mm:ss a')}
                                                            icon={<i className={this.handleClassType(m.message_type)} style={{'font-size':'200%'}}></i>}
                                                        >
                                                            {ReactHtmlParser(m.body)}
                                                        </TimelineEvent>
                                                        {m.is_answered == false && m.message_type == 'QUESTION' &&
                                                            <div style={{'margin': '47px'}}>
                                                            <form className="seller-message">
                                                                <div className="row">
                                                                    <div className="col-md-12 col-lg-12 col-xl-12 col-sm-12 col-12">
                                                                        <div className="form-group">
                                                                            <select className="form-control" id="answer_message" name="answer_message" value={this.state.answer_message} onChange={this.handleChange}>
                                                                                <option disabled value={-1}> -- select an option -- </option>
                                                                                {this.getAnswerMesseges()}
                                                                                {r.answer_messages.map(r => <option value={r} key={r}>{r}</option> )}
                                                                            </select>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-12 col-lg-12 col-xl-12 col-sm-12 col-12">
                                                                        <button type="submit" onClick={this.handleSubmitAnswer(m.id)} className="site-button btn-block">Submit Answer</button>
                                                                    </div>
                                                                </div>
                                                                </form>
                                                            </div>
                                                        }
                                                    
                                                    </>
                                                ))}
                                            </Timeline>
                                        </div>
                                    </div>
                                    <div className="row">
                                        
                                    </div>
                                </TabPane>
                            ))}
                        </TabContent>
                    </div>
                </div>
                <ToastContainer position="top-center"/>
            </div>
        );
    }
}

export default BidTab;