import React, { Component, useMemo } from 'react'
import Slider from "react-slick";
import { Link } from 'react-router-dom';
import Header from './../Layout/Header';
import Footer from './../Layout/Footer';
import BidForm from './../../components/BidForm';
import MessageForm from './../../components/MessageForm';
import queryString from 'query-string';
import ImageGallery from 'react-image-gallery';
import {ToastContainer, toast } from 'react-toastify';
import { confirmAlert } from 'react-confirm-alert'; // Import
import SimpleReactValidator from 'simple-react-validator'; // Import
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser'; //Import


import axios from 'axios';
import { useAuth } from "../../context/auth";
import { AuthContext } from "../../context/auth";
import { Api } from "../../utils/Api";
import { getTokens } from "../../context/auth";
import { Timeline, TimelineEvent } from "react-event-timeline";
import moment from 'moment'

import { Image, Video } from 'cloudinary-react';


import Dropzone from 'react-dropzone'

const baseStyle = {
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '20px',
  borderWidth: 2,
  borderRadius: 2,
  borderColor: '#eeeeee',
  borderStyle: 'dashed',
  backgroundColor: '#fafafa',
  color: '#bdbdbd',
  outline: 'none',
  transition: 'border .24s ease-in-out'
};

const activeStyle = {
  borderColor: '#2196f3'
};

const acceptStyle = {
  borderColor: '#00e676'
};

const rejectStyle = {
  borderColor: '#ff1744'
};

const hotelSlider = [
    {
        image: require('./../../images/hotel/pic1.jpg'),
    },
    {
        image: require('./../../images/hotel/pic2.jpg'),
    },
    {
        image: require('./../../images/hotel/pic3.jpg'),
    },
    {
        image: require('./../../images/hotel/pic4.jpg'),
    },
    {
        image: require('./../../images/hotel/pic5.jpg'),
    },
    {
        image: require('./../../images/hotel/pic6.jpg'),
    },
    {
        image: require('./../../images/hotel/pic7.jpg'),
    },
    {
        image: require('./../../images/hotel/pic8.jpg'),
    },
    {
        image: require('./../../images/hotel/pic9.jpg'),
    },
    {
        image: require('./../../images/hotel/pic10.jpg'),
    },
]

    const getImageName = './../../images/gallery/img4.jpg'
    const imageName = require('./../../images/gallery/img5.jpg');


var bg3 = require('./../../images/banner/bnr1.jpg');
class DealercarDetails extends Component {
    constructor(){
        super();
        
        this.handler = this.updateTimelineMessage.bind(this)

        this.state = {
            car_id : '',
            imgName : '',
            img_list : [],
            listing_id: '',
            listing_status: '',
            price: '',
            vin: '',
            odoometer: '',
            lean_holder: '',
            make: '',
            condition: '',
            body_style: '',
            cylinder: '',
            door: '',
            drive_type: '',
            ext_color: '',
            fuel_economy: '',
            fuel_type: '',
            interior: '',
            model: '',
            transmission: '',
            trim: '',
            year: '',
            isToggleOn: true,
            price: 0,
            counter_offer_btn: 'none',
            accept_counter_offer_btn: '',
            messages: [],
            message_list: [],
            bid_id: '',
            bid: {},
            dealer_counter_offer: '',
            is_answered: false,
            bid_price: null,
            city: '',
            state: '',
            zip: '',
            affiliated_inspection: -1,
            affiliated_transportation: -1,
            affiliated_inspection_list: [],
            affiliated_transportation_list: [],
            error_affiliated_inspection: '',
            error_affiliated_transportation: '',
            content_type: null,
            video_id: null,
        }
    }

    componentDidMount() {
        let url = this.props.location.search;
        let params = queryString.parse(url);
        var entity_id = params['entity_id'];
        Api.get('/ham/car_detail?entity_id='+entity_id)
            .then(res => {
                const imgs = res.data.car.img_list;
                console.log(imgs);
                this.setState({
                car_id: entity_id,
                img_list: imgs,
                listing_id: res.data.car.listing_id,
                listing_status: res.data.car.listing_status,
                imgName: res.data.car.image_first,
                year: res.data.car.year,
                price: res.data.car.price,
                make: res.data.car.make,
                model: res.data.car.model,
                trim: res.data.car.trim,
                condition: res.data.car.condition,
                body_style: res.data.car.body_style,
                drive_type: res.data.car.drive_type,
                fuel_type: res.data.car.fuel_type,
                fuel_economy: res.data.car.fuel_economy,
                transmission: res.data.car.transmission,
                cylinder: res.data.car.cylinder,
                doors: res.data.car.doors,
                price: res.data.car.price,
                vin: res.data.car.vin,
                odometer: res.data.car.odometer,
                lean_holder: res.data.car.lean_holder,
                bid_price: res.data.bid.bid_price,
                bid_id: res.data.bid.bid_id,
                bid: res.data.bid,
                messages: res.data.messages,
                message_list: res.data.message_list,
                is_answered: res.data.is_answered,
                city: res.data.car.city,
                state: res.data.car.state,
                zip: res.data.car.zip,
                affiliated_inspection: res.data.bid.affiliated_inspection,
                affiliated_transportation: res.data.bid.affiliated_transportation,
                affiliated_inspection_list: res.data.affiliated_inspection_list,
                affiliated_transportation_list: res.data.affiliated_transportation_list,
                content_type: res.data.car.content_type,
                video_id: res.data.car.video_id
            });
        })
    }

    toggleImage = (e) => {
        console.log('Toogle Image:', e.target.src);
         this.setState({
              imgName: e.target.src
            });
    }

    onDrop = (file) => {
        // const headers = {
        //   'Access-Control-Allow-Origin': '*'
        // }

        const formData = new FormData();

        formData.append("file", file[0]);

        Api.post("/ham/car_upload_file", formData)
        .then(res => console.log(res))
        .catch(err => console.warn(err));
    }

    handleChange = (e) => {
        console.log("==============>", e.target.name, e.target.value);
        this.setState({[e.target.name]: e.target.value})
    }

    handleDropdownAffiliate = (e) => {
        e.preventDefault();
        
        this.setState({[e.target.name]: e.target.value})
        
    }

    handleSaveAffiliate = (e) => {
        e.preventDefault();
        console.log('saving affiliate...', this.state.affiliated_inspection)
        console.log('saving affiliate...', this.state.affiliated_transportation)

        // if(this.validator.allValid()){
        confirmAlert({
            title: 'Confirm your selection',
            message: 'Are you sure you want to select affiliated vendor?',
            buttons: [
              {
                label: 'Yes',
                onClick: () => {
                    const data  = {
                        affiliated_inspection: this.state.affiliated_inspection,
                        affiliated_transportation: this.state.affiliated_transportation,
                        bid_id: this.state.bid_id
                    }
                    Api.post('/ham/save_affiliate', {data: data})
                        .then(res => {
                            console.log(res);
                            if(res.data.error_message == false){
                                toast("Affiliated vendor has been successfully selected");
                                this.setState(
                                    res.data.affiliate
                                    );
                            }else{
                                console.log("test123");
                                console.log(res.data.error.affiliated_inspection);
                                console.log(res.data.error.affiliated_transportation);
                                this.setState({
                                    error_affiliated_inspection: res.data.error.affiliated_inspection,
                                    error_affiliated_transportation: res.data.error.affiliated_transportation
                                })
                            }
                            
                        });
                }
              },
              {
                label: 'No',
                onClick: () => {
                    console.log("None");
                }
              }
            ]
        });

    }

    handleClassType = (v) => {
        if (v=='QUESTION'){
            return "la la-question md-18"
        } else if(v=="ANSWER"){
            return "la la-check md-18"
        }else{
            return "la la-exclamation md-18"
        }
      }

    handleOffer = (btn_name) => e => {
        var data = {}
        console.log(btn_name);
        if (btn_name == 'counter_offer') {
            data = {
                'bid_id': this.state.bid_id,
                'button_name': btn_name,
                'counter_offer': this.state.dealer_counter_offer,
                'car_id': this.state.car_id
            }
        } else {
            data = {
                'bid_id': this.state.bid_id,
                'button_name': btn_name
            }
        }
        
        Api.post('/ham/' + btn_name, {data: data})
            .then(res => {
                console.log(res);
                this.setState(prevState => ({
                    bid: {
                        ...prevState.bid,
                        ui_states: res.data.ui_states
                    }
                }))                
        })
    }

    handleBidPrice = (bid) => {
        this.setState({
            bid: bid,
            bid_price: bid.bid_price
        })
    }

    updateTimelineMessage = (msg_list) => {
        this.setState({
            message_list: msg_list,
            is_answered: false,
        })
    }

    render() {
        var settings = {
            dots: false,
            slidesToShow: 1,
            infinite: true,
        };

        const handleBid = btn_name => {
            var data = {}
            console.log(btn_name);
            if (btn_name == 'counter_offer'){
                data = {'button_name': btn_name, 'dealer_counter_offer': this.state.dealer_counter_offer}
            }else{
                data = {'button_name': btn_name}
            }
            Api.post('/ham/bid_offer', {data: data})
                .then(res => {
                    console.log(res);
            })
        }

        let url = this.props.location.search;
        let params = queryString.parse(url);
        var entity_id = params['entity_id'];
        return (
        <div>
            <Header originrole="DEALER"/>
            {/* <div className="dlab-bnr-inr overlay-black-middle" style={{ backgroundImage: "url(" + bg3 + ")", backgroundSize: 'cover' }}>
                <div className="container">
                    <div className="dlab-bnr-inr-entry">
                        <h1 className="text-white">Hotal Booking</h1>
                        <div className="breadcrumb-row">
                            <ul className="list-inline">
                                <li><Link>Home</Link></li>
                                <li>Hotal Booking</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div> */}
            <div className="content-block">
                <div className="section-full content-inner bg-white">
                    <div className="container">
                        <div className="row m-b30">
                            <div className="col-lg-8">
                                <div className="d-flex info-bx m-b30">
                                    <div className="tour-title">
                                        <h2>{this.state.year} {this.state.make} {this.state.model} {this.state.trim}</h2>
                                    </div>
                                    {/* <div className="tour-price ml-auto">
                                        <h2 className="price">${this.state.price}</h2>
                                    </div> */}
                                </div>
                                <div className="product-gallery on-show-slider">
                                    <Slider {...settings}>
                                        {hotelSlider.map((item, index) => (
                                            <div className="item" key={index}>
                                                <div className="dlab-box">
                                                    <div className="dlab-thum-bx">
                                                        <img src={this.state.imgName} alt="" />
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </Slider>
                                </div>
                                {this.state.content_type == 'photos' ?
                                    <>
                                    <ImageGallery items={this.state.img_list} />
                                    </>
                                    : <span></span>
                                }
                                {this.state.content_type == 'videos' ?
                                    <>
                                    {this.state.video_id != null ?
                                        <>
                                            <Video width="540px"
                                                key={this.state.video_id}
                                                publicId={this.state.video_id}
                                                controls={true}
                                            />
                                            </>
                                            :<p>There's no video yet.</p>
                                        }
                                    </>
                                    : <span></span>
                                }
                                <div className="day-details-bx">
                                    <div className="row">
                                        <div className="col-md-12 col-lg-8">
                                            <h4 className="m-b5">Car Detail</h4>
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <ul className="list-check primary">
                                                        <li>Make : {this.state.make}</li>
                                                        <li>Model : {this.state.model}</li>
                                                        <li>Trim : {this.state.trim}</li>
                                                        <li>Condition : {this.state.condition}</li>
                                                        <li>Body Style : {this.state.body_style}</li>
                                                        <li>Drive Type : {this.state.drive_type}</li>
                                                        <li>Fuel Type : {this.state.fuel_type}</li>
                                                        <li>Fuel Economy : {this.state.fuel_economy}</li>
                                                        <li>Transmission : {this.state.transmission}</li>
                                                    </ul>
                                                </div>
                                                <div className="col-md-6">
                                                    <ul className="list-check primary">
                                                        <li>Cylinder : {this.state.cylinder}</li>
                                                        <li>Doors : {this.state.doors}</li>
                                                        {/* <li>Price : {this.state.price}</li> */}
                                                        <li>Vin : {this.state.vin}</li>
                                                        <li>Odometer : {this.state.odometer}</li>
                                                        <li>Lien Info : {this.state.lean_holder}</li>
                                                        <li>Payoff Exact : {this.state.payoff_exact}</li>
                                                    </ul>
                                                </div>
                                                <div className="col-md-12">
                                                    <ul className="list-check primary">
                                                        <li>Zip Code : {this.state.zip_code}</li>
                                                        <li>City : {this.state.city}</li>
                                                        <li>State : {this.state.state}</li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        {/* <div className="col-md-7 col-lg-4">
                                            <div className="info-bx ">
                                                <div className="tour-price">
                                                    <h2 className="price">${this.state.price}</h2>
                                                </div>
                                            </div>
                                        </div> */}
                                    </div>
                                </div>
                                <br />

                                <div className="day-details-bx">
                                    
                                    <div className="row sp20">
                                        <div className="form-group col-md-8 col-sm-4 col-4">
                                            {this.state.bid_price != null?                                                
                                                <p>My Offer ${this.state.bid_price}</p>                                                
                                                : <p>You haven't made any bid.</p>
                                            }
                                            {this.state.bid.ui_states && this.state.bid.ui_states.SELLER_COUNTER_OFFER ?  
                                                <p>Seller Counter Offer ${this.state.bid.ui_states.SELLER_COUNTER_OFFER}</p>
                                                : <p>There is no seller counter-offer.</p>
                                            }                                            
                                            {this.state.bid.ui_states && this.state.bid.ui_states.DEALER_COUNTER_OFFER ?  
                                                <p>My Counter Offer: ${this.state.bid.ui_states.DEALER_COUNTER_OFFER}</p>
                                                : <p>There is no counter-offer from you.</p>
                                            }
                                        </div>
                                        <div className="form-group col-md-4 col-sm-4 col-4 text-right"><span></span></div>
                                    </div>
                                    <div className="row sp20">
                                        <div className="form-group col-md-12 col-sm-12 col-12 text-center">
                                            {this.state.bid.ui_states && this.state.bid.ui_states.ACCEPT_SELLER_COUNTEROFFER ? <button type="button" id='accept_counter_offer' name="accept_counter_offer" onClick={this.handleOffer('accept_counter_offer')} style={{display: this.state.accept_counter_offer_btn}} className="site-button sm-1" style={{float:"right"}}>Accept Seller Counter Offer</button>
                                                : <span></span>}
                                        </div>
                                    </div>
                                    <div className="row sp20">
                                        <div className="form-group col-md-12 col-sm-12 col-12 text-center">
                                            {this.state.bid.ui_states && this.state.bid.ui_states.REJECT_SELLER_COUNTEROFFER ? <button type="button" id='reject_counter_offer' name="reject_counter_offer" onClick={this.handleOffer('reject_counter_offer')} style={{display: this.state.accept_counter_offer_btn}} className="site-button sm-1" style={{float:"right"}}>Reject Seller Counter Offer</button>
                                                : <span></span>}
                                        </div>
                                    </div>
                                    {this.state.bid.ui_states && this.state.bid.ui_states.MAKE_DEALER_COUNTEROFFER ? 
                                    <div className="row sp20">
                                        <div className="form-group col-md-8 col-sm-8 col-8">
                                            <input type="text" name="dealer_counter_offer" onChange={(e) => this.handleChange(e)} id="dealer_counter_offer" className="form-control" placeholder="Enter My Counter Offer" style={{float:"right"}}/>
                                        </div>
                                        <div className="form-group col-md-4 col-sm-4 col-4">
                                            <button type="button" id='counter_offer' name="counter_offer" onClick={this.handleOffer('counter_offer')} className="site-button sm-1" style={{display: this.state.accept_counter_offer_btn, float:"right"}} >Counter Offer</button>
                                        </div>
                                    </div>
                                    : <span></span>}

                                    {this.state.listing_status == 'TRANSACTION' ?
                                        <div>
                                            {this.state.affiliated_inspection == -1 && this.state.affiliated_transportation == -1 ?
                                                <div className="row">
                                                    <span className="text-danger">You must either opt-out or select the affiliated transportation and inspection company</span>
                                                </div>
                                                : <div></div>
                                            }
                                            <div className="row">
                                                <b>Affiliate Inspection</b>
                                                <select required='' className="form-control" name="affiliated_inspection" id="affiliated_inspection" onChange={this.handleDropdownAffiliate} value={this.state.affiliated_inspection} >
                                                    <option disabled selected value={-1}> -- select an option -- </option>
                                                    {this.state.affiliated_inspection_list ?
                                                        this.state.affiliated_inspection_list.map(r => <option key={r.id} value={r.id}>{r.name}</option>)
                                                        : <option/>
                                                    }
                                                </select>
                                                {this.state.error_affiliated_inspection != '' &&
                                                    <small class="text-danger">
                                                        {this.state.error_affiliated_inspection}
                                                    </small>
                                                }
                                            </div>
                                            <br/>
                                            
                                            <div className="row">
                                                <b>Affiliate Transportation</b>
                                                <select required='' className="form-control" name="affiliated_transportation" id="affiliated_transportation" onChange={this.handleDropdownAffiliate} value={this.state.affiliated_transportation} >
                                                    <option disabled selected value={-1}> -- select an option -- </option>
                                                    {this.state.affiliated_transportation_list ?
                                                        this.state.affiliated_transportation_list.map(r => <option key={r.id} value={r.id}>{r.name}</option>)
                                                        : <option/>
                                                    }
                                                </select>
                                                {this.state.error_affiliated_transportation != '' &&
                                                    <small class="text-danger">
                                                        {this.state.error_affiliated_transportation}
                                                    </small>
                                                }
                                            </div>

                                            <br/>
                                            <button type="button" id='save_affiliate' name="save_affiliate" style={{}} onClick={this.handleSaveAffiliate} className="site-button sm-1" >Save transportation and inspection selection</button>
                                            <br/>
                                            
                                        </div>
                                        : <div></div>
                                    }
                                    <br/>
                                    <div className="row">
                                        <h4>Messages: </h4>
                                    </div>
                                    <div className="row">
                                        {this.state.message_list.length > 0 ?
                                            <Timeline>
                                                {this.state.message_list.map(m => (
                                                    <>
                                                        <TimelineEvent 
                                                            index={m.id}
                                                            title={m.from_user}
                                                            createdAt={moment(m.dd_date_created).format('MMMM Do YYYY, h:mm:ss a')}
                                                            icon={<i className={this.handleClassType(m.message_type)} style={{'font-size':'200%'}}></i>}
                                                        >
                                                            <div style={{padding:10}}>
                                                                {ReactHtmlParser(m.body)}
                                                            </div>
                                                        </TimelineEvent>
                                                    </>
                                                ))}
                                            </Timeline>
                                            : <p>You don't have any messages.</p>
                                                }
                                    </div>
                                </div>

                            </div>
                            <div className="col-lg-4">
                                <div className="sticky-top">
                                    <BidForm val={this.state} handler={this.handleBidPrice} />
                                    <div className="m-t30">
                                    <MessageForm val={this.state} handler={this.updateTimelineMessage}/>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row m-b30">
                            <div className="col-md-12 col-lg-12">

                            </div>
                        </div>
                    </div>
                </div>

            </div>
            <Footer />
        </div>
        )
    }
}
export default DealercarDetails;