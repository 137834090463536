import React, { Component } from 'react'
import { Button, Form, FormGroup, Label, Input, FormText } from 'reactstrap';
import { Api } from "../../utils/Api";
import $ from 'jquery';
import ReactSlider from 'react-slider'
import LandingHeader from '../Layout/LandingHeader';
import queryString from 'query-string';
// import { Link, Redirect, useHistory } from 'react-router-dom';
import InputMask from 'react-input-mask';
import SimpleReactValidator from 'simple-react-validator';

class ResetPassword extends Component {
    constructor(props) {
        super(props);
        this.validator = new SimpleReactValidator();
        this.state = {   
            c: '',  
            u: '',
            t: '',
            h: '',       
            password: '',
            verify_password: ''        
        }
    }

    componentDidMount() {
        let url = this.props.location.search;
        console.log(">> url:", url);
        let params = queryString.parse(url);
        console.log(">> params:", params, Object.getOwnPropertyNames(params));

        if (Object.getOwnPropertyNames(params).length > 0){
            var c = params['c'];
            var u = params['u'];
            var t = params['t'];
            var h = params['h'];
            console.log(u, t, c, h);
            this.setState({c:c, u:u, t:t, h:h});
        }
        console.log(">> resetpwdprecheck: calling api");
        const data  = {
            c: params['c'],
            u: params['u'],
            t: params['t'],
            h: params['h']
        }
        Api.post('/ham/resetpwdprecheck', data)
            .then(res => {
                console.log(">> resetpwdprecheck: res: ", res);
                if (res.data.status == 'EXPIRED') {
                    this.props.history.replace('/linkexpired')
                } else if (res.data.status != 'SUCCESS') {
                    this.props.history.replace('/mainerror')
                }
        })
    }

    handleChange = (e) => {
        this.setState({[e.target.name]: e.target.value})
    }

    handleSubmit = (e) => {
        'use strict';
        e.preventDefault();
        if (this.validator.allValid()) {

            const data  = {
                c: this.state.c,
                u: this.state.u,
                t: this.state.t,
                h: this.state.h,
                password: this.state.password,
                verify_password: this.state.verify_password
            }
            console.log("test=>", this.state)
            Api.post('/ham/resetpwd', data)
                .then(res => {
                    console.log(">> back from server: res:", res);
                    if (res.status == 200) {
                        this.props.history.replace('/');
                    } else {
                        alert('Opps, something is wrong!');
                    }

                })
        } else {
            this.validator.showMessages();
            // rerender to show messages for the first time
            // you can use the autoForceUpdate option to do this automatically`
            this.forceUpdate();
        }
        
    }

    // handleStep2 = (e) => {
    //     e.preventDefault();

    // }

    render() {
        return (
            <div>
                <LandingHeader />
                <div className="section-full content-inner-2 shop-account bg-white">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12 text-center">
                                <h3 className="font-weight-700 m-t0 m-b20">Reset Password</h3>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <div className="p-a30 border-1  max-w500 m-auto">
                                    <div className="tab-content">
                                    <p className="font-weight-600">Please enter new password.</p>
                                        <form id="login" className="tab-pane active">                                        
                                            <div className="form-group">
                                                <label className="font-weight-700">Password *</label>
                                                <input name="password" id="password" required="" className="form-control " placeholder="Password" type="password" onChange={this.handleChange} />
                                                <small class="text-danger">
                                                    {this.validator.message('password', this.state.password, 'required|min:6|max:120')}
                                                </small>
                                            </div>
                                            <div className="form-group">
                                                <label className="font-weight-700">Verify Password *</label>
                                                <input name="verify_password" id="verify_password" required="" className="form-control " placeholder="Verify Password" type="password" onChange={this.handleChange} />
                                                <small class="text-danger">
                                                    {this.validator.message('verify_password', this.state.verify_password, `required|in:${this.state.password}`, {messages: {in: 'Passwords need to match!'}})}
                                                </small>
                                            </div>                                        
                                            <div className="text-left">
                                                <button className="site-button button-lg radius-no" name="Submit" onClick={this.handleSubmit}>Submit</button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
export default ResetPassword;