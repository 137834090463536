import React, { Component } from 'react'
import { Api } from "../../utils/Api";
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import {ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


import InputMask from 'react-input-mask';
import SimpleReactValidator from 'simple-react-validator';

const baseStyle = {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '20px',
    borderWidth: 2,
    borderRadius: 2,
    borderColor: '#eeeeee',
    borderStyle: 'dashed',
    backgroundColor: '#fafafa',
    color: '#bdbdbd',
    outline: 'none',
    transition: 'border .24s ease-in-out'
};

class DealerGenerateEmail extends Component {
    constructor(props) {
        super(props);
        this.validator = new SimpleReactValidator();
        this.state = {
            email: '',
            tier_style: false,
        }
    }

    componentDidMount() {
    }

    handleChange = (e) => {
        this.setState({[e.target.name]: e.target.value})
    }

    handleStep1 = (e) => {
        e.preventDefault();
        if (this.validator.allValid()) {

            confirmAlert({
                title: 'Confirm to submit',
                message: 'Are you sure to do this.',
                buttons: [
                {
                    label: 'Yes',
                    onClick: () => {
                        // e.preventDefault();
                        // const data  = {
                        //     tier: this.state.tier,
                        // }
                        // console.log("test=>", this.state)
                        // Api.post('/ham/change_sub', {data: data})
                        //     .then(res => {
                        //         console.log("==>>>>>>>>>",res.data);
                        //         toast("Wow so easy !");
                        //     });

                        Api.post('/ham/generate_links', {data: this.state})
                        .then(res => {
                            console.log("==>>>>>>>>>",res.status);
                            const status = res.status;
                            // if (status === 'success'){
                            const location = "/dealer/dashboard";
                            window.location.assign(location);
                            // console.log("Test");
                            // }else{
                            //     // console.log("error");
                            //     // toast(error);
                            // }
                        })

                    }
                },
                {
                    label: 'No',
                    onClick: () => {
                        console.log("None");
                    }
                }
                ]
            });
        } else {
            this.validator.showMessages();
            // rerender to show messages for the first time
            // you can use the autoForceUpdate option to do this automatically`
            this.forceUpdate();
        }
    }


    render() {
        const {stripe} = this.props;
        const notify = () => toast("Wow so easy !");
        return (
            <div className="section-full content-inner-2 shop-account bg-white">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 text-center">
                            <h3 className="font-weight-700 m-t0 m-b20">Generate Link To Seller</h3>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="p-a30 border-1  max-w500 m-auto">
                                <div className="tab-content">
                                    <form id="login" className="tab-pane active needs-validation">
                                        <div className="form-group">
                                            <label className="font-weight-700">email *</label>
                                            <input value={this.state.email} name="email" id="email" className="form-control" placeholder="email" type="text" onChange={this.handleChange} />
                                            <small class="text-danger">
                                                {this.validator.message('email', this.state.email, 'required|email')}
                                            </small>
                                        </div>
                                        <div className="text-left">
                                            <button className="site-button button-lg radius-no outline outline-2" name="Submit" onClick={this.handleStep1}>Submit</button>
                                            <ToastContainer position="top-center"/>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default DealerGenerateEmail;