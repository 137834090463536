import React, { Component } from 'react'
import { Link } from 'react-router-dom';


const steps = [
    {
        image: require('./../images/hamilton/man-in-black-jacket-and-black-pants-sitting-near-silver-3807386.jpg'),
        step: 1,
        title: 'Register',
        desc: 'Enter your contact information including your zip code (transportation calculation).',

    },
    {
        image: require('./../images/hamilton/car_driver_1_small.jpg'),
        step: 2,
        title: 'Email Verification',
        desc: 'Check your email for registration verification. Click on the Verification link, and login into the Upshift using your email and registered password.',

    },
    {
        image: require('./../images/hamilton/close-up-photography-of-silver-car-1236710.jpg'),
        step: 3,
        title: 'List your car',
        desc: 'Click on “Add Car”, enter vehicle information, and save as draft to work on it later or if you have uploaded photos (see Step 5), you can save and list the vehicle immediately.',

    },
    {
        image: require('./../images/hamilton/car_key_handoff_small.jpg'),
        step: 4,
        title: 'Upload pictures',
        desc: 'A suggested amount of pictures is listed for the best possible exposure.  Be as thorough as possible for the highest possible value.',

    },
    {
        image: require('./../images/hamilton/car_key_handoff_small.jpg'),
        step: 5,
        title: 'Receive notifications and accept/counter offer bids',
        desc: 'Dealers will have real time buy-bids, along with notifications about the status of your bid.  After you have accepted a bid, the purchasing dealer will email instructions for payment, inspection, and transportation pick-up.',

    },
]

class SellerHowTo extends Component {

    render() {
        return (
            <div className="section-full bg-white content-inner dlab-about-1 promotions" id="about-us">
                <div className="container">
                    <div className="section-head text-center">
                        <h1 className="text-uppercase m-b0">How it Works</h1>
                        <p className="font-18">5 Simple Steps</p>
                    </div>
                    <div className="row" id="masonry">
                        <style dangerouslySetInnerHTML={{__html: `
                                .ham-howto-desc {
                                    border:1px solid #e2f3ff;
                                    padding-left: 15px;
                                    padding-top: 10px;
                                    border-radius:4px;
                                    background: #fafdff;
                                }
                        `}} />
                        <table>
                        {steps.map((item, index) => (
                                <tr>
                                    <td>
                                        <div>
                                            <div className="day-details bg-primary">Step {item.step}</div>
                                        </div>
                                    </td>
                                    <td>
                                        <div className="ham-howto-desc">
                                            <h4>{item.title}</h4>
                                            <p>{item.desc}</p>
                                        </div>
                                    </td>
                                </tr>
                        ))}
                        </table>
                        <p><strong>Note:</strong> You may have the ability to retain your tax saving for the vehicle you are selling depending on your state vehicle trade-in laws.  Please verify with your local tax office before taking buy-bid to the dealership you wish to purchase your next car.</p>
                    </div>
                </div>
            </div>



        )
    }
}
export default SellerHowTo;