import React, { useState } from 'react'
import { Link } from 'react-router-dom';
import Slider from "react-slick";

function SampleNextArrow(props) {
  const {  onClick } = props;
  return (
    <div className="la la-angle-right nav-right"  onClick={onClick}/>
  );
}

function SamplePrevArrow(props) {
  const {  onClick } = props;
  return (
    <div className="la la-angle-left nav-left" onClick={onClick}/>
  );
}

const PhotoGallery = (props) => {
    var settings = {
            dots: false,
            slidesToShow: 4,
            infinite: true,
            autoplay: false,
            speed: 500,
			nextArrow: <SampleNextArrow />,
			prevArrow: <SamplePrevArrow />,
			adaptiveHeight: true,

//            centerMode: true,
//            centerPadding: "60px",
            responsive: [
                {
                    breakpoint: 1200,
                    settings: {
                        slidesToShow: 1,
                    },
                    variableWidth: true
                },
                {
                    breakpoint: 991,
                    settings: {
                        slidesToShow: 1,
                    },
                    variableWidth: true

                },
                {
                    breakpoint: 576,
                    settings: {
                        slidesToShow: 1,
                    },
                    variableWidth: true
                }
            ]
        };
    return (
    <div>
    <div className="content-header">
        <h3 className="title"><i className="la la-file-image-o"></i> Photos</h3>
    </div>
    <div className="content-body">

            <Slider className="testimonial-center nav-btn-center-lr white" {...settings}>
                {props.imglist.map((item, index) => (
                <div className="dlab-box car_photo_carousel_div" key={index}>
                        <div className="dlab-post-thu">
                            <img className="car_photo_carousel_img" src={item.image} alt="" onClick={props.toggleImage}/>
                        </div>
                </div>
                ))}
            </Slider>

    </div>
    </div>    
    );

}

export default PhotoGallery;